import React from "react";
import {
  Form,
  Button,
  Icon,
  Columns,
  Notification,
} from "react-bulma-components/full";
import { Redirect, Link } from "react-router-dom";
import to from "await-to-js";
import detect from "detect.js";
import LOCALES from "../constants/Locales";
import "./Signin.scss";
import Auth from "../services/Auth";
import UserService from "../services/User";
import i18n from "../config/i18n";
import firebase from "firebase";
import LocaleContext from "../context/LocaleContext";
import PasswordVisibilityToggler from "./shared/PasswordVisibilityToggler";
import { DesktopShortcut } from "./CreateDesktopShortcut";
import { CustomModal } from "./CustomModal";
import { CustomUpgradeMaxUsers } from "./CustomCTAMessage";
import { unsetTutorialAsShown } from "../helpers/Validators";
import { TUTORIALS } from "../constants/Tutorials";
export default class Signin extends React.Component {
  initialState = () => ({
    email: "",
    password: "",
    rememberMe: false,
    redirect: false,
    isSigninLoad: false,
    signinError: null,
    passwordIsHidden: true,
    usersLimitReached: false,
    currentMaxUsers: 0,
  });

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }
  static contextType = LocaleContext;
  handleChangeInput = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ [target.name]: value });
  };

  getUserLanguage = async () => {
    if (!Auth.isAuth()) return;
    let err,
      user = null;
    [err, user] = await to(UserService.get(Auth.getUser().uid));
    // fetch user prefered language from db, if no language, then browser language is chosen
    if (user.language) {
      return await i18n.changeLanguage(user.language);
    } else {
      return await this.updateUserLocale(user);
    }
  };
  updateUserLocale = async (user) => {
    if (!user) return;
    // fetch user prefered language from db, if no language, then browser language is chosen
    let defaultLanguage = navigator.language;
    let {
      browser: { family },
    } = detect.parse(navigator.userAgent);
    if (family === "Chrome") {
      defaultLanguage = navigator.language.slice(0, 2);
    }
    await to(UserService.update(user, { language: i18n.language }));
    i18n.changeLanguage(defaultLanguage);
  };

  removeTutorialsKey = () => {
    unsetTutorialAsShown(TUTORIALS.NEW_ACC_ACI);
    unsetTutorialAsShown(TUTORIALS.NEW_TEMPO);
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      if (typeof this.props.location.state.signinError !== "undefined") {
        return this.setState({
          signinError: this.props.location.state.signinError,
        });
      }
    }

    this.removeTutorialsKey();
  }
  async componentWillUnmount() {
    let err,
      user = null;
    [err, user] = await to(UserService.get(Auth.getUser()?.uid));
    if (user?.language) return;
    if (this.getLang() !== "fr-FR") {
      UserService.updateKey("language", LOCALES.ENGLISH);
    }
  }
  getLang() {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
  }
  checkIfActive = async (e) => {
    this.handleSubmitForm(e);
  };
  registerUser = () => {
    // const dbRef = firebase.database().ref(`/users/${Auth.getUserId()}`);
    // dbRef.set({
    //   state: "online",
    //   last_changed: firebase.database.ServerValue.TIMESTAMP,
    // });
  };
  renderUpgradeMaxUsersModal = () => {
    return (
      <CustomModal
        show={this.state.usersLimitReached}
        title={i18n.t("cta.maxUserLimit")}
        // title={`Votre licence ne permet pas de se connecter à plus de ${this.state.currentMaxUsers} utilisateurs`}
        onClose={() => this.setState({ usersLimitReached: false })}
        onConfirm={() => this.setState({ usersLimitReached: false })}
      >
        <CustomUpgradeMaxUsers />
      </CustomModal>
    );
  };
  handleSubmitForm = async (e) => {
    e.preventDefault();
    this.setState({ isSigninLoad: true, signinError: null });
    let err,
      user = null;
    [err, user] = await to(Auth.login(this.state));
    if (!err) {
      this.getUserLanguage();
      this.registerUser();
      return this.setState({ isSigninLoad: false, redirect: true });
    }
    Auth.signOut();

    console.log({ err });
    switch (err.code) {
      case "auth/unauthorized-ip":
        return this.setState({
          isSigninLoad: false,
          signinError:
            "Votre compte n'est pas accessible depuis ce terminal. Veuillez utiliser une machine déclarée par l'administrateur.",
        });
      case "auth/couldnt-verify-ip":
        return this.setState({
          isSigninLoad: false,
          signinError: "Impossible de verifier vos identifiants",
        });
      case "auth/user-not-found":
        return this.setState({
          isSigninLoad: false,
          signinError: "L'adresse mail ou le mot de passe est incorrect.",
        });
      case "auth/user-disabled":
        return this.setState({
          isSigninLoad: false,
          signinError: "Cet compte a été désactivé.",
        });
      case "auth/invalid-email":
        return this.setState({
          isSigninLoad: false,
          signinError: "L'adresse email fournie n'est pas valide.",
        });
      case "auth/unvalidated-email":
        return this.setState({
          isSigninLoad: false,
          signinError: "Veuillez valider votre email.",
        });
      case "auth/inactive-user":
        return this.setState({
          isSigninLoad: false,
          signinError: "Ce compte a été désactivé.",
        });
      case "auth/wrong-password":
        return this.setState({
          isSigninLoad: false,
          signinError: "L'adresse mail ou le mot de passe est incorrect.",
        });
      case "user/one-user-only":
        return this.setState({
          isSigninLoad: false,
          signinError:
            "Votre licence ne permet qu'un seul utilisateur connecté à la fois",
        });
      case "auth/too-many-requests":
        return this.setState({
          isSigninLoad: false,
          signinError:
            "Trop de tentatives de connexion. Veuillez réessayer plus tard.",
        });

      case "auth/internal-error":

        if (err.message.indexOf("expiration") !== -1) {
          return this.setState({
            isSigninLoad: false,
            signinError: "Votre licence est arrivée à expiration",
          });
        }
        if (err.message.indexOf("started yet") !== -1) {
          return this.setState({
            isSigninLoad: false,
            signinError: "Votre licence n'a pas encore commencé",
          });
        }
        if (err.message.indexOf("Licence has expired") !== -1) {
          return this.setState({
            isSigninLoad: false,
            signinError: "Votre licence a expiré",
          });
        }
        const errorMessage = JSON.parse(err.message.split("HTTP Cloud Function returned an error: ")[1]);
        console.log("errorMessage", errorMessage);
        if (errorMessage.error.message === "You have reached the maximum number of users allowed by your licence") {
          console.log("errodqsdqsrMessage", errorMessage);

          return this.setState({
            isSigninLoad: false,
            signinError: "Vous avez atteint le nombre maximum d'utilisateurs autorisés par votre licence",
          });
        }
        if (errorMessage.error.message === "You have reached the maximum number of users for this account") {
          return this.setState({
            isSigninLoad: false,
            signinError: "Vous avez atteint le nombre maximum d'utilisateurs autorisés pour ce compte",
          });
        }

        return this.setState({
          isSigninLoad: false,
          signinError: `Une erreur inconnue est survenue. (${err.message})`,
        });

      default:
        return this.setState({
          isSigninLoad: false,
          signinError: `Une erreur inconnue est survenue. (${err.message})`,
        });
    }
  };

  handleVerificationMail = (e) => {
    e.preventDefault();
    let user = this.state;

    if (typeof this.props.location.state !== "undefined")
      user = this.props.location.state.user;
    Auth.sendNewEmailVerification(user);
    this.setState({
      signinError: "Nous venons de vous renvoyer un email de validation.",
    });
  };
  renderErrorNotification = () => {
    const { signinError } = this.state;
    let verified = null;

    // Check if location and location.state exist
    if (this.props.location && this.props.location.state) {
      verified = this.props.location.state.verified;
    }

    if (signinError == null) return;

    return (
      <Notification
        color={
          signinError ===
            ("Nous venons de vous renvoyer un email de validation." ||
              "Nous venons de vous envoyer un email vous permettant de réinitialiser votre mot de passe")
            ? "info"
            : "danger"
        }
      >
        <>
          {signinError}
          <br />
          {signinError === "Veuillez valider votre email." && (
            <Button
              color="info"
              size="small"
              position="centered"
              onClick={this.handleVerificationMail}
              style={{ margin: "auto", marginTop: "1em" }}
            >
              Renvoyer le mail de validation
            </Button>
          )}
        </>
      </Notification>
    );
  };

  handleResetPassword = async (e) => {
    e.preventDefault();
    let err,
      user = null;
    [err] = await to(Auth.isEmailUsed(this.state.email));
    if (err) {
      Auth.resetPassword(this.state.email);
      this.setState({
        signinError:
          "Nous venons de vous envoyer un email vous permettant de réinitialiser votre mot de passe",
      });
    } else
      this.setState({
        signinError:
          "Nous venons de vous envoyer un email vous permettant de réinitialiser votre mot de passe",
      });
  };

  renderBrowserWarning = () => {
    let {
      browser: { family },
    } = detect.parse(navigator.userAgent);
    if (family !== "Chrome")
      return (
        <div className="browser-info">
          <Notification color="info">
            <i className="fa fa-warning"></i>
            Optiwise fonctionne seulement sur Chrome ou Microsoft Edge
          </Notification>
        </div>
      );
    return <div></div>;
  };

  render() {
    const { email, password, rememberMe, redirect, isSigninLoad } = this.state;
    if (Auth.isAuthLocally() || redirect) {
      // if (Auth.isAuth() && Auth.getUser().isAdmin)
      //   return <Redirect to="/admin" />;

      return (
        <Redirect
          to={{
            pathname: "/",
            state: { emailVerified: Auth.isValidated() },
          }}
        />
      );
    }

    return (
      <div className="signin-wrapper container is-centered">
        {/* {this.renderBrowserWarning()} */}
        {this.renderUpgradeMaxUsersModal()}
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="box">
              <div className="subtitle is-4">Connexion</div>
              {this.renderErrorNotification()}
              <form onSubmit={this.checkIfActive}>
                <Form.Field>
                  <Form.Label>Adresse email</Form.Label>
                  <Form.Control iconLeft>
                    <Form.Input
                      placeholder="Exemple: hello@domain.com"
                      type="email"
                      value={email}
                      name="email"
                      onChange={this.handleChangeInput}
                    />
                    <Icon align="left">
                      <i className="fa fa-envelope" />
                    </Icon>
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control iconLeft>
                    <Form.Input
                      placeholder="Entrer votre mot de passe"
                      type={this.state.passwordIsHidden ? "password" : "text"}
                      value={password}
                      name="password"
                      onChange={this.handleChangeInput}
                    />
                    <Icon align="left">
                      <i className="fa fa-lock" />
                    </Icon>
                    <PasswordVisibilityToggler
                      isHidden={this.state.passwordIsHidden}
                      onClick={() =>
                        this.setState({
                          passwordIsHidden: !this.state.passwordIsHidden,
                        })
                      }
                    />
                  </Form.Control>
                </Form.Field>
                {this.state.signinError ===
                  "L'adresse mail ou le mot de passe est incorrect." && (
                    <div
                      className="has-text-right"
                      style={{ marginBottom: "25px" }}
                    >
                      <a href="#" onClick={this.handleResetPassword}>
                        Mot de passe oublié ?
                      </a>
                    </div>
                  )}
                <Columns>
                  <Columns.Column>
                    {/* <Form.Checkbox
                      name="rememberMe"
                      onChange={this.handleChangeInput}
                      checked={rememberMe}
                    >
                      {" "}
                      Se souvenir de moi{" "}
                    </Form.Checkbox> */}
                  </Columns.Column>
                  <Columns.Column narrow>
                    <Form.Field>
                      <Form.Control className="has-text-right">
                        <Button
                          loading={isSigninLoad}
                          color="info"
                          type="primary"
                        >
                          Se connecter
                        </Button>
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
              </form>
            </div>
            <Link to="/legal">Conditions générales d'utilisation</Link>
            {/* &{" "} <Link to="/dataProtection">Protection de la vie privée</Link> */}
            <DesktopShortcut />
          </div>
        </div>
      </div>
    );
  }
}
