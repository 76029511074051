import React, { useState, useEffect, useContext, useRef } from "react";
import LocaleContext from "../../context/LocaleContext";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import TUTORIAL_VALUES from "../../constants/TutorialValues";

const TutorialContainer = ({ showTutorial, currentPage, closeTutorial }) => {
  const { i18n, manualURL: url } = useContext(LocaleContext);
  const [content, setContent] = useState(TUTORIAL_VALUES(currentPage, url));
  const tutorialRef = useRef(null);

  // Get the main elements that allow me to differentiate the state of the Optimization page

  // The stage where the user has to choose their project
  const projectsList =
    document.getElementsByClassName("optimization-wrapper__Empty").length > 0;

  // The stage where the user can add a scenario to their project or modify/delete an existing one
  const sceanariosList =
    document.getElementsByClassName("scenarios-wrapper").length > 0;

  // The stage where users can create or modify a scenario for their project
  const editorIsOn =
    document.getElementsByClassName("create-scenario-wrapper").length > 0;

  useEffect(() => {
    setContent(TUTORIAL_VALUES(currentPage, url));
  }, [currentPage, url]);

  // These const allow me to dynamically add a class to the tutorial depending on which stage the user is in
  const firstTutoClass =
    projectsList && !editorIsOn && !sceanariosList ? "single-bullet" : "";
  const secondTutoClass = sceanariosList && !editorIsOn ? "many-bullets" : "";
  const thirdTutoClass = editorIsOn && !sceanariosList ? "rest-bullets" : "";

  const options = {
    // Const which I use here to add the corresponding custom classes

    // As the tutorial for the first step only has one slide, I'm changing the text of the button to avoid confusing the user
    nextLabel: `${projectsList ? "Ok" : i18n.t("next")}`,
    prevLabel: i18n.t("previous"),
    doneLabel: "OK",
    // I use these classes to modify the appearance of the bullet points in the tutorials
    tooltipClass: `${firstTutoClass} ${secondTutoClass} ${thirdTutoClass}`,
    // I'm hiding the previous button from the first tutorial because it only has one slide, to avoid confusing the user
    hidePrev: !!projectsList,
  };

  const handleChange = (nextStep) => {
    if (currentPage === "/optimization") {
      if (!editorIsOn && !sceanariosList) {
        //if in list of scenarios
        if (nextStep !== 0) {
          tutorialRef.current.introJs.exit();
        }
      }
      if (sceanariosList && !editorIsOn) {
        //if in scenario editor
        if (nextStep === 0) {
          tutorialRef.current.introJs.nextStep();
        }
        // Condition to close the 2nd step tutorial
        if (nextStep === 10) {
          tutorialRef.current.introJs.exit();
        }
      }
    }
  };
  return (
    <Steps
      steps={content}
      enabled={showTutorial}
      initialStep={editorIsOn ? 10 : 0}
      onExit={closeTutorial}
      options={options}
      ref={tutorialRef}
      onAfterChange={handleChange}
    />
  );
};

export { TutorialContainer };
