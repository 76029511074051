import React from "react";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";

const StyledTrigger = styled.div`
  display: inline;
  text-decoration: ${(props) => props.noUnderline || "underline"};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  &:hover {
    font-weight: ${(props) => (props.noBold ? "bold" : "normal")};
    cursor: ${(props) => props.disabled || props.noBold || "pointer"};
  }
`;

const StyledPopupHeader = styled(Popup.Header)`
  font-weight: bold;
`;

const StyledPopupContent = styled(Popup.Content)``;
/**
 *
 * @param trigger should be a react component, the element that will be hovered
 * @param title the title that will show inside of the popup
 * @param content the content of the popup
 * @returns
 */
export const CustomHoverPopUp = ({
  children,
  size = "large",
  position = "bottom left",
  title = "",
  wide = true,
  content = <></>,
  noUnderline = false,
  disabled = false,
  clickable = false,
  triggerWidth = "auto",
  noBold = false,
}) => {
  const on = clickable ? ["click"] : ["hover"];
  //   if (!children) return null;
  const trigger = (
    <StyledTrigger
      width={triggerWidth}
      noUnderline={noUnderline}
      noBold={noBold}
    >
      {children}
    </StyledTrigger>
  );
  return (
    <Popup
      trigger={trigger}
      size={size}
      position={position}
      wide={wide ? "very" : ""}
      disabled={disabled}
      on={on}
    >
      {title && <StyledPopupHeader>{title}</StyledPopupHeader>}
      <StyledPopupContent>{content}</StyledPopupContent>
    </Popup>
  );
};
