import moment from "moment";
import _ from "lodash";
import Randomstring from "randomstring";
import CheapRuler from "cheap-ruler";
import ApiService from "../services/Api";
import SiteService from "../services/Site";
import { fileHasTwoColumnsForDate } from "../helpers/Files/FileChecker";

import SiteConstants from "./../constants/Site";
import CollectiveSitesService from "../services/CollectiveSites";
import { defaultRateModel } from "../constants/DefaultRates";

// $ monthData.lenght / report.length

class SiteHelpers {
  initialConsumptionIndexes = () => ({
    1: [0, 0, 0, 0, 0],
    2: [0, 0, 0, 0, 0],
    3: [0, 0, 0, 0, 0],
    4: [0, 0, 0, 0, 0],
    5: [0, 0, 0, 0, 0],
    6: [0, 0, 0, 0, 0],
    7: [0, 0, 0, 0, 0],
    8: [0, 0, 0, 0, 0],
    9: [0, 0, 0, 0, 0],
    10: [0, 0, 0, 0, 0],
    11: [0, 0, 0, 0, 0],
    12: [0, 0, 0, 0, 0],
  });
  initialStateSubscribedPower = () => ({
    id: Randomstring.generate(10),
    PTE: 0,
    HPH: 0,
    HCH: 0,
    HPB: 0,
    HCB: 0,
  });
  initialStateSubscriptionRate = () => ({
    id: Randomstring.generate(10),
    pricePerYear: 0,
    pricePerKVA: 0,
  });
  initialStateEnergyPrice = () => ({
    id: Randomstring.generate(10),
    PTE: 0,
    HPH: 0,
    HCH: 0,
    HPB: 0,
    HCB: 0,
  });
  initialStateWeekEndPrice = () => ({
    id: Randomstring.generate(10),
    PTE: 0,
    HPS: 0,
    HCS: 0,
    HPW: 0,
    HCW: 0,
  });
  initialStateTempoPrice = () => ({
    id: Randomstring.generate(10),
    BCHC: 0,
    BCHP: 0,
    BUHC: 0,
    BUHP: 0,
    RHC: 0,
    RHP: 0,
  });
  initialOffPeakHours = () => ({
    id: Randomstring.generate(10),
    endTime: 6,
    startTime: 22,
  });
  setInitialDefaultTarifs = (type, userDefaultRates) => {
    if (userDefaultRates && userDefaultRates.length > 0) {
      return userDefaultRates[0].rates;
    }

    const defaultValue = defaultRateModel();

    return [
      {
        energyRate: 0,
        subscriptionRate: 0,
        id: Randomstring.generate(10),
        months: defaultValue.rates[0].months,
        days: [1, 2, 3, 4, 5, 6, 7],
        startHour: 0,
        endHour: 24,
        subscribedPower: 0,
      },
    ];
  };

  defaultProducerTarifs = () => [
    {
      energyRate: 0,
      subscriptionRate: 0,
      id: Randomstring.generate(10),
      months: [
        { month: 1, year: 2020 },
        { month: 2, year: 2020 },
        { month: 3, year: 2020 },
        { month: 4, year: 2020 },
        { month: 5, year: 2020 },
        { month: 6, year: 2020 },
        { month: 7, year: 2020 },
        { month: 8, year: 2020 },
        { month: 9, year: 2020 },
        { month: 10, year: 2020 },
        { month: 11, year: 2020 },
        { month: 12, year: 2020 },
      ],
      days: [1, 2, 3, 4, 5, 6, 7],
      startHour: 0,
      endHour: 24,
      subscribedPower: 0,
      name: "",
    },
  ];

  applyCollectiveSiteDataToSite = (collectiveSite) => {
    if (!collectiveSite) return {};

    const {
      id,
      address,
      addressCity,
      addressDepartement,
      addressDepartementNum,
      addressGeocode,
      addressZipCode,
      country,
      timezone,
    } = collectiveSite;

    return {
      collectiveSiteId: id,
      address,
      addressCity,
      addressDepartement,
      addressDepartementNum,
      addressGeocode,
      addressZipCode,
      country,
      timezone,
      ACI: false,
    };
  };

  initialStateSite = (
    type,
    userDefaultRates,
    collectiveSite,
    isFullSellOut = false,
  ) => ({
    id: "",
    name: "",
    type: isFullSellOut ? "CONSUMER" : type,
    isFullSellOut,
    address: "",
    addressZipCode: "",
    addressCity: "",
    addressDepartementNum: 0,
    addressDepartement: "",
    addressGeocode: [],
    currency: "EUR",
    country: "fr",
    co2rate: 0,
    autoCo2: true,
    description: "",
    purchaseContractType: "Contrat unique",
    purchaseMeterOwner: "AODE",
    meterOwner: "AODE",
    purchaseAreaOfTension: isFullSellOut
      ? SiteConstants.purchaseAreaOfTension["BT>36 kVA"]
      : "",
    ratesOption: isFullSellOut ? SiteConstants.ratesOption.CU : "",
    peakHours: [
      { startTime: 9, endTime: 11 },
      { startTime: 18, endTime: 20 },
    ],
    startDate: "",
    endDate: "",
    enedisNumber: "",
    enedisBuffer: "",
    automaticUpdateError: null,
    faltyPrm: null,
    reportURL: "",
    subscriptionRates: 0,
    subscribedPowers: [this.initialStateSubscribedPower()],
    offPeakHours: [this.initialOffPeakHours()],
    energyPrices: [this.initialStateEnergyPrice()],
    tarifs: this.setInitialDefaultTarifs(type, userDefaultRates),
    restOfTheYearTarifs: {
      energyRate: 0,
      subscribedPower: 0,
      subscriptionRate: 0,
    },
    customerConsentFirstName: "",
    customerConsentLastName: "",
    customerConsentEmail: "",
    isConsentMailSent: false,
    hasConsentData: false,
    disabled: false,
    tarifBuilder: false,
    TCSPE: SiteConstants.CSPE["<36kVA"],
    consentFileUrl: "",
    user: null,
    includeTURPE: false,
    includeTVA: false,
    profile: "",
    consumptionIndexes: this.initialConsumptionIndexes(),
    monthlyConsumptionIndex: true,
    siteHasData: false,
    hasWeekendPricing: false,
    hasPeakHours: true,
    connectingPower: null,
    energyPricesWe: [this.initialStateEnergyPrice()], //Weekend [6-7]
    tempoPricing: false,
    energyPricesTempo: [this.initialStateTempoPrice()],
    folder: "",
    dataSourceHistory: [],
    ...this.applyCollectiveSiteDataToSite(collectiveSite),
  });

  initialStateOffPeakHour = () => ({
    id: Randomstring.generate(10),
    startTime: null,
    endTime: null,
  });
  newStateSubscribedPower = () => {
    return {
      id: Randomstring.generate(10),
      PTE: 0,
      HPH: 0,
      HCH: 0,
      HPB: 0,
      HCB: 0,
    };
  };

  newStateSubscriptionRate = () => ({
    id: Randomstring.generate(10),
    pricePerYear: 0,
    pricePerKVA: 0,
  });

  newStateEnergyPrice = () => ({
    id: Randomstring.generate(10),
    PTE: 0,
    HPH: 0,
    HCH: 0,
    HPB: 0,
    HCB: 0,
  });

  computeReportDataStep = (reportData, isOptimize) => {
    const key = isOptimize ? "Date" : "dateTime";
    return Math.abs(
      moment(reportData[reportData.length - 1][key]).diff(
        moment(reportData[reportData.length - 2][key]),
        "hour",
        true,
      ),
    );
  };

  handleConsumptionIndexesForApi = (consumptionIndexes) => {
    const indexes = Object.keys(consumptionIndexes);
    let consumptionArray = [];
    //Monthly calculation
    if (indexes.length > 1) {
      consumptionArray = Object.entries(consumptionIndexes).map(
        ([month, R], index, arr) => ({
          startDate: moment()
            .set("year", 2024)
            .set("month", month - 1)
            .startOf("month")
            .format("DD/MM/YYYY"),
          endDate:
            index === arr.length - 1
              ? moment().set("year", 2025).startOf("year").format("DD/MM/YYYY")
              : moment()
                  .set("year", 2024)
                  .set("month", month)
                  .startOf("month")
                  .format("DD/MM/YYYY"),
          R: R,
        }),
      );
    } //yearlyCalculation
    else {
      return [
        {
          R: consumptionIndexes[1],
          startDate: moment()
            .set("year", 2024)
            .startOf("year")
            .format("DD/MM/YYYY"),
          endDate: moment()
            .set("year", 2025)
            .startOf("year")
            .format("DD/MM/YYYY"),
        },
      ];
    }
    return consumptionArray;
  };

  kWh2MWh = (value) => value / 1000;
  MWh2kWh = (value) => value * 1000;
  Wh2kWh = (value) => value / 1000;
  Wh2MWh = (value) => value / 1000000;

  buildOffPeakHoursArray = (offPeakHours) => {
    let vecHeuresCreuses = [];
    offPeakHours.forEach((e) => {
      if (e.endTime === 0) e.endTime = 24;
      if (e.startTime < e.endTime) {
        for (let i = e.startTime; i < e.endTime; i++) vecHeuresCreuses.push(i);
      } else {
        for (let i = e.startTime; i < 24; i++) vecHeuresCreuses.push(i);
        for (let i = 0; i < e.endTime; i++) vecHeuresCreuses.push(i);
      }
    });
    return vecHeuresCreuses;
  };

  GroupDataByHours = (reportData = []) => {
    reportData = Object.values(
      _.groupBy(reportData, (value) =>
        moment(value.dateTime, "YYYY-MM-DDTHH:mm:ss").startOf("hour"),
      ),
    );
    reportData = reportData.map((hourData) => {
      return {
        PA:
          hourData.reduce((sum, rowData) => ({
            PA: +sum.PA + parseFloat(rowData.PA),
          })).PA / hourData.length,
      };
    });
    return reportData;
  };

  groupDataByMonths = (
    reportData = [],
    reduceIt = true,
    isOptimize = false,
  ) => {
    let resultToArray = [];
    let buffer = [];
    const valueKey = isOptimize ? "Soutirage" : "PA";
    const dateKey = isOptimize ? "Date" : "dateTime";

    // let t0 = performance.now();

    // console.log(`[Performance/Start] groupDataByMonths _.groupBy`);
    // Take up to 10 secondes to group by

    // -----------------------------
    reportData = _.groupBy(reportData, (value) => {
      return parseInt(moment(value[dateKey]).year());
    });

    let years = Object.keys(reportData);

    for (
      let year = parseInt(years[0]);
      year <= parseInt(years[years.length - 1]);
      year++
    ) {
      buffer.push(
        _.groupBy(reportData[year], (value) => {
          return moment(value[dateKey], "YYYY-MM-DD HH:mm:ss").month() + 1;
        }),
      );
    }

    for (let i = 0; i < buffer.length; i++) {
      for (var key in buffer[i]) {
        if (!isOptimize) {
          resultToArray.push({
            month: key,
            monthStr: moment(key, "M").format("MMMM"),
            value: buffer[i][key],
          });
        } else {
          resultToArray.push({
            month: key,
            monthStr: moment(key, "M").format("MMMM"),
            value: buffer[i][key].map(({ Date, Soutirage, ...row }) => {
              return {
                dateTime: moment(Date, "YYYY-MM-DD HH:mm:ss").format(),
                PA: +Soutirage,
                PRI: +row.PRI || 0,
                ...row,
              };
            }),
          });
        }
      }
    }
    return resultToArray;
  };

  groupDataByWeekDays = (reportData = []) => {
    let resultToArray = [];

    reportData = _.groupBy(reportData, (value) => moment(value.dateTime).day());
    for (var key in reportData) {
      resultToArray.push({
        day: key,
        dayStr: moment(key, "d").format("dddd"),
        value: reportData[key],
      });
    }
    return resultToArray;
  };

  // getRowDataSubscriptionRate(site, rowData, isOptimize) {
  //   let rate = null;
  //   rate = site.subscriptionRates[0];
  //   let rates = [parseFloat(rate.pricePerYear), parseFloat(rate.pricePerKVA)];
  //   return rates;
  // }

  getSubscribedPower = (site, dateTime, isOptimize) => {
    const { subscribedPowers = [] } = site;
    const subscribedPower = subscribedPowers.reduce((cur, next, i, array) => {
      if (isOptimize || array.length === 1) return cur;
      if (
        moment(dateTime).isBetween(
          moment(new Date(next.startDate)),
          moment(new Date(next.endDate)),
          "[]",
        )
      )
        return next;
      return cur;
    });
    return subscribedPower;
  };

  formatReportData = async (reportData) => {
    let filtered = [...reportData];
    filtered = filtered.filter((e) => e[0] !== "");
    filtered.shift();
    if (!fileHasTwoColumnsForDate(filtered[0][1])) {
      return Promise.resolve(
        filtered.map((rowData) => {
          return {
            dateTime: moment(
              `${Object.values(rowData)[0]}`,

              "DD-MM-YYYY HH:mm",
            ).format(),
            PA: this.Wh2kWh(parseFloat(Object.values(rowData)[1])),
            PRI: this.Wh2kWh(parseFloat(Object.values(rowData)[2]) || 0),
          };
        }),
      );
    }
    return Promise.resolve(
      filtered.map((rowData) => {
        return {
          dateTime: moment(
            `${Object.values(rowData)[0]} ${Object.values(rowData)[1]}`,

            "DD-MM-YYYY HH:mm",
          ).format(),
          PA: this.Wh2kWh(parseFloat(Object.values(rowData)[2])),
          PRI: this.Wh2kWh(parseFloat(Object.values(rowData)[3]) || 0),
        };
      }),
    );
  };

  //Used Post-Opt only

  handleTMYRequests = async (site) => {
    // dont request TMY for subSites
    if (site.collectiveSiteId) return;
    try {
      return await ApiService.initTMY(
        site.id,
        site.addressGeocode,
        site.country,
      ); //trigger PVGIS ONLY
    } catch (e) {
      try {
        return this.moveGeocodeAwayFromCoast(site);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  };

  updateSiteGeoCode = (site, addressGeocode) => {
    if (site.type === SiteConstants.type.CONSUMER_PLUS) {
      return CollectiveSitesService.update(site.id, {
        ...site,
        addressGeocode,
      });
    }

    return SiteService.update(site.id, {
      ...site,
      addressGeocode,
    });
  };

  /** If addressGeocode is located too close to the sea, TMY API will reject the call
   * In this case, we'll try and change the geoCode with a 300m offset to the north, then east, south and west,
   * and take the first call accepted by the api
   */
  moveGeocodeAwayFromCoast = async (site) => {
    const { addressGeocode } = site;
    let success = false;
    const BEARINGS = [0, 90, 180, 270]; //Nort, East, South, West

    const ruler = new CheapRuler(addressGeocode[0], "meters");
    bearing: for (let i = 0; i < BEARINGS.length; i++) {
      if (!success) {
        let newGeocode = ruler.destination(addressGeocode, 300, BEARINGS[i]); //Computing new geoCode with a 300 meters offset with current bearing
        await this.updateSiteGeoCode(site, newGeocode); //update geocode in database so that API can try and fetch TMY with the corrected geocode
        try {
          await ApiService.initTMY(site.id, site.addressGeocode, site.country); //trigger PVGIS ONLY
        } catch (e) {
          continue bearing; //if TMY sends error, try next bearing
        }
        success = true; //else TMY has been fetched, end of the loop;
        return Promise.resolve();
      }
    }
    return Promise.reject("NO TMY");
  };
}

export default new SiteHelpers();
