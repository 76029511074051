import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import gb from "./locales/en";
import fr from "./locales/fr";

i18n.use(initReactI18next).init({
  resources: {
    gb,
    fr,
  },
  fallbackLng: "fr",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  //   keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
    format: (value, rawFormat, lng) => {
      const [type, ...additionalValues] = rawFormat
        .split(",")
        .map((v) => v.trim());

      let [currency, format] = additionalValues;
      switch (type) {
        case "price":
          return Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency,
            currencyDisplay: "narrowSymbol",
          }).format(value);
      }
    },
  },
  react: {
    wait: true,
  },
});
export default i18n;
