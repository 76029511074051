import { BehaviorSubject } from "rxjs";

const scenarioSpecificElements = [
  "Grid_EnergeticMix",
  "PV_EnergeticMix",
  "PV2_EnergeticMix",
  "WT_EnergeticMix",
  "LoadCurve",
  "LoadCurve_SOC",
  "LoadCurve_H2",
  "LoadCurve_Stations",
  "DistributionKey",
];

let pdfElements = {};

const pdf$ = new BehaviorSubject(pdfElements);

export const addPdfElement = (name, value, subSiteId) => {
  if (subSiteId) {
    if (!pdfElements.hasOwnProperty(subSiteId)) {
      pdfElements[subSiteId] = {};
    }
    if (typeof value === "object") {
      pdfElements[subSiteId][name] = {
        ...pdfElements[subSiteId][name],
        ...value,
      };
    } else {
      pdfElements[subSiteId][name] = value;
    }
  } else {
    if (typeof value === "object") {
      if (name === "TechSummary" && value.hasOwnProperty("techs")) {
        pdfElements[name] = {
          ...pdfElements[name],
          techs: { ...pdfElements[name]?.techs, ...value.techs },
        };
      } else {
        pdfElements[name] = { ...pdfElements[name], ...value };
      }
    } else {
      pdfElements[name] = value;
    }
  }
  pdf$.next(pdfElements);
};

export const clearPdfElement = () => {
  scenarioSpecificElements.map((el) => delete pdfElements[el]);
  pdf$.next(pdfElements);
};

export const clearAllPdfElements = () => {
  pdfElements = {};
  pdf$.next(pdfElements);
};

export default pdf$;
