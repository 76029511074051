import React from "react";
import styled from "styled-components";
import { CustomAbbr } from "../CustomAbbr";

const StyledIcon = styled.div`
  &&&&& {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
    .fa {
      padding: 1em 0;
    }
    .fa-trash {
      color: black;
      transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

      &:hover {
        color: red;
        transform: scale(1.2);
      }
    }
  }
`;

export const DownloadIcon = ({ link }) => (
  <CustomAbbr title="Télécharger">
    <StyledIcon>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <i className="fa fa-download" />
      </a>
    </StyledIcon>
  </CustomAbbr>
);

export const DeleteIcon = ({ action }) => (
  <CustomAbbr title="Supprimer">
    <StyledIcon>
      <a target="_blank" rel="noopener noreferrer" onClick={action}>
        <i className="fa fa-trash" />
      </a>
    </StyledIcon>
  </CustomAbbr>
);
