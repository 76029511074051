import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";

import "./config/i18n";

if (process.env.REACT_APP_ENV !== "production") {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };
}

if (
  process.env.REACT_APP_ENV === "production" &&
  process.env.REACT_APP_DEBUG === "false"
) {

  console.log = () => {};
  console.warn = () => {};
}

if (module.hot) module.hot.accept();

ReactDOM.render(
  <App />,

  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
