import SiteConstants from "../../../../constants/Site";
import Auth from "../../../../services/Auth";

const consumerPlusSiteModel = () => ({
  name: "Nouveau projet",
  type: SiteConstants.type.CONSUMER_PLUS,
  address: "",
  addressZipCode: "",
  addressCity: "",
  addressDepartementNum: 0,
  addressDepartement: "",
  addressGeocode: [],
  country: "fr",
  autoCo2: true,
  co2rate: 0,
  description: "",
  id: "",
  user: Auth.getUserId(),
  initialOwner: Auth.getUserId(),
  creator: Auth.getUserId(false),
  sites: [],
});

export { consumerPlusSiteModel };
