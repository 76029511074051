const getProjectName = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://us-central1-likewatt-4bb4b.cloudfunctions.net";
    case "stage":
    case "test":
      return "https://us-central1-test-likewatt.cloudfunctions.net";

    default:
      return "https://us-central1-likewatt-4bb4b.cloudfunctions.net";
  }
  //   return "http://localhost:5001/test-likewatt/us-central1";
};

const USER_DATA = {
  license: "",
  licenseEndDate: "",
  licenseStartDate: "",
  domain: "",
  type: "",
};

module.exports = {
  OBJ_KEY_JWT_TOKEN: "authToken",
  OBJ_KEY_USER_INFO: "userData",
  URL_ADMIN: `${getProjectName()}/sendMailToAdmin`,
  URL_CUSTOMER: `${getProjectName()}/sendMailToCustomer`,
  URL_UPDATE: `${getProjectName()}/sendMailUpdate`,
  URL_ADMIN_SIGNUP: `${getProjectName()}/sendMailToAdminSignUp`,
  URL_SEND_INFO_MAIL: `${getProjectName()}/sendInfoMail`,
  URL_CUSTOMER_TOKEN: `${getProjectName()}/sendMailToCustomerToken`,
  URL_ANALYZE: `${getProjectName()}/analyze`,
  URL_TEST: `${getProjectName()}/test`,
  URL_TIMEZONE: `${getProjectName()}/getTimezone`,
  URL_VALIDATE_USER: `${getProjectName()}/validateUser`,
  URL_DUPLICATE_FILE: `${getProjectName()}/duplicateFiles`,
  URL_SEND_UNKNOWN_ERROR_MAIL: `${getProjectName()}/sendMailUnknownError`,
  URL_SEND_BROKERAGE_MAIL: `${getProjectName()}/sendMailBrokerage`,
  USER_DATA,
  URL_SEND_CONTACT_OR_SUPPORT: `${getProjectName()}/sendMailToContactOrSupport`,
  URL_SEND_QUOTE_CANI: `${getProjectName()}/sendMailQuoteCnI`,
  URL_SEND_MAIL_RESULT_CANI: `${getProjectName()}/sendMailContactCnI`,
  URL_SEND_CONSENT: `${getProjectName()}/sendMailForConsent`,
  URL_SEND_ASK_RENEWAL: `${getProjectName()}/sendMailAskRenewal`,
  URL_SEND_INVITE_MEMBER: `${getProjectName()}/sendMailNewAccount`,
};
