export const GRID_REFERENCES = {
  ANALYSIS_INDIVIDUAL: "analysis_individual",
  ANALYSIS_COLLECTIVE: "analysis_collective",
  OPTIM_SCENARIO_CONSUMER: "optim_scenario_consumer",
  OPTIM_SCENARIO_SUPPLIER: "optim_scenario_supplier",
  OPTIM_GENERAL_CONSUMER: "optim_general_consumer",
  OPTIM_GENERAL_SUPPLIER: "optim_general_supplier",
};

export const DEFAULT_GRID_KEYS = {
  analysis_individual: [
    "MonthCosts",
    "AnalysisIndicators",
    "CostPerMonth",
    "EnergyBalance",
    "LoadCurve",
    "DailyProfile",
  ],
  analysis_collective: [
    "MonthCosts",
    "AnalysisIndicators",
    "CostPerMonth",
    "EnergyBalance",
    "LoadCurve",
    "DailyProfile",
  ],
  optim_general_consumer: ["ScenarioTable", "LineBalanceSheet", "Van"],
  optim_scenario_consumer: [
    "Map",
    "TechSummary",
    "OptimConsumerIndicators",
    "EnergeticMix",
    "Opex",
    "ConsumerEnergyBalance",
    "OptimConsumerLoadCurve",
    "LineBalanceSheetFromPopUp",
    "VanFromPopUp",
    "ScenarioTableFromPopUp",
  ],
};

export const SYNTHESIS_FI_KEYS = [
  "Hypothèse",
  "name",
  "van",
  "ccf",
  "scope",
  "capex",
  "tri",
  "triProjet",
  "timeROI",
  "timeRNA",
  "unitCost",
  "pvPeakPower",
];
