import PLANS from "../constants/Plans";
import SiteConstants from "../constants/Site";
import { getUserData } from "../store/authentication/AuthenticationStore";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(duration);
dayjs.extend(customParseFormat);

const errorNeedRefresh =
  /ILE500|SGT500|undefinedError|Une erreur est survenue, merci de modifier la configuration de votre site ou contacter le support/;
/**
 *
 * This file conatain (site and array of sites) validtators
 * for site ConsoData state, analyzability and optimizibility
 */

/**
 * ONE SITE VALIDATORS
 * */

export const isPRMConsumerSegment = (contractInformations) => {
  return contractInformations.donneesGenerales.segment.libelle._text.includes(
    "C",
  );
};

export const hasContractSituation = (contractInformations) => {
  return contractInformations["situationContractuelle"];
};

export const isSiteCollectiveSite = (site) =>
  site.sites && site.sites.length > 0;

export const doesSubSiteHasConfigSite = (subSite, scenario) =>
  typeof scenario.configSite[subSite.id] !== "undefined";

export const isSiteIsDisabled = (site) => site.disabled;
export const isSiteCollectiveSitesIsDisabled = (site) =>
  site.some((site) => site.disabled);

export const hasDisabledSite = (sites) => {
  return sites.some((site) => {
    if (site.type === "CONSUMER_PLUS") {
      return isSiteCollectiveSitesIsDisabled(site.sites);
    } else {
      return isSiteIsDisabled(site);
    }
  });
};

//a.k.a supplier || consumer + full sell out
export const doesSiteHasInitialConso = (site) =>
  [SiteConstants.type.CONSUMER, SiteConstants.type.CONSUMER_PLUS].includes(
    site.type,
  ) && !site.isFullSellOut;

/**
 *
 * Every site using PRM should have a consentFile
 */
export const isSiteEnedisCompliant = ({
  enedisNumber = "",
  // consentFileUrl = "",
  hasConsentData = false,
}) => {
  return enedisNumber && hasConsentData;
};

export const isSiteEnedisAutorisation = ({
  enedisNumber = "",
  isConsentMailSent = false,
}) => {
  return enedisNumber && isConsentMailSent;
};

export const siteSourceIsEnedisRelated = (site) =>
  siteSourceIsEnedis(site) ||
  siteSourceIsDailyIndex(site) ||
  siteSourceIsMesures(site);

export const siteSourceIsCsv = ({ dataSource = "" }) => {
  return [SiteConstants.DATA_SOURCES.CSV].includes(dataSource);
};
export const siteSourceIsEnedis = ({ dataSource = "" }) => {
  return [SiteConstants.DATA_SOURCES.ENEDIS].includes(dataSource);
};
export const siteSourceIsMesures = ({ dataSource = "" }) => {
  return [SiteConstants.DATA_SOURCES.MESURES].includes(dataSource);
};

export const siteSourceIsProfile = ({ dataSource = "" }) => {
  return [SiteConstants.DATA_SOURCES.PROFILE].includes(dataSource);
};

export const siteSourceIsDailyIndex = ({ dataSource = "" }) => {
  return [SiteConstants.DATA_SOURCES.DAILY_INDEX].includes(dataSource);
};

export const isSiteCompletelyConfigured = (site) => {
  return site.type === "CONSUMER"
    ? site.purchaseAreaOfTension
    : !site.grid_params;
};

export const isSiteIsInConsentProcess = (site) => {
  return site.enedisNumber && site.isConsentMailSent && !site.hasConsentData;
};

// CONSODATA VALIDATORS
export const isSiteWithConsoData = (site) => site?.reportURL;

export const isSiteExtractingData = ({
  enedisNumber,
  automaticUpdateError,
  reportURL,
  dataSource,
}) =>
  (enedisNumber && !reportURL && !automaticUpdateError) ||
  (dataSource === "PROFILE" && !reportURL);

export const isSiteHasError = ({
  enedisNumber,
  automaticUpdateError,
  reportURL,
  dataSource,
}) => {
  if (dataSource === "PROFILE") return false;
  return (
    (enedisNumber &&
      automaticUpdateError &&
      automaticUpdateError !== "Report Retrieved") ||
    (!enedisNumber && !reportURL)
  );
};

export const isSiteNeedUpdate = ({
  enedisNumber,
  automaticUpdateError,
  reportURL,
}) =>
  enedisNumber &&
  automaticUpdateError &&
  automaticUpdateError !== "Report Retrieved" &&
  String(automaticUpdateError).match(errorNeedRefresh) &&
  !reportURL;

// ANALYSIS VALIDATORS

export const isAnalysisFalty = (status) => ["unknownError"].includes(status);

export const isSiteAnalyzable = (site) =>
  site.purchaseAreaOfTension && isSiteWithConsoData(site);
// &&
// !isSiteIsDisabled(site);

export const isSiteAnalyzed = (site) => site?.siteHasData || site.isFullSellOut;

// OPTIMIZATION VALIDATORS
export const isSiteOptimizable = isSiteAnalyzed;

/**
 * ARRAY OF SITES VALIDATORS
 */

// CONSODATA VALIDATORS
export const isAllSitesHaveConsoData = (sites) =>
  sites.every(isSiteWithConsoData);

export const isSomeSitesExtractingData = (sites) =>
  sites.some(isSiteExtractingData);

export const isSomeSitesHasError = (sites) => sites.some(isSiteHasError);

export const isSomeSitesNeedUpdate = (sites) => sites.some(isSiteNeedUpdate);

// ANALYSIS VALIDATORS
export const isAllSitesAnalyzable = (sites) => sites.every(isSiteAnalyzable);

export const isAllSitesAnalyzed = (sites) => sites.every(isSiteAnalyzed);

// OPTIMIZATION VALIDATORS
export const isAllSitesOptimizable = (sites) => sites.every(isSiteOptimizable);

//SCENARIOS VALIDATORS
export const isScenarioConsumer = (scenario) =>
  !scenario?.isACCScenario && !isScenarioSupplier(scenario);

export const isScenarioConsumerPlus = (scenario) => scenario?.isACCScenario;

export const isScenarioSupplier = (scenario) => !scenario?.objectives;

// ACC VALIDATORS
// Used to check if user has access to ACC sites
export const isUserAllowedForACC = (site) => {
  const { type } = getUserData();
  return (
    ![SiteConstants.type.CONSUMER_PLUS].includes(site?.type) ||
    ([SiteConstants.type.CONSUMER_PLUS].includes(type) &&
      site.type === SiteConstants.type.CONSUMER_PLUS)
  );
};

//USER
export const isUserDemo = () => {
  const { license } = getUserData();
  return license === PLANS().DEMO.toUpperCase();
};

export const isUserLeads = () => {
  const { license } = getUserData();
  return license === PLANS().LEADS.name;
};

export const isUserEssential = () => {
  const { license } = getUserData();
  return license === PLANS().ESSENTIAL.name;
};

export const isLicenceExpired = () => {
  const { licenseEndDate } = getUserData();
  return dayjs(licenseEndDate, "DD/MM/YYYY").isBefore(dayjs());
};

export const isLicenceBefore2024 = () => {
  const { licenseStartDate } = getUserData();
  return dayjs(licenseStartDate, "DD/MM/YYYY").isBefore(
    dayjs("01/01/2024", "DD/MM/YYYY"),
  );
};

/**
 * Decides whether or not the user can add the following techs :
 * Gen, FuelCell, Electrolysis, Battery, ChargingStations
 * @returns
 */
export const shouldUserBeBlockedForSomeTechs = () =>
  isUserEssential() && !isLicenceBefore2024();

export const isUserExpiredDemo = () => isUserDemo() && isLicenceExpired();

export const isUserSubordinate = () => !!getUserData().managerId;

export const isUserAllowedToChangeParams = () => {
  const { isAbleToChangeParameters } = getUserData();
  if (!isUserSubordinate()) return true;
  return isAbleToChangeParameters;
};

/**
 *
 * @param {function} condition A function checking a condition
 * @param {function} actionIfTrue The function to execute if condition is true
 * @param {function} actionIfFalse The function to execute if condition is false
 * @returns {function}
 */
export const isUserAllowedToAction = (
  condition = () => {},
  actionIfTrue = () => {},
  actionIfFalse = () => {},
) => (condition() ? actionIfTrue() : actionIfFalse());

export const isHubspotCampaign = (key) => key.includes("WI_FREQUENCY");
export const isTutorialKey = (key) => key.includes("tutorialSeen");
export const isUserDataKey = (key) => key.includes("userData");

//LocalStorage

export const shouldLocalStorageKeyBeDeleted = (key) => {
  return !isHubspotCampaign(key) && !isTutorialKey(key) && !isUserDataKey(key);
};

export const shouldTutorialBeShown = (tutorialName) =>
  !localStorage.getItem(`tutorialSeen_${tutorialName}`);

export const setTutorialAsShown = (tutorialName) =>
  localStorage.setItem(`tutorialSeen_${tutorialName}`, true);

export const unsetTutorialAsShown = (tutorialName) =>
  localStorage.removeItem(`tutorialSeen_${tutorialName}`);
