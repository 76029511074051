import React, { useContext } from "react";
import LOGO from "../assets/img/logo.png";
import LocaleContext from "../context/LocaleContext";
import "../containers/ShortcutPage.scss";

export const CreateShortcutHelper = ({ flexDirection = "column" }) => {
  const { i18n } = useContext(LocaleContext);
  return (
    <div className="shortcut-page">
      <div className="shortcut-container" style={{ flexDirection }}>
        <a href="https://app.likewatt.com/#/">
          <div
            id="icon"
            style={{
              width: "128px",
              height: "128px",
              backgroundImage: `url(${LOGO})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div id="title" style={{ visibility: "hidden" }}>
            Optiwize
          </div>
        </a>
        <p>{i18n.t("dragAndDropIcon")}</p>
        <section>
          <p>{i18n.t("orFromChrome")} :</p>
          <ul>
            <li>
              {" "}
              {i18n.t("parameters")} <i className="fa fa-ellipsis-v"></i> ->{" "}
              {i18n.t("moreTools")} ->
              {i18n.t("chromeCreateShortcut")}
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};
