import moment from "moment";
import _ from "lodash";

class ForeignSite {
  computeReportDataStep = (reportData, isOptimize) => {
    const key = isOptimize ? "Date" : "dateTime";
    return Math.abs(
      moment(reportData[reportData.length - 1][key]).diff(
        moment(reportData[reportData.length - 2][key]),
        "hour",
        true,
      ),
    );
  };
  formatTarifsParams = (tarifs, restOfTheYear, optim = false) => {
    // Formats prices from TarifBuilder into [{months : [], hours : [], price : int}]
    let newTarifs = [...tarifs];
    let payload = [];
    newTarifs.forEach((tarif) => {
      return payload.push({
        name: tarif.name ?? "",
        months: tarif.months.map((m) => m.month),
        hours: this.getHoursVector(tarif.startHour, tarif.endHour),
        subscriptionPrice: +tarif.subscriptionRate,
        energyPrice: +tarif.energyRate,
        subscribedPower: optim ? "null" : +tarif.subscribedPower,
        days: tarif.days,
      });
    });
    payload = [
      ...payload,
      {
        name: "Rest",
        months: [],
        hours: [],
        subscriptionPrice: +restOfTheYear.subscriptionRate,
        energyPrice: +restOfTheYear.energyRate,
        subscribedPower: optim ? "null" : +restOfTheYear.subscribedPower,
        days: [],
      },
    ];
    return payload;
  };
  getHoursVector = (start, end) => {
    // gives a vector for hours
    // if start == 14, end == 16, vector = [14,15]
    let vector = [];
    let span = end - start;
    if (span > 0) {
      for (let i = start; i < end; i++) {
        vector.push(i);
      }
    } else {
      for (let i = start; i < 25; i++) {
        if (i === 24) {
          vector.push(0);
          continue;
        }
        vector.push(i);
      }
      for (let i = 1; i < end; i++) {
        vector.push(i);
      }
    }
    return vector;
  };
  groupDataByMonths = (reportData = [], isOptimize = false) => {
    let resultToArray = [];
    let buffer = [];
    const valueKey = isOptimize ? "Soutirage" : "PA";
    const dateKey = isOptimize ? "Date" : "dateTime";

    // let t0 = performance.now();

    // console.log(`[Performance/Start] groupDataByMonths _.groupBy`);
    // Take up to 10 secondes to group by

    // -----------------------------
    reportData = _.groupBy(reportData, (value) =>
      parseInt(moment(value[dateKey]).year()),
    );

    let years = Object.keys(reportData);

    for (
      let year = parseInt(years[0]);
      year <= parseInt(years[years.length - 1]);
      year++
    ) {
      buffer.push(
        _.groupBy(reportData[year], (value) => {
          return moment(value[dateKey]).month() + 1;
        }),
      );
    }

    for (let i = 0; i < buffer.length; i++) {
      for (var key in buffer[i]) {
        if (!isOptimize) {
          resultToArray.push({
            month: key,
            monthStr: moment(key, "M").format("MMMM"),
            value: buffer[i][key],
          });
        } else {
          resultToArray.push({
            month: key,
            monthStr: moment(key, "M").format("MMMM"),
            value: buffer[i][key].map(({ Date, Soutirage, ...row }) => {
              return {
                dateTime: moment(Date, "YYYY-MM-DD HH:mm:ss").format(),
                PA: +Soutirage,
                PRI: +row.PRI || 0,
                ...row,
              };
            }),
          });
        }
      }
    }
    return resultToArray;

    // for (let i = 0; i < buffer.length; i++) {
    //   for (var key in buffer[i]) {
    //     resultToArray.push({
    //       month: key,
    //       monthStr: moment(key, "M").format("MMMM"),
    //       value: buffer[i][key],
    //     });
    //   }
    // }

    // if (!isOptimize) return resultToArray;
    // return resultToArray.map((monthData) => {
    //   return {
    //     ...monthData,
    //     value: monthData.value.map((v) => ({
    //       dateTime: v[dateKey],
    //       PA: parseFloat(v[valueKey]),
    //       PRI: v.PRI ?? 0,
    //     })),
    //   };
    // });
  };

  computeTimeStepPerRow = (row, prevRow, isOptimize = false) => {
    const key = isOptimize ? "Date" : "dateTime";
    return Math.abs(moment(row[key]).diff(moment(prevRow[key]), "hour", true));
  };

  addTimeStepToReport = (report, isOptimize = false) => {
    report = report.map((rowData, index, array) => {
      return {
        ...rowData,
        timeStep:
          isOptimize || !index
            ? 1
            : this.computeTimeStepPerRow(rowData, array[index - 1]),
      };
    });
    report[0].timeStep = report[1].timeStep;
    return report;
  };

  getMonthEnergy = (site, monthData) => {
    const { tarifs } = site;
    let notInRange = 0;
    let energyArray = [];
    for (let i = 0; i < tarifs.length; i++) {
      energyArray.push(0);
    }
    let rawMonth = moment(monthData[0].dateTime).get("month") + 1;

    monthData.forEach((row) => {
      nextTarif: for (let i = 0; i < tarifs.length; i++) {
        let dayOfWeek = moment(row.dateTime).isoWeekday();
        if (
          tarifs[i].months.map((m) => m.month).includes(rawMonth) &&
          tarifs[i].days.includes(dayOfWeek) &&
          this.isInHourRange(
            tarifs[i].startHour,
            tarifs[i].endHour,
            row.dateTime,
          )
        ) {
          energyArray[i] += row.PA * row.timeStep;
          return;
        } else {
          //If dateTime can't be found anywhere, row is out of range
          if (i + 1 === tarifs.length) {
            notInRange += row.PA * row.timeStep;
            return;
          }
          // if it doesn't work for this tarif, skip to the next
          continue nextTarif;
        }
      }
    });

    return [...energyArray, notInRange];
    /* If dateTime is not in any tarif, return 0 */
  };

  calcCosts_PE = (site, monthEnergy) => {
    const wholeYearTarifs = [...site.tarifs, site.restOfTheYearTarifs];
    /** Purchased Energy */
    return monthEnergy
      .map((PA, index) => (PA * wholeYearTarifs[index].energyRate) / 100)
      .reduce((sum, PA) => sum + PA, 0);
  };
  calcCosts_PP(site, monthData, isOptimize, leapYear) {
    /** Purchased Power */
    let PP = 0;
    const daysInYear = leapYear ? 366 : 365;
    monthData = monthData.forEach(
      (rowData) =>
        (PP +=
          (this.getRowDataSubP(site, rowData, isOptimize) *
            this.getRowDataSubscriptionRate(site, rowData, isOptimize) *
            rowData.timeStep) /
          24 /
          daysInYear),
    );
    return PP;
  }

  isInHourRange = (rangeStart, rangeEnd, dateTime) => {
    const hour = moment(dateTime).get("hour");
    let isIn = false;
    if (parseInt(rangeStart) < parseInt(rangeEnd)) {
      if (
        parseInt(hour) >= parseInt(rangeStart) &&
        parseInt(hour) < parseInt(rangeEnd)
      ) {
        isIn = true;
      }
    } else {
      if (
        parseInt(hour) < parseInt(rangeEnd) ||
        parseInt(hour) >= parseInt(rangeStart)
      ) {
        isIn = true;
      }
    }
    return isIn;
  };

  getRowDataEnergyPrice = (site, rowData, timeStep, monthEnergy) => {
    const { tarifs, restOfTheYearTarifs } = site;
    const { energyRate } = restOfTheYearTarifs;
    let energyPrice = 0;
    let rawMonth = moment(rowData.dateTime).get("month") + 1;
    let dayOfWeek = moment(rowData.dateTime).isoWeekday();
    for (let i = 0; i < tarifs.length; i++) {
      if (
        tarifs[i].months.map((m) => m.month).includes(rawMonth) &&
        tarifs[i].days.includes(dayOfWeek) &&
        this.isInHourRange(
          tarifs[i].startHour,
          tarifs[i].endHour,
          rowData.dateTime,
        )
      ) {
        energyPrice = parseFloat(tarifs[i].energyRate);
        return energyPrice * monthEnergy[i];
      } else {
        return energyRate;
      }
    }
    /* If dateTime is not in any tarif, return 0 */
    return energyRate;
  };

  getRowDataSubP = (site, rowData, isOptimize) => {
    const { tarifs, restOfTheYearTarifs } = site;
    const { subscribedPower } = restOfTheYearTarifs;
    let subP = 0;
    let rawMonth = moment(rowData.dateTime).get("month") + 1;
    let dayOfWeek = moment(rowData.dateTime).isoWeekday();

    nextRow: for (let i = 0; i < tarifs.length; i++) {
      if (
        tarifs[i].months.map((m) => m.month).includes(rawMonth) &&
        tarifs[i].days.includes(dayOfWeek) &&
        this.isInHourRange(
          tarifs[i].startHour,
          tarifs[i].endHour,
          rowData.dateTime,
        )
      ) {
        subP = parseFloat(tarifs[i].subscribedPower);
        return subP;
      } else {
        //If there is no next row, return restOfTheYear value
        if (i + 1 === tarifs.length) return subscribedPower;
        //If not in this tarif, go nex
        continue nextRow;
      }
    }
  };

  getRowDataSubscriptionRate(site, rowData, isOptimize) {
    const { tarifs, restOfTheYearTarifs } = site;
    const { subscriptionRate } = restOfTheYearTarifs;
    let subR = 0;
    let rawMonth = moment(rowData.dateTime).get("month") + 1;
    let dayOfWeek = moment(rowData.dateTime).isoWeekday();

    nextRow: for (let i = 0; i < tarifs.length; i++) {
      if (
        tarifs[i].months.map((m) => m.month).includes(rawMonth) &&
        tarifs[i].days.includes(dayOfWeek) &&
        this.isInHourRange(
          tarifs[i].startHour,
          tarifs[i].endHour,
          rowData.dateTime,
        )
      ) {
        subR = parseFloat(tarifs[i].subscriptionRate);
        return subR;
      } else {
        //If there is no next row, return restOfTheYear value
        if (i + 1 === tarifs.length) return subscriptionRate;
        //If not in this tarif, go next
        continue nextRow;
      }
    }
  }

  ASYNC_calcCostsByMonth = async (site, reportData, isOptimize = false) => {
    let costGroupByMonths = isOptimize
      ? this.addTimeStepToReport(reportData, isOptimize)
      : reportData;

    costGroupByMonths = this.groupDataByMonths(costGroupByMonths, isOptimize);

    const leapYear = isOptimize
      ? !!reportData.find((row) =>
          moment(row.Date).isSame(`${moment(row.Date).year()}-02-29`),
        )
      : !!reportData.find((row) =>
          moment(row.dateTime).isSame(`${moment(row.dateTime).year()}-02-29`),
        );
    costGroupByMonths = costGroupByMonths.map((monthData) => ({
      ...monthData,
      energy: this.getMonthEnergy(site, monthData.value),
    }));
    // console.log({ costGroupByMonths });
    costGroupByMonths = costGroupByMonths.map((monthData) => ({
      ...monthData,

      CER: 0,
      PE: this.calcCosts_PE(site, monthData.energy),
      PP: this.calcCosts_PP(site, monthData.value, isOptimize, leapYear),
    }));

    // Calc total from previous calcs

    costGroupByMonths = costGroupByMonths.map((monthDataWithCost) => ({
      ...monthDataWithCost,
      total: monthDataWithCost.PE + monthDataWithCost.PP,
    }));

    return costGroupByMonths;
  };
}

export default new ForeignSite();
