import React from "react";
import styled, { css } from "styled-components";

export const CustomButton = (props) => {
  const {
    children = "defaultLabel",
    onClick = () => {
      console.log("Button handler");
    },
    iconPosition = "left",
    margin = "10px",
    loading = false,
  } = props;

  return (
    <StyledButton onClick={onClick} {...props} disable={props.disabled}>
      <WrapperButton style={loading ? { opacity: 0 } : {}} flex={props.flex}>
        {iconPosition === "left" && props.icon && (
          <StyledIconWrapper marginLeft={margin} fontSize={props.fontSize}>
            {props.icon}
          </StyledIconWrapper>
        )}
        {children}
        {iconPosition === "right" && props.icon && (
          <StyledIconWrapper marginRight={margin}>
            {props.icon}
          </StyledIconWrapper>
        )}
      </WrapperButton>
    </StyledButton>
  );
};
const WrapperButton = styled.div`
  display: ${(props) => props.flex && "flex"};
  align-items: ${(props) => props.flex && "center"};
  margin: auto;
`;
const StyledButton = styled.button`
  width: ${(props) => props.width || "auto"};
  background-color: ${(props) => props.background || "#003b58"};
  color: ${(props) => props.color || "white"};
  padding: ${(props) => props.padding || "1%"};
  margin-left: ${(props) => props.marginLeft || "3%"};
  font-size: ${(props) => props.fontSize || "1rem !important"};
  font-family: ${(props) => props.fontFamily || "Roboto"};
  transition: 0.3s ease 0s;
  float: ${(props) => props.float && "right"};
  border-radius: 5px;
  border: ${(props) => props.border || "#006276"};
  cursor: pointer;
  position: relative;
  display: ${(props) => (props.flex ? "flex" : "inline-flex")};
  align-items: ${(props) => props.flex && "center"};

  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor || "#044981"};
    background-color: ${(props) => !props.current || "#dcecf5"};
    color: ${(props) => !props.disable || "black"};
    background-color: ${(props) => !props.disable || "lightgrey"};
    color: ${(props) => !props.disable || "black"};
  }

  background-color: ${(props) => !props.disable || "lightgrey"};
  color: ${(props) => !props.disable || "black"};
  cursor: ${(props) => !props.disable || "not-allowed"};
  border: ${(props) => !props.disable || "solid #006276 1px"};

  background-color: ${(props) => !props.current || "white"};
  color: ${(props) => !props.current || "black"};
  border: ${(props) => !props.current || "solid #006276 1px"};
  border: ${(props) => props.isWindow && "solid grey 1px"};
  margin-right: ${(props) => props.isWindow && "0"};
  border-radius: ${(props) => props.roundedLeft && "50px 0 0 50px"};
  border-radius: ${(props) => props.roundedRight && "0 50px 50px 0"};
  &::after {
    position: absolute;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.5em);
    position: absolute !important;

    ${(props) =>
      props.loading &&
      css`
        animation: spinAround 0.5s infinite linear;
        border: 2px solid #003b58;
        border-radius: 290486px;
        border-right-color: ${props.loaderColor || "transparent"};
        border-top-color: ${props.loaderColor || "transparent"};
        content: "";
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
      `}
  }

  *,
  &::after,
  &::before {
    box-sizing: inherit;
  }
`;

const StyledIconWrapper = styled.span`
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : props.marginRight};
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : props.marginLeft};
  font-size: ${(props) => props.fontSize || "17px"};
`;
export const StyledLoader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledButtonContainer = styled.div`
  margin-right: 5%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
