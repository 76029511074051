import DepartementTaxHelper from "../../helpers/DepartementalTax";
import moment from "moment";
import to from "await-to-js";
import SiteService from "../../services/Site";
import ApiService from "../../services/Api";
import UserService from "../../services/User";
import Auth from "../../services/Auth";
import SiteHelper from "../../helpers/Site";
import Enedis from "../../helpers/Enedis";
import Randomstring from "randomstring";
import SiteConstants from "../../constants/Site";

const COUNTRY_SHORTNAME = ["GP", "MQ", "GF", "RE", "YT"];

async function handleBTINF(site, data, enedisNumber, startDate, endDate) {
  if (enedisNumber === data._attributes.id) {
    return {
      site: {
        ...site,
        dataSource: "ENEDIS",

        _lastUpdate: moment().format("DD/MM/YYYY HH:mm"),
        _step3: true,
        _step3At: moment().format(),
        automaticUpdateError: null,
        error: false,
        success: true,
        analysisId: "",
        enedisData: true,
        reportURL: "",
        startDate: startDate,
        endDate: endDate,
        connectingPower:
          data.situationAlimentation.alimentationPrincipale
            .puissanceRaccordementSoutirage.valeur._text,
        purchaseAreaOfTension: Enedis.formatAreaOfTension(
          data.situationAlimentation.alimentationPrincipale.domaineTension
            ._attributes.code,
        ),
        offPeakHours: data.situationComptage.dispositifComptage.relais
          ? Enedis.getHoursBTINF(
              data.situationComptage.dispositifComptage.relais
                .plageHeuresCreuses._text,
            )
          : [
              {
                id: Randomstring.generate(10),
                startTime: 23,
                endTime: 6,
              },
            ],
        ratesOption: Enedis.getRatesOption(
          data.situationContractuelle.structureTarifaire
            .formuleTarifaireAcheminement._attributes.code,
        ),
        purchaseContractType: Enedis.getPurchaseContractType(
          data.donneesGenerales.segment._attributes.code,
        ),
        subscribedPowers: [
          {
            PTE: parseInt(
              data.situationContractuelle.structureTarifaire
                .puissanceSouscriteMax.valeur._text,
            ),
            HCB: parseInt(
              data.situationContractuelle.structureTarifaire
                .puissanceSouscriteMax.valeur._text,
            ),
            HCH: parseInt(
              data.situationContractuelle.structureTarifaire
                .puissanceSouscriteMax.valeur._text,
            ),
            HPB: parseInt(
              data.situationContractuelle.structureTarifaire
                .puissanceSouscriteMax.valeur._text,
            ),
            HPH: parseInt(
              data.situationContractuelle.structureTarifaire
                .puissanceSouscriteMax.valeur._text,
            ),
            id: Randomstring.generate(10),
          },
        ],
        subscriptionRates: 0,
        energyPrices: [
          {
            id: Randomstring.generate(10),
            PTE: 0,
            HPH: 0,
            HCH: 0,
            HPB: 0,
            HCB: 0,
          },
        ],
      },
    };
  }
}

const formatDepartmentNumber = (place) => {
  //get Department number without zipCode

  const hasZipCode = place.address_components.find((e) =>
    e.types.find((type) => type === "postal_code"),
  );
  const isCorsica =
    place.address_components.find((address) =>
      address.types.find((type) => type === "administrative_area_level_1"),
    ).long_name === "Corse";

  const isOversea = COUNTRY_SHORTNAME.includes(
    place.address_components.find((address) =>
      address.types.find((type) => type === "country"),
    ).short_name,
  );
  // Trimming department to remove 0 in first position, or returns the first two figures
  let addressDepartementNum =
    place.address_components
      .find((e) => e.types.find((type) => type === "postal_code"))
      ?.long_name.charAt(0) === "0"
      ? place.address_components
          .find((e) => e.types.find((type) => type === "postal_code"))
          ?.long_name.charAt(1)
      : place.address_components
          .find((e) => e.types.find((type) => type === "postal_code"))
          ?.long_name.substring(0, 2);

  if (!hasZipCode && !isOversea) {
    let departement = place.address_components
      .find((e) =>
        e.types.find((type) => type === "administrative_area_level_2"),
      )
      .long_name.toUpperCase();
    departement =
      DepartementTaxHelper.getDepartementalNumberByName(departement);

    return departement;
  }
  // Dealing with Corse as Google returns 20 instead of 02B or 02A
  if (isCorsica) {
    let departement = place.address_components.find((e) =>
      e.types.find((type) => type === "administrative_area_level_2"),
    ).long_name;
    switch (departement) {
      case "Haute-Corse":
        return "02B";
      default:
        return "02A";
    }
  }

  if (isOversea) {
    let departementName = place.address_components.find((e) =>
      e.types.find((type) => type === "country"),
    ).long_name;
    if (departementName === "Guyane française") departementName = "Guyane";
    addressDepartementNum = DepartementTaxHelper.getDepartementalNumberByName(
      departementName.toUpperCase(),
    );
  }
  return addressDepartementNum;
};

export function buildAddressObject(
  setState,
  site,
  place,
  country = "fr",
  timezone,
) {
  console.log("buildAddressObject");
  const FRANCE = ["fr", "re", "gp", "mq", "gf"];
  if (!FRANCE.includes(country)) {
    return setState({
      ...site,
      address: place.formatted_address,
      addressCity: "LYON",
      addressDepartement: "RHÔNE",
      addressDepartementNum: 69,
      addressZipCode: "69003",
      addressGeocode: [
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      ],
      timezone: timezone,
    });
  }
  let addressCity = place.address_components
    .find((e) => e.types.find((type) => type === "locality"))
    .long_name.toUpperCase();

  let addressDepartementNum = formatDepartmentNumber(place);

  setState({
    ...site,
    address: place.formatted_address,
    addressCity,
    addressZipCode:
      place.address_components.find((e) =>
        e.types.find((type) => type === "postal_code"),
      )?.long_name || addressDepartementNum.toString(),
    addressGeocode: [
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    ],
    addressDepartementNum: parseInt(addressDepartementNum),
    timezone: timezone,
  });
  setState((prev) => ({
    ...prev,
  }));
}

export function canGoToStepTwo(site) {
  const { name, type, address } = site;

  if (name.length !== 0 && type.length !== 0 && address.length !== 0)
    return true;
  return false;
}

const checkPRM = (prm) => prm.length === 14;

const formatErrorStatus = (err) => {
  switch (err) {
    case 503:
      return "Erreur de récupération du TMY";
    case 500:
      return "Erreur : le service d'Enedis est momentanément indisponible";
    case 404:
      return "Site introuvable";
    case 400:
      return "Erreur de format du numéro de compteur : le numéro de compteur soit comporter 14 chiffres";

    default:
      return err;
  }
};

export function canGoToStepThree(rows) {
  if (rows.length == 0) return false;
  if (rows.find((row) => !row.id)) return false;

  let results = rows.map((row) => {
    if (!row.name) {
      // check if row has a name
      return true;
    }
    if (!row?.reportURL && !row?.enedisNumber) return true;
    if (row?.enedisNumber.length > 0) {
      //if site works with PRM
      if (!checkPRM(row.enedisNumber)) {
        return true;
      } else if (checkPRM(row.enedisNumber) && !row.agreed) {
        return true;
      } else if (row.error) return true;
      else {
        return false;
      }
    } else if (!row.reportURL) {
      //if row works with reportURL

      return true;
    } else {
      return false;
    }
  });
  return results.find((e) => e) ? false : true;
}

const uploadProof = async (site, user) => {
  const { lastname, firstname, address, email } = user;
  const { id, _agreedAt, _step3, _step3At, enedisNumber, agreed } = site;
  const _ip = null;
  let err = null;
  let payload = {
    nom: lastname,
    prénom: firstname,
    adresse: address,
    email: email,
    consentement: agreed,
    date_Consentement: _agreedAt,
    clic: _step3,
    date_clic: _step3At,
    ip: _ip,
    prm: enedisNumber,
  };
  [err] = await to(SiteService.uploadEnedisProof(id, payload));
};

// ajoute les parametres recu par enedis dans le site
// purchaseAreaOfTension
// offPeakHours
// ratesOption
// purchaseContractType
// subscribedPowers
//
// energyPrices
export async function getEnedisData(
  site,
  enedisNumber,
  editMode,
  siteToEdit = {},
) {
  let data,
    user,
    err = null;

  [err, user] = await to(UserService.get(Auth.getUserId()));
  if (err)
    return Promise.reject((err) => {
      return {
        site: {
          ...site,
          error: "Erreur lors de la récupération de l'utilisateur",
        },
      };
    });

  let startDate = moment().subtract(2, "y").format("DD-MM-YYYY HH:mm");
  let endDate = moment().format("DD-MM-YYYY HH:mm");

  let id = site.id;

  if (editMode && siteToEdit.enedisNumber === "") {
    SiteService.deleteReport(siteToEdit.id);
    id = siteToEdit.id;
  }
  await SiteService.update(id, {
    ...site,
    automaticUpdateError: null,
  });
  // 50041968113681
  // 1 heure 30001940645861
  // BT<36 kVa 19109695879000

  // [err, res] = await to(ApiService.initData(id, enedisNumber));
  // if (err) {
  //   return Promise.reject((err) => {
  //     // this.setState({
  //     //   fetchingEnedis: false,
  //     //   error: "Erreur lors de la connexion avec Enedis",
  //     // });
  //     return {
  //       site: { ...site, error: "Erreur lors de la connexion avec Enedis" },
  //     };
  //   });
  // }
  [err, data] = await to(ApiService.getContractInfo(enedisNumber));
  if (err) {
    return Promise.reject((err) => {
      // this.setState({
      //   fetchingEnedis: false,
      //   error: "Erreur lors de la connexion avec Enedis",
      // });
      return {
        site: { ...site, error: "Erreur lors de la connexion avec Enedis" },
      };
    });
  }

  if (site.enedisNumber !== "") uploadProof(site, user);

  if (data.data) {
    const error =
      data.data?.["soapenv:Envelope"]?.["soap:Body"]?.["ns3:Fault"]?.faultstring
        ?._text || data.data.statusCode;

    SiteService.update(id, {
      ...site,
      agreed: false,
      automaticUpdateError: formatErrorStatus(error),
      faltyPrm: enedisNumber,
    });

    return Promise.reject((err) => {
      return {
        site: { ...site, error: "Erreur lors de la connexion avec Enedis" },
      };
    });
  }
  // if (data.data) {
  //   setSitesToConfigure({
  //     fetchingEnedis: false,
  //     error: data.data["soapenv:Envelope"]?.["soapenv:Body"]
  //       ? data.data["soapenv:Envelope"]?.["soapenv:Body"]?.["soapenv:Fault"]
  //           .faultstring._text
  //       : data.data["soapenv:Envelope"]?.["soap:Body"]?.["soap:Fault"]
  //           .faultstring._text,
  //   });
  //   SiteService.setFaltyPrm(id, enedisNumber);
  //   return Promise.reject();
  // }

  if (
    data.situationAlimentation.alimentationPrincipale.domaineTension._attributes
      .code !== "BTINF"
  ) {
    if (editMode) {
      SiteService.setFaltyPrm(id, null);
      if (enedisNumber === data._attributes.id) {
        return {
          site: {
            ...site,
            dataSource: "ENEDIS",
            automaticUpdateError: null,
            _lastUpdate: moment().format("DD/MM/YYYY HH:mm"),
            _step3: true,
            _step3At: moment().format(),
            enedisData: true,
            error: false,
            success: true,
            faltyPrm: null,
            reportURL: "",
            analysisId: "",
            startDate: startDate,
            endDate: endDate,
            connectingPower:
              data.situationAlimentation.alimentationPrincipale
                .puissanceRaccordementSoutirage.valeur._text,
            purchaseAreaOfTension: Enedis.formatAreaOfTension(
              data.situationAlimentation.alimentationPrincipale.domaineTension
                ._attributes.code,
            ),
            offPeakHours: Array.isArray(
              data.situationComptage.dispositifComptage.compteurs.compteur
                .programmationHoraire.programmationPosteHoraire,
            )
              ? Enedis.filterOffPeakHours(
                  data.situationComptage.dispositifComptage.compteurs.compteur.programmationHoraire.programmationPosteHoraire.filter(
                    (e) => e._attributes.code === "HC",
                  )[0],
                )
              : Enedis.filterOffPeakHours(
                  data.situationComptage.dispositifComptage.compteurs.compteur
                    .programmationHoraire.programmationPosteHoraire,
                ),
            ratesOption: Enedis.getRatesOption(
              data.situationContractuelle.structureTarifaire
                .formuleTarifaireAcheminement._attributes.code,
            ),
            purchaseContractType: Enedis.getPurchaseContractType(
              data.donneesGenerales.segment._attributes.code,
            ),
            subscribedPowers: Enedis.getSubscribedPowers(
              data.situationContractuelle.structureTarifaire.denivelePuissances
                .classesTemporelles.classeTemporelle,
              Enedis.formatAreaOfTension(
                data.situationAlimentation.alimentationPrincipale.domaineTension
                  ._attributes.code,
              ),
            ),
            subscriptionRates: 0,
            energyPrices: [
              {
                id: Randomstring.generate(10),
                PTE: 0,
                HPH: 0,
                HCH: 0,
                HPB: 0,
                HCB: 0,
              },
            ],
          },
        };
      }
    } else {
      SiteService.setFaltyPrm(id, null);
      return {
        site: {
          ...site,
          dataSource: "ENEDIS",

          _lastUpdate: moment().format("DD/MM/YYYY HH:mm"),
          _step3: true,
          _step3At: moment().format(),
          automaticUpdateError: null,
          enedisData: true,
          error: false,
          success: true,
          analysisId: "",
          faltyPrm: null,
          reportURL: "",
          startDate: startDate,
          endDate: endDate,
          connectingPower:
            data.situationAlimentation.alimentationPrincipale
              .puissanceRaccordementSoutirage.valeur._text,
          purchaseAreaOfTension: Enedis.formatAreaOfTension(
            data.situationAlimentation.alimentationPrincipale.domaineTension
              ._attributes.code,
          ),
          offPeakHours: data.situationComptage.dispositifComptage.compteurs
            .compteur.programmationHoraire
            ? Array.isArray(
                data.situationComptage.dispositifComptage.compteurs?.compteur
                  ?.programmationHoraire?.programmationPosteHoraire,
              )
              ? Enedis.filterOffPeakHours(
                  data.situationComptage.dispositifComptage.compteurs.compteur.programmationHoraire.programmationPosteHoraire.filter(
                    (e) => e._attributes.code === "HC",
                  )[0],
                )
              : Enedis.filterOffPeakHours(
                  data.situationComptage.dispositifComptage.compteurs.compteur
                    .programmationHoraire.programmationPosteHoraire,
                ) || [
                  {
                    id: Randomstring.generate(10),
                    startTime: 22,
                    endTime: 6,
                  },
                ]
            : [
                {
                  id: Randomstring.generate(10),
                  startTime: 22,
                  endTime: 6,
                },
              ],

          ratesOption: Enedis.getRatesOption(
            data.situationContractuelle.structureTarifaire
              .formuleTarifaireAcheminement._attributes.code,
          ),
          purchaseContractType: Enedis.getPurchaseContractType(
            data.donneesGenerales.segment._attributes.code,
          ),
          subscribedPowers: Enedis.getSubscribedPowers(
            data.situationContractuelle.structureTarifaire.denivelePuissances
              .classesTemporelles.classeTemporelle,
            Enedis.formatAreaOfTension(
              data.situationAlimentation.alimentationPrincipale.domaineTension
                ._attributes.code,
            ),
          ),
          subscriptionRates: 0,
          energyPrices: [
            {
              id: Randomstring.generate(10),
              PTE: 0,
              HPH: 0,
              HCH: 0,
              HPB: 0,
              HCB: 0,
            },
          ],
        },
      };
    }
  } else {
    // Enedis.getHoursBTINF(
    //   data.situationComptage.dispositifComptage.relais.plageHeuresCreuses
    //     ._text
    // );

    return await handleBTINF(
      site,

      data,
      enedisNumber,
      startDate,
      endDate,
    );
    // return this.setState({ error: "Domaine de tension non pris en charge" });
  }
}

export function createDefaultParameters(reportData = [], site) {
  const defaultSubscribedPowers = [
    {
      ...site.subscribedPowers[0],
    },
  ];
  const defaultSubscriptionRates = [
    {
      ...site.subscriptionRates[0],
    },
  ];
  const defaultEnergyPrices = [
    {
      ...site.energyPrices[0],
    },
  ];

  return {
    ...site,
    enedisData: false,
    subscribedPowers: defaultSubscribedPowers,
    subscriptionRates: defaultSubscriptionRates,
    energyPrices: defaultEnergyPrices,
    startDate: moment(reportData[1].dateTime).toString(),
    endDate: moment(reportData[reportData.length - 1].dateTime).toString(),
    error: false,
    success: true,
  };
}

export function canSubmitSite(site) {
  const {
    purchaseContractType,
    purchaseMeterOwner,
    purchaseAreaOfTension,
    ratesOption,
    subscribedPowers,
    energyPrices,
    subscriptionRates,
  } = site;

  if (
    purchaseContractType.length !== 0 &&
    purchaseMeterOwner.length !== 0 &&
    purchaseAreaOfTension.length !== 0 &&
    ratesOption.length !== 0 &&
    subscribedPowers.length !== 0 &&
    energyPrices.length !== 0 &&
    subscriptionRates.length !== 0
  )
    return true;
  return false;
}

export function handleChangeEnergyPriceInput(
  id,
  { target: { name, value } },
  site,
  setSite,
) {
  let energyPrices = site?.energyPrices.length ? [...site.energyPrices] : [];

  // if (value === "" || parseInt(value) < 0) value = "0";
  if (site.purchaseAreaOfTension !== "BT<36 kVA") {
    energyPrices = energyPrices.map((enyP /** energyPrice */) =>
      enyP.id === id ? { ...enyP, [name]: value } : enyP,
    );
    if (site.purchaseAreaOfTension === "BT>36 kVA")
      energyPrices.map((e) => (e.id === id ? { ...e, PTE: e.HPH } : e));
  }
  if (site.purchaseAreaOfTension === "BT<36 kVA") {
    energyPrices = energyPrices.map((enyP /** energyPrice */) => {
      if (enyP.id === id) {
        switch (site.ratesOption) {
          // return {
          //   ...enyP,
          //   HCB: value,
          //   HCH: value,
          //   HPH: value,
          //   HPB: value,
          //   PTE: value,
          // };
          case SiteConstants.ratesOption.CU:
          case SiteConstants.ratesOption.LU:
          case SiteConstants.ratesOption.MUDT:
            if (name === "HPB") {
              return { ...enyP, PTE: value, HPH: value, [name]: value };
            } else {
              return {
                ...enyP,
                HCH: value,
                [name]: value,
              };
            }
          default:
            return { ...enyP, [name]: value, PTE: value };
        }
      }
    });
  }

  setSite({ ...site, energyPrices, analysisId: "" });
}

export function handleChangeOffPeakHourStartTime(id, startTime, site, setSite) {
  let offPeakHours = site?.offPeakHours.length ? [...site.offPeakHours] : [];
  offPeakHours = offPeakHours.map((pkH /** offPeakHour */) =>
    pkH.id === id ? { ...pkH, startTime } : pkH,
  );
  setSite({ ...site, offPeakHours, analysisId: "" });
}

export function handleChangeOffPeakHourEndTime(id, endTime, site, setSite) {
  let offPeakHours = site?.offPeakHours.length ? [...site.offPeakHours] : [];
  if (endTime === 0) endTime = 24;
  offPeakHours = offPeakHours.map((pkH /** offPeakHour */) =>
    pkH.id === id ? { ...pkH, endTime } : pkH,
  );

  setSite({ ...site, offPeakHours, analysisId: "" });
}

export function handleDeleteOffPeakHourLine(id, site, setSite) {
  setSite({
    ...site,
    offPeakHours: site.offPeakHours.filter(
      (pkH /** offPeakHour */) => pkH.id !== id,
    ),
    analysisId: "",
  });
}

export function handleAddOffPeakHoursLine(site, setSite) {
  setSite({
    ...site,
    offPeakHours: site.offPeakHours
      ? site.offPeakHours.concat(SiteHelper.initialStateOffPeakHour())
      : [].concat(SiteHelper.initialStateOffPeakHour()),
  });
}

export function handleChangeSubscribedPowerInput(
  id,
  { target: { name, value } },
  site,
  setSite,
) {
  let subscribedPowers = site?.subscribedPowers?.length
    ? [...site.subscribedPowers]
    : [];

  if (site.purchaseAreaOfTension !== "BT<36 kVA") {
    subscribedPowers = subscribedPowers.map((subP /** subscribedPower */) =>
      subP.id === id ? { ...subP, [name]: value } : subP,
    );
  }
  if (site.purchaseAreaOfTension === "BT<36 kVA") {
    subscribedPowers = subscribedPowers.map((subP /** energyPrice */) => {
      if (subP.id === id)
        return {
          ...subP,
          HCB: value,
          HCH: value,
          HPH: value,
          HPB: value,
          PTE: value,
        };
      // else return { ...enyP, HCH: value, [name]: value };
    });
  }
  setSite({ ...site, subscribedPowers, analysisId: "" });
}

export function handleChangeField({ value: e, site }) {
  const name = e.target.name;
  const value = e.target.value;
  return { ...site, [name]: value };
}

export function handleChangeAreaOfTension({ target }, site, setSite) {
  const newSite = {
    ...site,
    [target.name]: target.value,
    ratesOption: SiteConstants.areaOfTensionRatesOption[target.value][0],
    analysisId: "",
  };
  setSite(newSite);
}
