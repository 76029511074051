import firebase from "firebase";
import { uuid } from "uuidv4";
import UserService from "./User";
import { handleRedirects } from "../helpers/Router";
import dayjs from "dayjs";
import _ from "lodash";
export const checkPresence = async (props) => {
  const { history, timestamp } = props;
  // Fetch the current user's ID from Firebase Authentication.
  var uid = firebase.auth()?.currentUser?.uid;
  if (!uid) {
    
    return false;
  }
  // Fetch the current user's from Firebase Authentication with current uid.
  let account = await UserService.get(uid);
  let principalUid = uid; // Par défaut, on utilise l'uid actuel
  if(!account) return false;
  // Si l'utilisateur n'est pas un compte principal, récupérer le compte principal
  if (!account.principal && account.principalUser) {
    principalUid = account.principalUser; // On stocke l'uid du compte principal
    const principalAccount = await UserService.get(principalUid);
    if (principalAccount) {
      account = {
        ...principalAccount,
        ...account,
      };
    }
  }
  if (account.license === "C&I") {
    return true;
  }
  
  // Check if the user has a renew license. If so, we update the user's license.
  if (
    !_.isEmpty(account.renewLicense) &&
    dayjs().isAfter(dayjs(account.renewLicense.startDate, "DD/MM/YYYY")) &&
    dayjs().isBefore(dayjs(account.renewLicense.endDate, "DD/MM/YYYY"))
  ) {
    let principalAccount = await UserService.get(principalUid);
    principalAccount = {
      ...principalAccount,
      allowedNumberOfSites: account.renewLicense?.allowedNumberOfSites,
      domain: account.renewLicense?.domain,
      endDate: account.renewLicense?.endDate,
      maxUsers: account.renewLicense?.maxUsers,
      optimNumber: account.renewLicense?.optimNumber,
      license: account.renewLicense?.plan,
      startDate: account.renewLicense?.startDate,
      trackerOkd: account.renewLicense?.trackerOkd,
      type: account.renewLicense?.type,
      renewLicense: {},
    };
    console.log(
      "[Presence] Updating principal user with renewed license",
      principalAccount
    );
    return await UserService.update(principalUid, principalAccount); // On utilise l'uid principal
  }

  // If licence is expired and user check autoRenew

  if (
    account.automaticRenew &&
    dayjs().isAfter(dayjs(account.endDate, "DD/MM/YYYY"))
  ) {
    const newEndDate = dayjs(account.endDate, "DD/MM/YYYY")
      .add(1, "year")
      .format("DD/MM/YYYY");

    console.log(
      "[Presence] Auto-renewing license with new end date:",
      newEndDate
    );
    return await UserService.update(principalUid, { endDate: newEndDate }); // On utilise l'uid principal
  }

  // Create a reference to this user's specific status node.
  const navId = localStorage.getItem("navId") ?? uuid();
  localStorage.setItem("navId", navId);

  // Create a reference to this account.
  var userStatusDatabaseRef = firebase.database().ref("/users/" + principalUid);

  // Create a reference to this user with the correct structure
  var navIdStatusDatabaseRef = firebase
    .database()
    .ref("/users/" + principalUid + "/" + uid);

  // Check if the user licence is expired.
  if (
    dayjs(account.startDate, "DD/MM/YYYY").isAfter(dayjs()) ||
    dayjs(account.endDate, "DD/MM/YYYY").isBefore(dayjs())
  ) {
    if (
      dayjs(account._createdAt, "DD/MM/YYYY")
        .isBefore
        // dayjs().subtract(15, "day"),
        () &&
      account.license !== "DEMO"
    ) {
      
      handleRedirects(history, "#/signout");
      return window.location.reload();
    }
  }

  // Fetching data from realtime database of the current user.
  async function getData() {
    const result = await new Promise((resolve, reject) => {
      try {
        userStatusDatabaseRef.on("value", (snapshot) => {
          return resolve(snapshot.val());
        });
      } catch (error) {
        reject(error);
      }
    });
    const data = await result;
    if (data) {
      return data;
    } else {
      return {};
    }
  }
  const data = async () => await getData();
  const count = async () => Object.keys(await data()).length;

  // Vérifier si l'utilisateur est déjà connecté ou si le nombre max d'utilisateurs est atteint
  const dataRT = await data();

  // Vérifier si une autre session est active pour cet utilisateur

  // TODO: Décommenter pour activer la vérification de la session
  // if (dataRT?.[uid]?.navId && dataRT[uid].navId !== navId) {
  //   handleRedirects(history, "#/signout", { message: "Already connected" });
  //   return window.location.reload();
  // }

  // Compter le nombre d'utilisateurs uniques (différents uid)
  const uniqueUsersCount = Object.keys(dataRT).length;

  if (uniqueUsersCount >= account.maxUsers && !dataRT?.[uid]) {
    
    handleRedirects(history, "#/signout", { message: "Max users reached" });
    return window.location.reload();
  }

  navIdStatusDatabaseRef
    .onDisconnect()
    .remove()
    .then(async function () {
      const dataRT = await data();

      // Vérifier à nouveau si une autre session est active

      // TODO: Décommenter pour activer la vérification de la session
      // if (dataRT?.[uid]?.navId && dataRT[uid].navId !== navId) {
      //   console.log(
      //     "[Presence] Another session detected, redirecting to signout"
      //   );
      //   handleRedirects(history, "#/signout");
      //   return window.location.reload();
      // }

      if (uniqueUsersCount >= account.maxUsers && !dataRT?.[uid]) {
        
        handleRedirects(history, "#/signout");
        return window.location.reload();
      }

      const updates = {
        timestamp: timestamp,
        navId: navId,
      };

      navIdStatusDatabaseRef.update(updates);

      navIdStatusDatabaseRef.on("value", async function (snapshot) {
        if (!navigator.onLine) {
          
          navIdStatusDatabaseRef.child(navId).remove();
          return;
        }

        const sessions = snapshot.val();
        if (!sessions || sessions.navId === "logout") {
          
          if (sessions?.navId === "logout") {
            
            handleRedirects(history, "#/signout");
            return window.location.reload();
          }

          const dataRT = await data();
          const uniqueUsersCount = Object.keys(dataRT).length;

          if (uniqueUsersCount >= account.maxUsers && !dataRT?.[uid]) {
            
            handleRedirects(history, "#/signout");
            return window.location.reload();
          }
        }
      });
    });
};
