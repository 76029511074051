import React, { useContext, useState } from "react";
import LocaleContext from "../../../context/LocaleContext";
import SiteConstants from "../../../constants/Site";
import { Modal } from "react-bulma-components/full";
import EditorSiteForm from "../EditorSiteForm";
import { SiteListItem } from "../../Home/CollectiveSiteListItem";
import SiteService from "../../../services/Site";
import ScenarioService from "../../../services/Scenario";
// import CollectiveSitesService from "../../../services/CollectiveSites";
import { CustomButton } from "../../CustomButton";
import { CustomButtonSecondary } from "../../CustomButtonSecondary";
import styled from "styled-components";
import { ButtonsContainer } from "../Step1";

const AddCounterBtn = styled.button`
  background-color: #003b58;
  border: none;
  border-radius: 4px;
  color: white;
  font-family: "Roboto";
  width: 100%;
  margin-bottom: 30px;
  padding: 11px 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #044981;
  }
`;

const EditSiteFormModal = ({
  close,
  user,
  userDefaultRates,
  editMode,
  subSiteToEdit,
  collectiveSite,
  updateSubSite,
  deleteSubSite,
}) => {

  const closeAfterConsent = (site) => {
    updateSubSite(site);
    close();
  }
  const handleRemoveEditor = (site) => {
    deleteSubSite(site);
    close();
  }
  const handleSubmitSuccess = (site) => {
    const updatedSite = updateSubSite(site);
    // Check if sites are updated locally

    updatedSite.map(async (siteToUpdate) => {
      await SiteService.updateKey(
        siteToUpdate.id,
        "includeTVA",
        site.includeTVA,
      );
    });
    close();
  };
  return (
    <Modal
      show={true}
      onClose={close}
      className="collective-site-edit-site-form-modal"
    >
      <Modal.Card>
        <Modal.Card.Body>
          <EditorSiteForm
            isCollectiveSiteEditorMode={true}
            collectiveSite={collectiveSite}
            type={SiteConstants.type.CONSUMER}
            userType={user.type}
            onSubmitSuccess={handleSubmitSuccess}
            userDefaultRates={userDefaultRates}
            editMode={editMode}
            siteToEdit={subSiteToEdit}
            updateSubSite={updateSubSite}
            deleteSubSite={deleteSubSite}
            cancelEditor={close}
            closeAfterConsent={closeAfterConsent}
            handleRemoveEditor={handleRemoveEditor}
          />
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export function EditorCollectiveSiteForm(props) {
  const context = useContext(LocaleContext);
  const { Translation } = context;
  const [subSiteToEdit, setSubSiteToEdit] = useState(undefined);
  const [isShowEditSiteForm, setIsShowEditSiteForm] = useState(false);

  const { user, sites, setSites, isLoading } = props;

  const updateSubSite = (subSite) => {
    const siteExists = sites.some((site) => site.id === subSite.id);

    const shouldUpdateIncludeTVA = sites.some(
      (site) => site.includeTVA !== subSite.includeTVA,
    );

    // Upadte locally includeTVA key in all sites if is necessary
    const updatedSites = shouldUpdateIncludeTVA
      ? sites.map((site) => ({ ...site, includeTVA: subSite.includeTVA }))
      : sites;

    if (!siteExists) {
      // Add subsite if doesn't existed

      setSites([...updatedSites, subSite]);
      return [...updatedSites, subSite];
    }

    // If existing site, replace locally with updatedSite
    setSites(
      updatedSites.map((site) => (site.id === subSite.id ? subSite : site)),
    );

    return updatedSites.map((site) =>
      site.id === subSite.id ? subSite : site,
    );
  };

  const deleteSubSite = (site) => {
    // delete site from state
    setSites(sites.filter((subSite) => subSite.id !== site.id));
  };

  const editSite = (site) => {
    setSubSiteToEdit(site);
    setIsShowEditSiteForm(true);
  };

  const deletesubSiteFromDb = async (subSite) => {
    //We need to fetch collectiveSite to remove the subsite from its sites list
    const collectiveSite = await SiteService.getCollectiveSiteById(
      subSite.collectiveSiteId,
    );
    await SiteService.update(collectiveSite.id, {
      ...collectiveSite,
      sites: collectiveSite.sites.filter((site) => subSite.id !== site),
    });
    //We transfer the subsite to trash account as an individual site
    await SiteService.deleteSubSite(subSite);
    //We remove it form scenarios
    await ScenarioService.updateScenariosAfterSiteDeletion(subSite);
  };


  const close = () => {
    setSubSiteToEdit(undefined);
    setIsShowEditSiteForm(false);
  };

  return (
    <>
      {isShowEditSiteForm && (
        <EditSiteFormModal
          close={close}
          user={user}
          editMode={!!subSiteToEdit}
          subSiteToEdit={subSiteToEdit}
          updateSubSite={updateSubSite}
          deleteSubSite={deleteSubSite}
          collectiveSite={props.collectiveSite}
        />
      )}
      <div className="create-site-wrapper container">
        <div
          className="title is-4 has-text-centered"
          style={{ marginBottom: "0", width: "80%", margin: "auto" }}
        >
          <div className="subtitle is-uppercase">
            <Translation>
              {(t) => `${t("step")} 2 ${t("outOfPreposition")} 2`}
            </Translation>
          </div>
        </div>
        {/** list all the sites of the collective site */}
        <div className="collective-site-editor">
          {sites.map((site, i) => (
            <SiteListItem
              key={i}
              site={site}
              handleShowEditSite={editSite}
              handleDeletesubSite={(subSite) => {
                deletesubSiteFromDb(subSite);
                deleteSubSite(subSite);
              }}
              isCollectiveSiteEditorMode={true}
              fromEditor
              colorName="#9dcf56"
              fontSize="1.5rem"
              isMargin="0"
              fontWeight="500"
            />
          ))}
        </div>
        {sites.length >= 0 && (
          <AddCounterBtn
            onClick={() => setIsShowEditSiteForm(true)}
            disabled={isLoading}
          >
            {sites.length === 0 ? (
              <Translation>{(t) => t("addMyFirstCounter")}</Translation>
            ) : (
              <Translation>{(t) => t("addAnotherCounter")}</Translation>
            )}
          </AddCounterBtn>
        )}
        <ButtonsContainer marginTop="0">
          <CustomButton
            background="$lightgray"
            color="black"
            className="cancel-button"
            border="solid black 1px"
            hoverBackgroundColor="#C0C0C0"
            marginLeft="0"
            padding="8px 15px"
            onClick={props.cancelEditor}
            loading={isLoading}
            disabled={isLoading}
          >
            <Translation>{(t) => t("cancel")}</Translation>
          </CustomButton>
          <CustomButtonSecondary
            onClick={props.handlePrevStep}
            loading={isLoading}
            disabled={isLoading}
            marginLeft="0"
            padding="8px 15px"
          >
            <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />{" "}
            <Translation>{(t) => `${t("previous")}`}</Translation>
          </CustomButtonSecondary>
          <CustomButton
            onClick={props.onSubmitSuccess}
            loading={isLoading}
            disabled={sites.length < 1 || isLoading}
            marginLeft="0"
            padding="8px 15px"
          >
            <Translation>{(t) => `${t("save")}`}</Translation>{" "}
          </CustomButton>
        </ButtonsContainer>
      </div>
    </>
  );
}
