import React from "react";
import Auth from "./../services/Auth";
import { fire } from "./../fire";
import { handleRedirects } from "../helpers/Router";
import history from "../history";
import { shouldLocalStorageKeyBeDeleted } from "../helpers/Validators";

export default class Signout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!Auth.isAuthLocally()) return this.setState({ redirect: true });
    Object.keys(localStorage).map((key) => {
      if (shouldLocalStorageKeyBeDeleted(key)) localStorage.removeItem(key);
    });

    fire
      .auth()
      .signOut()
      .then(() => {
        Auth.logout();
        this.setState({ redirect: true });
      });
  }

  render() {
    return (
      <div>
        <div>{!this.state.redirect && "En cours de déconnexion..."}</div>
        <div hidden>
          {this.state.redirect &&
            handleRedirects(history, "#/signin") & window.location.reload()}
        </div>
      </div>
    );
  }
}
