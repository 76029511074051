import React, { useContext } from "react";
import styled from "styled-components";

import { capitalize } from "../utils/Utils";
import { Button, Modal } from "react-bulma-components/full";
import { blue } from "../assets/style/SharedColors";
const StyledModal = styled(Modal)`
  .modal-background {
    ${(props) => props.bodyOnly && `background-color: #ffffff8f;`}
    backdrop-filter: blur(3px);
  }
  .modal-card {
    ${(props) =>
      props.bodyOnly &&
      `
		border: 5px solid ${blue};
		border-radius: 10px;
	`}
    ${(props) => props.isFullHeight && `max-height:100%; height:48rem;`}
  }
  .modal-close {
    background-color: ${blue};
  }
`;

export const CustomModal = (props) => {
  const {
    children,
    onClose = () => {},
    onConfirm = () => {},
    show,
    title = "",
    type = "success",
    bodyOnly = false,
    isFullHeight = false,
    validateLabel = "Ok",
    cancelLabel = "",
    loading = false,
    notCloseOnEsc = false,
    showClose = false,
  } = props;

  return (
    <StyledModal
      show={show}
      onClose={onClose}
      bodyOnly={bodyOnly}
      isFullHeight={isFullHeight}
      closeOnEsc={!notCloseOnEsc}
      showClose={!showClose}
    >
      <Modal.Card>
        {!bodyOnly && (
          <Modal.Card.Head showClose={false}>
            {capitalize(title)}
          </Modal.Card.Head>
        )}
        <Modal.Card.Body>{children}</Modal.Card.Body>
        {!bodyOnly && (
          <Modal.Card.Foot style={{ justifyContent: "flex-end" }}>
            {cancelLabel && (
              <Button color={"sucess"} onClick={onClose} disabled={loading}>
                {cancelLabel}
              </Button>
            )}
            <Button color={type} onClick={onConfirm} disabled={loading}>
              {validateLabel}
            </Button>
          </Modal.Card.Foot>
        )}
      </Modal.Card>
    </StyledModal>
  );
};
