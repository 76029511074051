import DepartementalsTaxesData from "./../assets/data/taxe_departementale.json";

import DepartementalTaxConstants from "./../constants/DepartementalTax";

class DepartementalTax {
  constructor() {
    this.data = JSON.parse(JSON.stringify(DepartementalsTaxesData));
  }

  getByDepartementalNum = (num) =>
    this.data.find(
      (rowData) => rowData[DepartementalTaxConstants.FIELD_NUM] === num,
    );
  getByDepartementalName = (name) =>
    this.data.find(
      (rowData) => rowData[DepartementalTaxConstants.FIELD_NAME] === name,
    );
  getDepartementalNumberByName = (name) => {
    name = name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();

    return this.data.find(
      (rowData) => rowData[DepartementalTaxConstants.FIELD_NAME] === name,
    )[DepartementalTaxConstants.FIELD_NUM];
  };
}

export default new DepartementalTax();
