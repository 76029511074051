import React from "react";
import { Notification } from "react-bulma-components/full";
import styled from "styled-components";
import { info, lightInfo, danger } from "../assets/style/SharedColors";

const COLORS = {
  info,
  "light-info": lightInfo,
  danger,
};

const LOGOS = {
  info: "ℹ️",
  danger: "⚠️",
  locked: "🔒",
};

const StyledNotification = styled(Notification)`
  white-space: pre-line;
  ${(props) => props.color && `background-color : ${COLORS[props.color]}`}
  ${(props) => props.position && `position : ${props.position};bottom: 0`}
  ${(props) => props.noPadding && `padding : 0`};
  ${(props) =>
    props.noMargin &&
    `margin : 0; &:not(:last-child) {
	margin:0;
  }`};
  ${(props) => props.isBold && `font-weight:bold`};
  ${(props) => props.fontSize && `font-size:${props.fontSize}`};
  ${(props) => props.padding && `padding:${props.padding}`};
`;

export const StyledAbsoluteIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 2px;
  &:hover {
    cursor: pointer;
    background-color: lightgrey;
    color: ${COLORS["danger"]};
  }
`;

export const CustomNotification = ({
  children,
  logo,
  isCloseable = false,
  toggleNotification = () => {},
  ...props
}) => {
  return (
    <StyledNotification {...props}>
      {isCloseable && (
        <StyledAbsoluteIcon
          className="fa fa-times"
          onClick={toggleNotification}
        />
      )}
      {LOGOS[logo]} {children}
    </StyledNotification>
  );
};
