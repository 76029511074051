import i18next from "i18next";
import { capitalize } from "../utils/Utils";
const PLANS = () => ({
  DEMO: "Demo",
  //   BASIC: {
  //     name: "Basic",
  //     mainColor: "#006be5",
  //     backgroundColor: "#c4dbf4",
  //     description: [
  //       i18next.t("12MonthAccess"),
  //       `${capitalize(i18next.t("upTo"))} 10 ${i18next.t("projectsSimultaneously")}`,
  //       `${i18next.t("oneUserOnly")}`,
  //     ],
  //     icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2F10.jpg?alt=media&token=61a98138-410c-4ff0-9ff7-d86045b2ea42",
  //     allowedNumberOfSites: 10,
  //   },
  ESSENTIAL: {
    name: "Essentiel",
    mainColor: "#006be5",
    backgroundColor: "#c4dbf4",
    description: [
      i18next.t("12MonthAccess"),
      `${capitalize(i18next.t("upTo"))} 10 ${i18next.t(
        "projectsSimultaneously",
      )}`,
      `${i18next.t("oneUserOnly")}`,
    ],
    icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2F10.jpg?alt=media&token=61a98138-410c-4ff0-9ff7-d86045b2ea42",
    allowedNumberOfSites: 10,
    maxUsers: 1,
  },
  STANDARD: {
    name: "Standard",
    mainColor: "#a2d261",
    backgroundColor: "#e5f0d9",
    description: [
      i18next.t("12MonthAccess"),
      `${capitalize(i18next.t("upTo"))} 100 ${i18next.t(
        "projectsSimultaneously",
      )}`,
      `${i18next.t("oneUserOnly")}`,
    ],
    icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2F100.jpg?alt=media&token=c778ddfe-9678-4021-a0a7-12572e779960",
    allowedNumberOfSites: 50,
    maxUsers: 1,
  },
  PREMIUM: {
    name: "Premium",
    mainColor: "#1f5025",
    backgroundColor: "#24542a47",
    description: [
      i18next.t("12MonthAccess"),
      `${i18next.t("unlimitedProjects")}`,
      `${i18next.t("unlimitedUsers")}`,
    ],
    icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FInfini.jpg?alt=media&token=c0135366-78ad-49fd-bdf7-d015f5494651",
    allowedNumberOfSites: 300,
    maxUsers: 100,
  },
  CANDI: {
    name: "C&I",
    mainColor: "#1f5025",
    backgroundColor: "#24542a47",
    description: [
      i18next.t("12MonthAccess"),
      `${i18next.t("unlimitedProjects")}`,
      `${i18next.t("unlimitedUsers")}`,
    ],
    icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FInfini.jpg?alt=media&token=c0135366-78ad-49fd-bdf7-d015f5494651",
    allowedNumberOfSites: 1000,
    maxUsers: 100,
  },
  LEADS: {
    name: "Leads",
    mainColor: "#1f5025",
    backgroundColor: "#24542a47",
    description: [
      i18next.t("12MonthAccess"),
      `${i18next.t("unlimitedProjects")}`,
      `${i18next.t("unlimitedUsers")}`,
    ],
    icon: "https://firebasestorage.googleapis.com/v0/b/likewatt-4bb4b.appspot.com/o/utils%2FInfini.jpg?alt=media&token=c0135366-78ad-49fd-bdf7-d015f5494651",
    allowedNumberOfSites: 0,
    maxUsers: 100,
  },
});
export default PLANS;
