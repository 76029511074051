import Papa from "papaparse";
import FileNames from "./FileNames";
import {
  ConsoData,
  WT_TMY,
  PV_TMY,
  PV_POWERS,
  WT_POWERS,
  ELECTROLYSIS_DEMAND,
  ECS_DEMAND,
  DISTRIBKEYS,
  DATE_FORMATS,
  HOUR_FORMATS,
} from "./FileModels";
import moment from "moment";
import i18next from "i18next";

export const fileHasTwoColumnsForDate = (value) =>
  HOUR_FORMATS.some((format) => moment(value, format, true).isValid());

const dateWithTwoColumnsIsValid = (value) =>
  DATE_FORMATS.twoColumns.some((format) =>
    moment(value, format, true).isValid(),
  );
const dateWithOneColumnIsValid = (value) =>
  DATE_FORMATS.oneColumn.some((format) =>
    moment(value, format, true).isValid(),
  );
const hourIsValid = (value) =>
  HOUR_FORMATS.some((format) => moment(value, format, true).isValid());

const isDESIGNFileType = (firstRow) =>
  firstRow[0].includes(`${"Date&Hour" && "Date&Time"},GHI,DHI`);

const compareFileWithModel = async (
  model,
  meta,
  data,
  checkHeaders = false,
  counterNumber,
) => {
  const { format, headers } = model;

  const sample = data[0];

  console.log({ sample });

  return new Promise((resolve, reject) => {
    if (isDESIGNFileType(sample)) {
      return resolve();
    }

    //check delimiter
    if (meta.delimiter !== format.DELIMITER)
      return reject(
        new Error(
          `${i18next.t("separatorShouldBe")} ' ${
            format.DELIMITER
          } ' (${i18next.t("currently")} ' ${meta.delimiter} ')`,
        ),
      );
    data.shift();

    const dateHasTwoColumns = fileHasTwoColumnsForDate(data[0][1]);

    let dataIndex = dateHasTwoColumns ? 2 : 1;
    let dateIndex = 0;
    let hourIndex = dateHasTwoColumns ? 1 : 0;
    if (data[data.length - 1].length === 1) data.pop();
    const dataLength = data.length;

    const emptyLines = [];
    data.map((row, index) => {
      if (row.length > 1) {
        if (dateHasTwoColumns) {
          if (
            row[dateIndex] === "" ||
            row[hourIndex] === "" ||
            row[dataIndex] === ""
          ) {
            return emptyLines.push(index + 2);
          }
        } //+2 because we don't consider headers
      } else if (!dateHasTwoColumns) {
        if (row[dateIndex] === "" || row[dataIndex] === "") {
          return emptyLines.push(index + 2); //+2 because we don't consider headers
        }
      }
    });

    if (emptyLines.length > 0) {
      return reject(
        new Error(
          `${i18next.t("emptyOrIncompleteLine")} : ` +
            `${i18next.t("andMoreLines", {
              firstLine: emptyLines[0],
              lines: emptyLines.length - 1,
            })}`,
        ),
      );
    }
    for (let rowIndex = 0; rowIndex < dataLength; rowIndex++) {
      // check date format

      if (dateHasTwoColumns) {
        if (!dateWithTwoColumnsIsValid(data[rowIndex][dateIndex])) {
          return reject(
            new Error(
              `${i18next.t("invalidDateAtLine")} ${rowIndex + 1} : ${
                data[rowIndex][dateIndex]
              }. ${i18next.t("dateFormatMustBe")}`,
            ),
          );
        }

        if (!hourIsValid(data[rowIndex][hourIndex])) {
          return reject(
            new Error(
              `${i18next.t("invalidDateAtLine")} ${rowIndex + 1} : ${
                data[rowIndex][dateIndex]
              }. ${i18next.t("dateFormatMustBe")}`,
            ),
          );
        }

        if (
          DATE_FORMATS.oneColumn.some(
            (format) => moment(data[rowIndex][dateIndex], format).year() < 2020,
          )
        ) {
          return reject(
            new Error(
              `${i18next.t("invalidDateAtLine")} ${rowIndex + 1} : ${
                data[rowIndex][dateIndex]
              }. ${i18next.t("noDateBefore2020")}`,
            ),
          );
        }
      } else if (!dateHasTwoColumns) {
        if (!dateWithOneColumnIsValid(data[rowIndex][dataIndex - 1])) {
          return reject(
            new Error(
              `${i18next.t("invalidDateAtLine")} ${rowIndex + 1} : ${
                data[rowIndex][dateIndex]
              }. ${i18next.t("dateFormatMustBe")}`,
            ),
          );
        }
        if (
          DATE_FORMATS.oneColumn.some(
            (format) => moment(data[rowIndex][dateIndex], format).year() < 2020,
          )
        ) {
          return reject(
            new Error(
              `${i18next.t("invalidDateAtLine")} ${rowIndex + 1} : ${
                data[rowIndex][dateIndex]
              }. ${i18next.t("noDateBefore2020")}`,
            ),
          );
        }
      }

      if (
        model.name === "ConsoData" &&
        data[rowIndex][dataIndex].toString().includes(",")
      )
        return reject(
          new Error(
            `${i18next.t("errorAtLine")} ${rowIndex + 1}: ${i18next.t(
              "useValuesInWattsNoDecimal",
            )}`,
          ),
        );
      if (
        ["PvPowers", "WtPowers", "distribKeys"].includes(model.name) &&
        data[rowIndex][dataIndex].toString().includes(",")
      )
        return reject(
          new Error(
            `${i18next.t("errorAtLine")} ${rowIndex + 1}: ${i18next.t(
              "useValuesWithPointForDecimal",
            )}`,
          ),
        );

      if (
        model.name === "ConsoData" &&
        data[rowIndex][dataIndex].toString().includes(",")
      )
        return reject(
          new Error(
            `${i18next.t("errorAtLine")} ${rowIndex + 1}: ${i18next.t(
              "useValuesInWattsNoDecimal",
            )}`,
          ),
        );
    }

    if (model.name === "distribKeys") {
      const arrayHeaderFile = [];

      for (let i = 0; i < sample.length; i++) {
        const words = sample[i].split(",");
        for (let j = 0; j < words.length; j++) {
          arrayHeaderFile.push(words[j]);
        }
      }

      const slicedHeaders = arrayHeaderFile.slice(2);

      if (counterNumber !== slicedHeaders.length) {
        const errorMessages = [
          i18next.t("wrongCounterNumber"),
          `${i18next.t("numberCounterAdded")} ${counterNumber}`,
          `${i18next.t("numberCounterExpected")} ${slicedHeaders.length}`,
        ];

        return reject(new Error(errorMessages.join("\n")));
      }
    }

    return resolve("ALL GOOD");
  });
};

const loadModel = async (fileName, parsedFile, counterNumber) => {
  const { meta, data } = parsedFile;
  switch (fileName) {
    case FileNames.CONSO_DATA:
      return compareFileWithModel(ConsoData, meta, data);
    case FileNames.PV_PROD:
      return compareFileWithModel(PV_POWERS, meta, data, true);
    case FileNames.WT_PROD:
      return compareFileWithModel(WT_POWERS, meta, data, true);
    case FileNames.WT_TMY:
      return compareFileWithModel(WT_TMY, meta, data, true);
    case FileNames.PV_TMY:
      return compareFileWithModel(PV_TMY, meta, data, true);
    case FileNames.ELECTROLYSIS_DEMAND:
      return compareFileWithModel(ELECTROLYSIS_DEMAND, meta, data, true);
    case FileNames.ECS_DEMAND:
      return compareFileWithModel(ECS_DEMAND, meta, data, true);
    case FileNames.DISTRIBKEYS:
      const headers = DISTRIBKEYS.getHeaders(counterNumber);
      return compareFileWithModel(
        { ...DISTRIBKEYS, headers },
        meta,
        data,
        true,
        counterNumber,
      );
    default:
      break;
  }
};

export async function FileChecker(file, name, header, counterNumber) {
  return new Promise((resolve) =>
    Papa.parse(file, {
      download: true,
      header,
      worker: true,
      complete: async (results) => {
        resolve(loadModel(name, results, counterNumber));
      },
    }),
  );
}
