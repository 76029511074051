import React, { useContext } from "react";
import LocaleContext from "../context/LocaleContext";
import styled from "styled-components";
import { CustomHoverPopUp } from "./CustomHoverPopUp";
import Auth from "../services/Auth";

const SwitchComponent = styled.label`
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
  margin: auto;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: ${(props) =>
        props.pageSite
          ? props.theme.toggleColor || "rgba(201, 201, 201, 0.5)"
          : props.theme.main || "#9dcf56"};
    }

    &:focus + .slider {
      box-shadow: ${(props) =>
        props.pageSite
          ? props.theme.toggleColor || "rgba(201, 201, 201, 0.5)"
          : props.theme.main || "#9dcf56"};
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: ${(props) =>
      props.isToogle ? "#ccc" : props.theme.green || "lightgrey"};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    ${(props) =>
      props.isDisabled &&
      `
		&:hover{
			cursor : not-allowed
		}
	`}
  }

  .round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
`;

const StyledTextContainer = styled.div`
  white-space: break-spaces;
  font-size: ${(props) => props.fontSize};
`;

export const SwitchButton = ({
  style = {},
  text,
  textRight = "",
  isChecked,
  name,
  onChange,
  inputStyle = {},
  isToogle = true,
  disabled = false,
  popUpContent = "",
  fontSize = "1rem",
  pageSite = false,
}) => {
  // const { THEME } = useContext(LocaleContext);
  return (
    <div
      className="switch"
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginLeft: "3em",
        ...style,
      }}
    >
      <CustomHoverPopUp
        title={text}
        content={popUpContent}
        disabled={popUpContent === "" || text === ""}
        noUnderline={popUpContent === "" || text === ""}
        noBold={popUpContent === "" || text === ""}
      >
        <StyledTextContainer fontSize={fontSize}>{text}</StyledTextContainer>
      </CustomHoverPopUp>
      <SwitchComponent
        // theme={userLicense !== "C&i" && THEME}
        isToogle={isToogle}
        isDisabled={disabled}
        style={{
          ...inputStyle,
        }}
        pageSite={pageSite}
      >
        <input
          type="checkbox"
          checked={isChecked}
          className="optimize-checkbox"
          name={name}
          onChange={onChange}
          disabled={disabled}
        ></input>
        <span className="slider round"></span>
      </SwitchComponent>
      {textRight}
    </div>
  );
};
