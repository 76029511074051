import React from "react";
import ReactTooltip from "react-tooltip";

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { content, mode, img, from } = this.props;
    return (
      <div className="tooltip">
        {from !== "loadCurve" && (
          <i
            data-tip="info"
            data-for={from}
            data-event={`click ${mode}`}
            className="fa fa-question-circle"
          ></i>
        )}
        <ReactTooltip
          place="top"
          type="info"
          effect="solid"
          globalEventOff="click"
          clickable
          multiline
          id={from}
          delayHide={0}
        >
          <span style={{ whiteSpace: "pre-line" }}>{content}</span>
          <br></br>
          <br></br>
          {from && <img src={img} alt="popup" />}
        </ReactTooltip>
      </div>
    );
  }
}
