import React, { useState } from "react";
import { URL_CUSTOMER } from "../../constants/Auth";
import Auth from "../../services/Auth";

const SendMailToCustomer = ({ token }) => {
  const [result, setResult] = useState("");
  const [color, setColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(Auth.getUserId());
  const [forfeit, setForfeit] = useState("Licence DEMO");
  const [numberOfSites, setNumberOfSites] = useState(1);

  const send = () => {
    setIsLoading(true);
    fetch(URL_CUSTOMER, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ userId, forfeit, numberOfSites }),
    })
      .then((res) => {
        setColor(res.ok ? "green" : "red");
        res
          .text()
          .then((txt) => {
            try {
              setResult(JSON.stringify(JSON.parse(txt), null, "\t"));
              setIsLoading(false);
            } catch {
              setResult(txt);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setColor("red");
            setResult(JSON.stringify(err));
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setColor("red");
        setResult(JSON.stringify(err));
        setIsLoading(false);
      });
  };
  return (
    <div className="cloud-functions-tester-container">
      {isLoading && <div class="cloud-functions-tester-loader"></div>}
      {!isLoading && (
        <>
          <label htmlFor="userId">userId:</label>
          <input
            id="userId"
            type="text"
            value={userId}
            onChange={({ target }) => setUserId(target.value)}
          />
          <label htmlFor="forfeit">forfeit:</label>
          <input
            id="forfeit"
            type="text"
            value={forfeit}
            onChange={({ target }) => setForfeit(target.value)}
          />
          <label htmlFor="numberOfSites">numberOfSites:</label>
          <input
            id="numberOfSites"
            type="number"
            value={numberOfSites}
            onChange={({ target }) => setNumberOfSites(target.value)}
          />
          <hr />
          <label htmlFor="token">Response:</label>
          <textarea
            cols="30"
            rows="10"
            id="textarea"
            type="text"
            style={{ color }}
            value={result}
          />

          <button id="send" onClick={send}>
            SEND
          </button>
        </>
      )}
    </div>
  );
};

export default SendMailToCustomer;
