import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { Carousel } from "react-responsive-carousel";
import { Step1, EndOfLanding } from "../../components/Landing";
import styled from "styled-components";
import { Arrow, ArrowIcon } from "../../assets/style/commonStyledComponent";
import Auth from "../../services/Auth";
import to from "await-to-js";
import { Step2 } from "../../components/Landing/Step2";
import Developpers from "../../services/Developpers";
import User from "../../services/User";
import CustomLoader from "../../components/CustomLoader";
const LandingPage = styled.div`
  height: 100vh;
`;

const Background = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
`;

const Half = styled.div`
  height: 100%;
  width: 50%;
  z-index: 1;
  &.left {
    position: absolute;
    left: 0;
    background: url(${(props) => props.landingPV});
    background-position: left;
    background-size: cover;
    z-index: 0;
  }
  &.right {
    position: absolute;
    right: 0;
    background-color: white;
    z-index: 0;
  }
`;

// const CarouselStyle = styled.div`
//   height: 100%;
//   z-index: 999;
// `;

const Landing = () => {
  const [step, setStep] = useState(0);
  const [redirect, triggerRedirect] = useState(false);
  const [landingPV, setLandingPV] = useState(null);
  // const { analytics, PageNames } = useContext(LocaleContext);

  useEffect(() => {
    const findURL = async () => {
      const dev = await Developpers.getByDevelopper();

      const userDev = await User.get(dev.account);

      const url = await User.getBackgroudImage(userDev.uid);
      return url;
    };
    findURL()
      .then((url) => {
        setLandingPV(url);
      })
      .catch((error) => {
        console.error("Error finding url:", error);
      });
  }, []);

  function renderArrowPrev(onClickHandler, hasPrev, label) {
    if (hasPrev)
      return (
        <Arrow
          className="left"
          onClick={() => {
            setStep(step - 1);
          }}
          title={label}
        >
          <ArrowIcon
            className="fa fa-chevron-left left"
            aria-hidden="true"
            style={{ paddingRight: 6, paddingTop: 3 }}
          ></ArrowIcon>
        </Arrow>
      );
  }

  function renderArrowNext(onClickHandler, hasNext, label) {
    if (hasNext) {
      switch (step) {
        case 0:
          return (
            <Arrow
              className="right start"
              onClick={() => {
                setStep(step + 1);
              }}
              // style={{ ...arrows.step0 }}
            >
              C'est parti{" "}
            </Arrow>
          );

        default:
          return (
            <Arrow
              className="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              <ArrowIcon
                className="fa fa-chevron-right right"
                aria-hidden="true"
                style={{ paddingRight: 6, paddingTop: 3 }}
              ></ArrowIcon>
            </Arrow>
          );
      }
    }
  }

  const config = {
    showStatus: false,
    showThumbs: false,
    showIndicators: false,
    renderArrowPrev: renderArrowPrev,
    renderArrowNext: renderArrowNext,
  };

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       console.log("CONNECTED", user.uid);
  //       // triggerRedirect(true);
  //       // ...
  //     } else {
  //       // User is signed out
  //       // ...
  //     }
  //   });
  // }, [redirect]);
  if (landingPV === null) {
    return (
      <CustomLoader
        color="black"
        fullSize={true}
        text={
          <span style={{ fontSize: "30px", lineHeight: 1.5 }}>
            Chargement du simulateur
          </span>
        }
      ></CustomLoader>
    );
  }

  if (!redirect) {
    return (
      <LandingPage id="landing-page">
        <Background>
          {/* <img className="logo" src={LOGO} /> */}
          <Half className="left" landingPV={landingPV} />
          <Half className="right" />
        </Background>
        {/* <CarouselStyle> */}
        <Carousel selectedItem={step} {...config}>
          <Step1 />
          <Step2 />
          {/* <Step3 />
          <Step4 /> */}
          <EndOfLanding triggerRedirect={triggerRedirect} />
        </Carousel>
        {/* </CarouselStyle> */}
      </LandingPage>
    );
  } else {
    return <Redirect to="/home" />;
  }
};

export default Landing;
