import React from "react";
import styled from "styled-components";
import { CustomHoverPopUp } from "./CustomHoverPopUp";
import { CustomUpgradeToChatMessage } from "./CustomCTAMessage";
import { POP_UPS } from "../constants/Notifications";
import { toogleNotification } from "../store/notifications/NotificationsStore";
import { MAILTYPES } from "../constants/MailTypes";
import { setQueryParams } from "../helpers/Router";
const ContactContainer = styled.div`
  width: 5%;
  height: 100px;
  position: fixed;
  bottom: 0;
`;
const StyledButton = styled.div`
  border: none;
  position: absolute;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px, rgb(0 0 0 / 20%) 0px 2px 12px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: rgb(0, 164, 189);
  left: 10%;
  bottom: 25%;
  z-index: 1;
  display: flex;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .fa-envelope {
    color: white;
    padding: 30%;
    font-size: 2.3rem;
  }
`;

const sendMail = () => {
  setQueryParams(MAILTYPES.SUPPORT);
  toogleNotification(POP_UPS.SEND_MAIL);
};

export const ContactButton = () => {
  return (
    <ContactContainer>
      <StyledButton onClick={sendMail}>
        <CustomHoverPopUp
          position="top right"
          content={<CustomUpgradeToChatMessage />}
        >
          <i className="fa fa-envelope" aria-hidden="true"></i>
        </CustomHoverPopUp>
      </StyledButton>
    </ContactContainer>
  );
};
