import React, { useContext } from "react";
import LocaleContext from "../../../../context/LocaleContext";
import { Form } from "react-bulma-components/full";
import dayjs from "dayjs";
import SiteConstants from "../../../../constants/Site";
export const ProfileSlots = ({
  changeProfileMonthlyConsumption,
  consumptionIndexes,
  slots,
  monthlyConsumptionIndex,
  profile,
}) => {
  const { i18n, Utils } = useContext(LocaleContext);
  const { capitalize } = Utils;

  const getIndexesByProfile = () => {
    switch (profile) {
      case SiteConstants.profiles.ENT_BT_SUP:
        return SiteConstants.ENT_BT_SUP_WINTER_INDEXES;
      case SiteConstants.profiles.ENT_HTA:
        return SiteConstants.ENT_HTA_WINTER_INDEXES;
      default:
        break;
    }
  };
  const shouldCellBeDisabled = (month, index) => {
    if (
      ![
        SiteConstants.profiles.ENT_BT_SUP,
        SiteConstants.profiles.ENT_HTA,
      ].includes(profile)
    )
      return false;
    else {
      if (
        profile === SiteConstants.profiles.ENT_HTA &&
        !SiteConstants.PTE_MONTH.includes(+month) &&
        SiteConstants.ENT_HTA_PTE_INDEXES.includes(index)
      ) {
        return true;
      }
      if (
        SiteConstants.WINTER_MONTH.includes(+month) &&
        !getIndexesByProfile().includes(index) &&
        !SiteConstants.ENT_HTA_PTE_INDEXES.includes(index)
      ) {
        return true;
      }
      if (
        !SiteConstants.WINTER_MONTH.includes(+month) &&
        getIndexesByProfile().includes(index)
      ) {
        return true;
      }
    }
    return false;
  };

  if (consumptionIndexes)
    return (
      <>
        <thead>
          <tr>
            {monthlyConsumptionIndex && <th>{capitalize(i18n.t("month"))}</th>}
            {slots.map((slot) => (
              <th>{slot}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {monthlyConsumptionIndex &&
            Object.keys(consumptionIndexes).length > 0 &&
            Object.entries(consumptionIndexes).map(
              ([month, consumption], index) => (
                <tr key={month}>
                  <td>
                    {capitalize(
                      dayjs()
                        .set("month", month - 1)
                        .format("MMMM"),
                    )}
                  </td>
                  {slots.map((slot, index) => (
                    <td key={slot}>
                      <Form.Field>
                        <Form.Control>
                          <Form.Input
                            value={
                              shouldCellBeDisabled(month, index)
                                ? null
                                : consumption[index]
                            }
                            type="number"
                            onChange={(e) =>
                              changeProfileMonthlyConsumption(
                                month,
                                e.target.value,
                                index,
                              )
                            }
                            min="0"
                            disabled={shouldCellBeDisabled(month, index)}
                          />
                        </Form.Control>
                      </Form.Field>
                    </td>
                  ))}
                </tr>
              ),
            )}
          {!monthlyConsumptionIndex &&
            slots.map((slot, index) => (
              <td key={slot}>
                <Form.Field>
                  <Form.Control>
                    <Form.Input
                      value={consumptionIndexes[1][index]}
                      type="number"
                      onChange={(e) =>
                        changeProfileMonthlyConsumption(
                          1,
                          e.target.value,
                          index,
                        )
                      }
                      min="0"
                    />
                  </Form.Control>
                </Form.Field>
              </td>
            ))}
        </tbody>
      </>
    );
  else return "loading";
};
