import React, { useContext } from "react";
import "./FolderSelect.scss";

import LocaleContext from "../../context/LocaleContext";
export const FolderSelect = ({
  currentFolder,
  handleFolderSelect,
  folders = [],
  width = "100%",
  marginLeft = 0,
  marginTop = 0,
}) => {
  const { Translation, i18n } = useContext(LocaleContext);
  return (
    <div
      className="folder-select"
      style={{
        marginLeft: marginLeft,
        width: "80%",
        borderColor: "none",
        backgroundColor: "white",
        marginTop: "1%",
      }}
    >
      <label htmlFor="folder">
        <Translation>{(t) => t("folder")}</Translation>
      </label>
      <select
        id="folder"
        value={currentFolder?.id}
        onChange={handleFolderSelect}
        style={{
          width: "100%",
          marginTop: marginTop,
          padding: "0.3rem",
          borderRadius: "5px",
          borderColor: "none",
          backgroundColor: "white",
        }}
      >
        <option value={"ALL"}>Tous les dossiers</option>
        {folders.map((folder) => (
          <option key={folder.id} value={folder.id}>
            {folder.displayName}
          </option>
        ))}
      </select>
    </div>
  );
};
