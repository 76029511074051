import { db } from "./../fire";
import to from "await-to-js";
import moment from "moment";
import AuthService from "./Auth";
import DatabaseConstants from "./../constants/Database";
import SiteService from "./Site";

class CollectiveSiteService {
  add = (data) => {
    const refSite = db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc();
    data.id = refSite.id;
    const payload = {
      ...data,
      _createdAt: moment(Date.now()).format(),
      user: data.user ?? AuthService.getUserId(),
      sites: [],
      name: data.name,
      type: data.type,
    };
    if (data.score) delete data.score;
    return refSite.set(payload);
  };

  getAll = () => {
    return (
      db
        .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
        .get()
        .then((collectiveSites) => {
          return collectiveSites.docs.map((doc) => {
            return doc.data();
          });
        })
        // remove null collectiveSites
        .then((collectiveSites) =>
          collectiveSites.filter((collectiveSite) => collectiveSite),
        )
        // fetch sub-sites for each collective-site
        .then((collectiveSites) =>
          Promise.all(
            collectiveSites.map((collectiveSite) =>
              Promise.all(
                collectiveSite.sites.map((siteId) =>
                  SiteService.getById(siteId),
                ),
              )
                // remove null sites
                .then((sites) => sites.filter((site) => site))
                .then((sites) => ({
                  ...collectiveSite,
                  sites,
                })),
            ),
          ),
        )
        .catch((err) =>
          console.error(
            `Could not get all collective-sites. Error : ${err.message}`,
          ),
        )
    );
  };

  remove = async (siteId) => {
    const batch = db.batch();

    const collectiveSiteRef = db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId);

    const collectiveSite = await collectiveSiteRef
      .get()
      .then((doc) => doc.data());

    const subSitesRefs = collectiveSite?.sites.map((siteId) =>
      db.collection(DatabaseConstants.COLLECTION_SITES).doc(siteId),
    );

    subSitesRefs?.forEach((ref) => batch.delete(ref));

    batch.delete(collectiveSiteRef);
    return batch.commit();
  };

  deleteAllCollectiveSites = (sites) => {
    sites.forEach((site) => this.remove(site.id));
  };

  update = (siteId, siteData) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId);
    siteData._lastModified = moment(Date.now()).format();
    if (siteData.score) delete siteData.score;
    return siteRef.update(siteData);
  };

  updateKey = (siteId, key, value) => {
    const siteRef = db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId);

    return siteRef.set({ [key]: value }, { merge: true });
  };

  getUserSites = async (userId) => {
    return (
      db
        .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
        .where("user", "==", userId)
        .get()
        .then((collectiveSites) => {
          return collectiveSites.docs.map((doc) => {
            return doc.data();
          });
        })
        // remove null collective-sites
        .then((collectiveSites) =>
          collectiveSites.filter((collectiveSite) => collectiveSite),
        )
        // fetch sub-sites for each collective-site
        .then((collectiveSites) =>
          Promise.all(
            collectiveSites.map((collectiveSite) =>
              Promise.all(
                collectiveSite.sites.map((siteId) =>
                  SiteService.getById(siteId),
                ),
              )
                // remove null sites
                .then((sites) => sites.filter((site) => site))
                .then((sites) => ({
                  ...collectiveSite,
                  sites,
                })),
            ),
          ),
        )
        .catch((err) =>
          console.error(
            `Could not get all user collective-sites. Error : ${err.message}`,
          ),
        )
    );
  };

  getById = async (collectiveSiteId) => {
    return db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(collectiveSiteId)
      .get()
      .then((doc) => doc.data())
      .then((collectiveSite) =>
        Promise.all(
          collectiveSite?.sites.map((siteId) => SiteService.getById(siteId)),
        )
          // remove null sites
          .then((sites) => sites?.filter((site) => site) || [])
          .then((sites) => ({
            ...collectiveSite,
            sites,
          })),
      )
      .catch((err) =>
        console.error(
          `Could not get the collective-site. Error : ${err.message}`,
        ),
      );
  };

  transferToUser = async (siteId, user) => {
    let err = null;

    const docRef = db
      .collection(DatabaseConstants.COLLECTION_COLLECTIVE_SITES)
      .doc(siteId);

    [err] = await to(docRef.set({ user }, { merge: true }));
    if (err) {
      console.log("err", err);
      return Promise.reject(err);
    }

    return Promise.resolve();
  };
}

export default new CollectiveSiteService();
