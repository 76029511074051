export default {
  fr: {
    Soutirage: "Soutirage",
    "Soutirage total des sites": "Soutirage total des sites",
    "Puissances souscrites": "Puissances souscrites",
    "Consommation du site": "Consommation du site",
    "Consommation totale des sites": "Consommation totale des sites",
    "Grp. Elec.": "Grp. Elec.",
    "Pile à combustible": "Pile à combustible",
    "PV produit": "Photovoltaïque produit",
    Batterie: "Batterie",
    "Stockage Virtuel": "Stockage virtuel",
    Eolien: "Eolien",
    Electrolyse: "Electrolyse",
    "PV vendu": "Photovoltaïque vendu",
    "PV stockage": "Photovoltaïque stocké",
    // "PV Energy loss": "Photovoltaïque stocké non utilisé",
    "Potentiel PV": "Potentiel Photovoltaïque",
    "Puissance de raccordement": "Puissance de raccordement",
    "SOC Batterie": "SOC Batterie",
    "SOC Stockage Virtuel": "SOC Stockage virtuel",
    "Eolien vendu": "Eolien vendu",
    "Vente H2": "Vente H2",
    "Stockage H2": "Stockage H2",
    // "ECS Save": "Besoin ECS",
    "ECS Capacity": "Stockage ECS",
    "PV2 produit": "Photovoltaïque 2 produit",
    "Potentiel PV2": "Potentiel Photovoltaïque 2",
    "PV2 vendu": "Photovoltaïque 2 vendu",
    "PV2 stockage": "Photovoltaïque 2 stocké",
    //SUPPLIER
    "Eolien Spot": "Eolien Spot",
    "Eolien PPA": "Eolien PPA",
    "Complement Eolien PPA": "Complément PPA",
    "Profil Eolien PPA": "Profil PPA",
    "PV Spot": "Photovoltaïque Spot",
    "PV PPA": "Photovoltaïque PPA",
    "Complement PV PPA": "Complément PPA",
    "Profil PV PPA": "Profil PPA",
    "PV2 Spot": "Photovoltaïque 2 Spot",
    "PV2 PPA": "Photovoltaïque 2 PPA",
    "Complement PV2 PPA": "Complément PPA",
    "Profil PV2 PPA": "Profil PPA",
    "Batterie Spot": "Batterie Spot",
    "Batterie PPA PV": "Batterie PPA",
    "Batterie PPA PV2": "Batterie PPA",
    "Batterie PPA Eolien": "Batterie PPA",
    "Pile a combustible Spot": "Pile à combustible Spot",
    "Pile a combustible PPA PV": "Pile à combustible PPA",

    "Pile a combustible PPA Eolien": "Pile à combustible PPA",
    "Grp. Elec. PPA PV": "Groupe électrogène PPA",
    "Grp. Elec. PPA PV2": "Groupe électrogène PPA",
    "Grp. Elec. PPA Eolien": "Groupe électrogène PPA",
    FCR: "FCR",
    FCR_SPOT: "Prix FCR & Prix SPOT",
    "Prix FCR": "Prix FCR",
    "Prix SPOT": "Prix SPOT",
    "Anciennes puissances souscrites": "Anciennes puissances souscrites",
    stations: "Consommation bornes {{index}}",
    "Consommation initiale du site": "Consommation initiale du site",
    "Energie stockée dans la batterie": "Energie stockée dans la batterie",
  },
  en: {
    Soutirage: "Grid supply",
    "Soutirage total des sites": "Total grid supply",
    "Puissances souscrites": "Subscribed powers",
    "Consommation du site": "Electrical load",
    "Consommation totale des sites": "Total electrical load",
    "Grp. Elec.": "Fuel generator",
    "Pile à combustible": "Fuel cell",
    "PV produit": "Photovoltaic production",
    Batterie: "Battery",
    "Stockage Virtuel": "Virtual storage",
    Eolien: "Wind production",
    Electrolyse: "Electrolysis",
    "PV vendu": "Photovoltaic sales",
    "PV stocké": "Photovoltaic stored",
    "Potentiel PV": "Photovoltaic potential",
    "Puissance de raccordement": "Connecting power",
    "SOC Batterie": "Battery SOC",
    "SOC Stockage Virtuel": "Virtual storage SOC",
    "Eolien vendu": "Wind sales",
    "Vente H2": "H2 sales",
    "Stockage H2": "H2 storage SOC",
    // "ECS Save": "ECS Save",
    "ECS Capacity": "ECS Capacity",
    "PV2 produit": "Photovoltaic 2 production",
    "Potentiel PV2": "Photovoltaic 2 potential",
    "PV2 vendu": "Photovoltaic 2 sales",
    //SUPPLIER
    "Eolien Spot": "Wind on Spot",
    "Eolien PPA": "Wind on PPA",
    "Complement Eolien PPA": "PPA completion",
    "Profil Eolien PPA": "PPA profile",
    "PV Spot": "Photovoltaic on Spot",
    "PV PPA": "Photovoltaic on PPA",
    "Complement PV PPA": "PPA completion",
    "Profil PV PPA": "PPA profile",
    "PV2 Spot": "Photovoltaic 2 on Spot",
    "PV2 PPA": "Photovoltaic 2 on PPA",
    "Complement PV2 PPA": "PPA completion",
    "Profil PV2 PPA": "PPA profile",
    "Batterie Spot": "Battery on Spot",
    "Batterie PPA PV": "Battery on PPA",
    "Batterie PPA PV2": "Battery on PPA",
    "Batterie PPA Eolien": "Battery on PPA",
    "Pile a combustible Spot": "Fuel cell on Spot",
    "Pile a combustible PPA PV": "Fuel cell on PPA",
    "Pile a combustible PPA Eolien": "Fuel cell on PPA",
    "Grp. Elec. PPA PV": "Fuel generator on PPA",
    "Grp. Elec. PPA PV2": "Fuel generator on PPA",
    "Grp. Elec. PPA Eolien": "Fuel generator on PPA",
    FCR: "FCR",
    FCR_SPOT: "FCR & SPOT prices",
    "Prix FCR": "FCR prices",
    "Prix SPOT": "SPOT prices",
    "Anciennes puissances souscrites": "Past subscribed powers",
    stations: "Consumption station {{index}}",
    "Consommation initiale du site": "Initial site consumption",
    "Energie stockée dans la batterie": "Energy stored in battery",
  },
};
