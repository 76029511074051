const DATE_FORMATS = {
  oneColumn: [
    "DD/MM/YYYY HH:mm",
    "DD/MM/YYYY HH:mm:ss",
    "DD-MM-YYYY HH:mm",
    "DD-MM-YYYY HH:mm:ss",
  ],
  twoColumns: ["DD/MM/YYYY", "DD-MM-YYYY"],
};

const HOUR_FORMATS = ["HH:mm", "HH:mm:ss"];

const ConsoData = {
  name: "ConsoData",
  headers: ["Date de la mesure", "Heure de la mesure", "Valeur"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "HH:mm",
    DELIMITER: ";",
  },
};
const PV_POWERS = {
  name: "PvPowers",
  headers: ["Date", "Heure", "Valeur"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "HH:mm",
    DELIMITER: ";",
  },
};
const WT_POWERS = {
  name: "WtPowers",
  headers: ["Date", "Heure", "Valeur"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "HH:mm",
    DELIMITER: ";",
  },
};
const WT_TMY = {
  name: "WtTMY",
  headers: ["Date de la mesure", "Heure de la mesure", "WS10m"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "H:mm",
    DELIMITER: ";",
  },
};
const PV_TMY = {
  name: "PvTMY",
  headers: [
    "Date de la mesure",
    "Heure de la mesure",
    "T2m",
    "G(h)",
    "Gb(n)",
    "Gd(h)",
    "WS10m",
  ],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "H:mm",
    DELIMITER: ";",
  },
};
const ELECTROLYSIS_DEMAND = {
  name: "ElectrolysisDemande",
  headers: ["Date", "Heure", "Consommation (kg)"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "H:mm",
    DELIMITER: ";",
  },
};

const ECS_DEMAND = {
  name: "ECSDemande",
  headers: ["Date", "Heure", "Consommation (m³)"],
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "H:mm",
    DELIMITER: ";",
  },
};

const DISTRIBKEYS = {
  name: "distribKeys",
  getHeaders: (counterNumber) => {
    const counterHeaders = Array.from(
      { length: counterNumber },
      (_, i) => `Cle de distribution - Compteur n°${i + 1}`,
    );

    return ["Date", "Heure", ...counterHeaders];
  },
  format: {
    DATE1: "DD/MM/YYYY",
    DATE2: "DD-MM-YYYY",
    HOURS1: "HH:mm",
    HOURS2: "H:mm",
    DELIMITER: ";",
  },
};

export {
  ConsoData,
  WT_TMY,
  PV_TMY,
  PV_POWERS,
  WT_POWERS,
  ELECTROLYSIS_DEMAND,
  ECS_DEMAND,
  DISTRIBKEYS,
  DATE_FORMATS,
  HOUR_FORMATS,
};
