import styled from "styled-components";

export const SiteMetadata = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: ${(props) => props.isMargin || "10px 0 5px 0"};
`;

export const SiteMetadataName = styled.div`
  color: ${(props) => props.colorName || "#000000"};
  font-size: ${(props) => props.fontSize || "1.25rem"};
  font-weight: ${(props) => props.fontWeight || "bolder"};
`;

export const SiteMetadataAdress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 1rem;
  margin: ${(props) => props.addressMargin || "0"};
`;

export const Data = styled.div`
  font-size: 1rem;
  margin-top: ${(props) => props.marginTop || "0"};
`;

export const DownloadConsoData = styled.a`
  display: block;
  font-size: ${(props) => props.smallSize || "1rem"};
  margin-top: ${(props) => props.marginTop || "15px"};
  float: right;
  transition: color 0.2s ease-in-out;

  i {
    font-size: 1rem !important;
    margin-left: ${(props) => props.marginLeft || "10px"};
    cursor: pointer;

    &:hover {
      transform: none;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding-top: ${(props) => props.paddingTop || "5px"};
`;

export const SiteActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
`;

export const ActionsButton = styled.button`
  background-color: ${(props) => props.bgBtn || "white"};
  border: 1px solid #003b58;
  border-radius: 4px;
  color: ${(props) => props.colorBtn || "#003b58"};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
  font-family: "Roboto";
  width: 145px;
  height: 40px;
  padding: 10px 18px;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.hoverColorBtn || "white"};
    background-color: ${(props) => props.hoverBgBtn || "#003b58"};
    border-color: ${(props) => props.hoverBorderColor || "#003b58"};
  }
  &:disabled {
    background-color: white !important;
    color: black;
    opacity: 0.5;
    cursor: not-allowed;
  }

  i {
    &:hover {
      transform: none;
      color: inherit;
    }
  }
`;
