const fr_cta = {
  cta: {
    toSubscribeWriteUs: "Pour y souscrire, contactez-nous à",
    trialIsOver: "Votre essai est terminé",
    subscribeToKeepProjects: "Souscrire pour ne pas perdre vos projets",
    deleteProjectOr: "Supprimez un projet existant \n ou \n",
    upgradePlan: "Passer à la version supérieure",
    upgradePlanForMoreProjects:
      "Commandez un paquet de 100 projets supplémentaires.",
    upgradePlanForChat:
      "Passer à la version supérieure pour accéder au chat en direct",
    upgradeForChat: "Cliquez pour nous envoyer un mail \n ou \n",
    endOfACC: "Vous n’avez pas accès au module autoconsommation collective.",
    BTINF:
      "Votre licence ne vous permet pas d'analyser un projet ayant un domaine de tension supérieur à 36 kVA \n",
    toAccessThisFeature: "Pour accéder à cette fonctionnalité",
    upgradeLimit: "Passez au forfait supérieur",
    toLogin: "Déconnectez vous sur les autres navigateurs \n ou \n",
    maxUserLimit:
      "Le maximum d'utilisateurs simultanés permis par votre licence a été atteint.",
  },
};
const en_cta = {
  cta: {
    toSubscribeWriteUs: "To apply, contact us at",
    trialIsOver: "Your trial period is over",
    subscribeToKeepProjects: "Subscribe to keep access to your projects",
    deleteProjectOr: "Delete a project \n or \n",
    upgradePlan: "Upgrade your plan",
    upgradePlanForChat: "Upgrade your plan to access live chat",
    upgradeForChat: "Click here to send us an e-mail \n or \n",
    upgradePlanForMoreProjects: "Order a package of 100 additional projects.",
    endOfACC:
      "Your access to the Collective Consumption module is over (free trial ended on 31/07/2023).",
    BTINF:
      "Your licence doesn't allow for analysis of projects with a voltage range greater than 36 kVA \n",
    toAccessThisFeature: "To access this feature",
    upgradeLimit: "Upgrade your plan",
    maxUserLimit:
      "The maximum number of simultaneous users allowed by your license has been reached.",
    toLogin: "Sign out on the other browsers \n or \n",
  },
};

export { fr_cta, en_cta };
