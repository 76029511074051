import { COLLECTION_INVITATIONS } from "../constants/Database";
import to from "await-to-js";
import { db } from "./../fire";
import dayjs from "dayjs";

export const getById = async (id) => {
  const [err, data] = await to(db.collection(COLLECTION_INVITATIONS).doc(id).get());
  if (err) return null;
  return data.data();
};

export const deleteById = async (id) => {
  const [err, data] = await to(db.collection(COLLECTION_INVITATIONS).doc(id).delete());
  if (err) return null;
  return true;
};

export const expired = (date) => {
  const now = dayjs();
  const invitationDate = dayjs.unix(date.seconds).add(2, 'day')
  return invitationDate.isBefore(now);
}

export const checkExistingInvitation = async (email) => {
  const [err, snapshot] = await to(
    db.collection(COLLECTION_INVITATIONS)
      .where("email", "==", email)
      .get()
  );
  
  if (err) return false;
  return !snapshot.empty;
};