import React from "react";
import { Notification, Button } from "react-bulma-components/full";
import Linkify from "react-linkify";
import styled from "styled-components";

const StyledTextWithLink = styled.div``;

export default function InfoBar(props) {
  return (
    <div className="infoBar-container">
      <div className="infoBar-content">
        <Notification>
          <StyledTextWithLink>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {props.content}
            </Linkify>
          </StyledTextWithLink>
          <Button
            className="info-button"
            color="info"
            onClick={props.handleCloseInfoBar}
          >
            Compris
          </Button>
        </Notification>
      </div>
    </div>
  );
}
