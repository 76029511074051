import React from "react";
import styled from "styled-components";
import { Button } from "react-bulma-components/full";
import { blue } from "../assets/style/SharedColors";
const StyledButtonWithIcon = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.main || "#003b58"};
    display: flex;
    width: ${(props) => props.width};
    color: ${(props) => props.textColor};
    flex-direction: ${(props) => (props.iconRight ? "row-reverse" : "row")};
    text-align: center;
    justify-content: space-around;
    flex: ${(props) => props.flex || `1;`};
    margin: ${(props) => props.margin};
    margin-right: ${(props) => props.marginRight};
    padding: ${(props) => props.padding || "0% 0%"}
      ${(props) => props.round && "border-radius: 10px"}
      ${(props) => props.largeFont && "font-size:1.5em!important;"}
      ${(props) => props.centered && `margin: 10px auto;`} i {
      padding: 0 1em;
    }
    transition: transform 0.2s ease-in-out;
    &:hover {
      ${(props) => props.scaleOnHover && "transform:scale(1.1)"}
    }
  }
  &.is-loading {
    &.is-info {
      &:after {
        color: ${blue};
        border-color: transparent transparent ${blue} ${blue} !important;
      }
    }
  }
`;

export const CustomButtonWithIcon = ({
  children,
  onClick = () => {},
  type = "sucess",
  iconRight = false,
  icon = "",
  disabled = false,
  color = "white",
  round = false,
  scaleOnHover = false,
  width = "100%",
  centered = false,
  largeFont = false,
  margin = "0",
  id = "",
  loading = false,
}) => (
  <StyledButtonWithIcon
    id={id}
    color={type}
    iconRight={iconRight}
    onClick={onClick}
    disabled={disabled}
    textColor={color}
    round={round}
    scaleOnHover={scaleOnHover}
    width={width}
    centered={centered}
    largeFont={largeFont}
    margin={margin}
    loading={loading}
  >
    {icon && <i className={`fa fa-${icon}`} />}
    {children}
  </StyledButtonWithIcon>
);
