import React, { useState, useEffect } from "react";
import to from "await-to-js";
import Terms from "./Terms";
import "./Signup.scss";
import Auth from "../services/Auth";
import keys from "../keys";
import ReCAPTCHA from "react-google-recaptcha";
import PasswordVisibilityToggler from "./shared/PasswordVisibilityToggler";
import {
  Columns,
  Form,
  Button,
  Notification,
  Icon,
} from "react-bulma-components/full";
import { useParams, Link } from "react-router-dom";
import { getById, deleteById, expired } from "../services/Invitation";
import User from "../services/User";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";
import styled from "styled-components";
import { CustomButton } from "./CustomButton";

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  min-height: 60vh;
  display: flex;
  align-items: center;
`;

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ErrorBox = styled.div`
  padding: 1.25rem;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1);
  text-align: center;
`;

const ErrorTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
`;

const ErrorIcon = styled(BiErrorCircle)`
  color: #f14668;
`;

const ErrorMessage = styled.span`
  margin-left: 1rem;
`;

const HomeButton = styled(Link)`
  background-color: #003b58;
  color: white;
  padding: 0.5em 1em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #044981;
    color: white;
  }
`;

const Invitation = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [invitation, setInvitation] = useState(null);
  const [principalUser, setPrincipalUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(Auth.isAuthLocally());

  const handleLogout = () => {
    Auth.logoutWithoutRedirect();
    window.location.reload();
  };

  const renderAuthenticatedMessage = () => {
    return (
      <Container>
        <CenteredWrapper>
          <div>
            <ErrorBox>
              <ErrorTitle>
                <ErrorIcon size={32} />
                <ErrorMessage>Vous êtes déjà connecté</ErrorMessage>
              </ErrorTitle>
              <div className="subtitle is-5 has-text-centered">
                Pour accéder à cette page d'invitation, vous devez d'abord vous
                déconnecter.
              </div>
              <div
                className="has-text-centered"
                style={{ marginTop: "1.5rem" }}
              >
                <CustomButton onClick={handleLogout}>
                  Se déconnecter
                </CustomButton>
              </div>
            </ErrorBox>
          </div>
        </CenteredWrapper>
      </Container>
    );
  };

  if (isAuthenticated) {
    return renderAuthenticatedMessage();
  }

  const initialState = {
    step: 1,
    password: "",
    repassword: "",
    firstname: "",
    lastname: "",
    maxUsers: 1,
    termsAccepted: false,
    isEmailUsed: false,
    isSignupLoad: false,
    signupError: null,
    signupSuccess: false,
    isAdmin: false,
    isValidPassword: false,
    emailVerified: false,
    showTerms: false,
    captcha: false,
    phone: null,
    incorrectPhone: false,
    passwordIsHidden: true,
    confirmPasswordIsHidden: true,
    isLenbthValid: false,
    isLowercaseValid: false,
    isUppercaseValid: false,
    isDigitValid: false,
    isSpecialCharValid: false,
    principal: false,
    principalUser: null,
  };

  const [state, setState] = useState(initialState);

  const handleChangeInput = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    setState((prev) => ({ ...prev, [target.name]: value }));
  };

  const handleSignup = async (e) => {
    if (e) e.preventDefault();
    setState((prev) => ({
      ...prev,
      isSignupLoad: true,
      signupError: null,
      email: principalUser.email,
      principalUser: principalUser.uid,
    }));
    const [err, uid] = await to(
      Auth.createSubAccountWithEmailAndPassword(state),
    );

    if (err) {
      const errorStates = {
        "auth/email-already-in-use": "Cette adresse mail est déjà utilisée.",
        "auth/operation-not-allowed":
          "Impossible de créer votre compte par le biais de cette méthode.",
        "auth/invalid-email": "L'email fourni n'est pas valide.",
        "auth/weak-password": "Votre mot de passe est trop faible.",
      };

      return setState((prev) => ({
        ...prev,
        isSignupLoad: false,
        signupError:
          errorStates[err.code] ||
          `Une erreur inconnue est survenue. (${err.message})`,
        step: 1,
      }));
    }

    const usersList = principalUser.usersList;
    const userIndex = usersList.findIndex((user) => user.email === state.email);

    if (userIndex !== -1) {
      const newUser = {
        uid,
        status: "active",
        email: state.email,
      };
      usersList[userIndex] = newUser;
    }

    try {
      console.log("STARTING UPDATE");
      await User.update(principalUser.uid, {
        usersList: usersList,
      });
      await deleteById(id);

      setState((prev) => ({
        ...prev,
        isSignupLoad: false,
        signupSuccess: true,
        uid,
      }));
    } catch (error) {
      console.error("🚀 ~ handleSignup ~ error:", error);
      setState((prev) => ({
        ...prev,
        isSignupLoad: false,
        signupError: "Une erreur est survenue lors de la mise à jour du compte",
      }));
    }

    return Promise.resolve(true);
  };

  const handleNextStep = async () => {
    const { captcha } = state;
    let err = null;
    if (!captcha) return;
    [err] = await to(handleSignup());
    if (!err) return setState((prev) => ({ ...prev, step: prev.step + 1 }));
    else return;
  };

  const isPasswordsSame = () => state.password === state.repassword;

  useEffect(() => {
    if (state.password) {
      const isLenbthValid = state.password.length >= 6;
      const isLowercaseValid = /[a-z]/.test(state.password);
      const isUppercaseValid = /[A-Z]/.test(state.password);
      const isDigitValid = /[0-9]/.test(state.password);
      const isSpecialCharValid =
        /.*[!@#=$%^&*()_+{}\[\]:;<>,.?\/\\~`|-].*/.test(state.password);

      setState((prev) => ({
        ...prev,
        isLenbthValid,
        isLowercaseValid,
        isUppercaseValid,
        isDigitValid,
        isSpecialCharValid,
        isValidPassword:
          isLowercaseValid &&
          isUppercaseValid &&
          isDigitValid &&
          isSpecialCharValid,
      }));
    }
  }, [state.password]);

  const handleCaptcha = (v) =>
    setState((prev) => ({ ...prev, captcha: v ? true : false }));

  const canGoToStepTwo = () => {
    const {
      password,
      repassword,
      firstname,
      lastname,
      termsAccepted,
      isEmailUsed,
      captcha,
      incorrectPhone,
    } = state;

    if (
      password.length >= 6 &&
      repassword.length !== 0 &&
      firstname.length !== 0 &&
      lastname.length !== 0 &&
      isPasswordsSame() &&
      termsAccepted &&
      !isEmailUsed &&
      captcha &&
      !incorrectPhone
    )
      return true;
    return false;
  };

  const handleChangePhone = ({ target: { value } }) => {
    setState((prev) => ({ ...prev, phone: value }));
  };

  const renderStepOne = () => {
    const {
      password,
      repassword,
      firstname,
      lastname,
      termsAccepted,
      showTerms,
      phone,
      isSignupLoad,
    } = state;

    return (
      <div className="signup-wrapper container is-centered">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="box">
              <div className="title is-4 has-text-centered">
                Créez votre compte
              </div>
              {renderErrorNotification()}
              <Columns>
                <Columns.Column size={12}>
                  <div className="title is-6">Coordonnées</div>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="firstname"
                        onChange={handleChangeInput}
                        value={firstname}
                        placeholder="Prénom*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="lastname"
                        onChange={handleChangeInput}
                        value={lastname}
                        placeholder="Nom*"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={6}>
                  <Form.Field>
                    <Form.Control>
                      <Form.Input
                        name="phone"
                        onChange={handleChangePhone}
                        value={phone}
                        placeholder="Téléphone"
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>

                <Columns.Column size={12}>
                  <div className="title is-6">Identifiants de connexion</div>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control iconLeft>
                      <Form.Input
                        name="password"
                        onChange={handleChangeInput}
                        value={password}
                        type={state.passwordIsHidden ? "password" : "text"}
                        placeholder="Mot de passe*"
                      />
                      <Icon align="left">
                        <i className="fa fa-lock" />
                      </Icon>
                      <PasswordVisibilityToggler
                        isHidden={state.passwordIsHidden}
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            passwordIsHidden: !prev.passwordIsHidden,
                          }))
                        }
                      />
                    </Form.Control>
                    {password.length > 0 && (
                      <Form.Help onSubmit={handleSignup}>
                        <div className="text-password">
                          Votre mot de passe doit comporter au moins :
                        </div>
                        <div className="list-password">
                          <ul>
                            <li>
                              {state.isLenbthValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              6 caractères
                            </li>
                            <li>
                              {state.isLowercaseValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Une lettre minuscule
                            </li>
                            <li>
                              {state.isUppercaseValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Une lettre majuscule
                            </li>
                            <li>
                              {state.isDigitValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Un chiffre
                            </li>

                            <li>
                              {state.isSpecialCharValid ? (
                                <i class="fa fa-check"></i>
                              ) : (
                                <i class="fa fa-close"></i>
                              )}
                              Un caractère spécial
                            </li>
                          </ul>
                        </div>
                      </Form.Help>
                    )}
                  </Form.Field>
                </Columns.Column>
                <Columns.Column size={12}>
                  <Form.Field>
                    <Form.Control iconLeft>
                      <Form.Input
                        name="repassword"
                        onChange={handleChangeInput}
                        value={repassword}
                        type={
                          state.confirmPasswordIsHidden ? "password" : "text"
                        }
                        placeholder="Répéter le mot de passe*"
                      />
                      <Icon align="left">
                        <i className="fa fa-lock" />
                      </Icon>
                      <PasswordVisibilityToggler
                        isHidden={state.confirmPasswordIsHidden}
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            confirmPasswordIsHidden:
                              !prev.confirmPasswordIsHidden,
                          }))
                        }
                      />
                    </Form.Control>
                    {!isPasswordsSame() && repassword.length > 0 && (
                      <Form.Help>
                        <div className="list-repassword">
                          Les mots de passe ne correspondent pas.
                        </div>
                      </Form.Help>
                    )}
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <div className="has-text-justified">
                    <div className="check">
                      <Form.Checkbox
                        name="termsAccepted"
                        onChange={handleChangeInput}
                        checked={termsAccepted}
                      />
                    </div>
                    <span>
                      J'accepte les{" "}
                      <a
                        href="#terms"
                        onClick={(e) => {
                          e.preventDefault();
                          if (showTerms)
                            setState((prev) => ({
                              ...prev,
                              showTerms: !prev.showTerms,
                            }));
                          else
                            setTimeout(
                              () =>
                                document
                                  .getElementById("terms")
                                  .scrollIntoView({ behavior: "smooth" }),
                              100,
                            );
                          setState((prev) => ({
                            ...prev,
                            showTerms: !prev.showTerms,
                          }));
                        }}
                      >
                        conditions générales d'utilisation
                      </a>
                    </span>
                  </div>
                </Columns.Column>
              </Columns>
              <ReCAPTCHA sitekey={keys.CAPTCHA} onChange={handleCaptcha} />

              <Form.Field>
                <Form.Control className="has-text-right">
                  <Button
                    color="info"
                    type="primary"
                    onClick={handleNextStep}
                    disabled={!canGoToStepTwo() || !state.isValidPassword}
                    loading={isSignupLoad}
                  >
                    Suivant
                  </Button>
                </Form.Control>
              </Form.Field>
              <div className="required-field">
                <p>*champs obligatoire</p>
              </div>
            </div>
          </div>
        </div>
        {showTerms && <Terms />}
      </div>
    );
  };

  const renderErrorNotification = () => {
    const { signupError } = state;

    if (signupError == null) return;
    return <Notification color="danger">{signupError}</Notification>;
  };

  const renderSuccessSignUp = () => {
    return (
      <div className="signup-wrapper container is-centered">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="box">
              <div className="title is-4 has-text-centered">
                <div className="step-subtitle subtitle is-uppercase">
                  Félicitations
                </div>
                Votre compte a été créé avec succès !
              </div>
              <div className="has-text-centered">
                Nous vous invitons à valider votre inscription à l'aide du mail
                que nous venons de vous envoyer
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderError = (message) => {
    return (
      <Container>
        <CenteredWrapper>
          <div>
            <ErrorBox>
              <ErrorTitle>
                <ErrorIcon size={32} />
                <ErrorMessage>{message}</ErrorMessage>
              </ErrorTitle>
              <HomeButton to="/signin">
                <span>{t("invitation.backToLogin")}</span>
              </HomeButton>
            </ErrorBox>
          </div>
        </CenteredWrapper>
      </Container>
    );
  };

  useEffect(() => {
    const fetchInvitation = async () => {
      const invitation = await getById(id);

      if (!invitation) {
        setState((prev) => ({
          ...prev,
          isSignupLoad: false,
          signupError: t("invitation.notFound"),
        }));
        return;
      }

      if (expired(invitation.createdAt)) {
        setState((prev) => ({
          ...prev,
          isSignupLoad: false,
          signupError: t("invitation.expired"),
        }));
        return;
      }

      const principalUser = await User.get(invitation.principal);
      if (!principalUser) {
        setState((prev) => ({
          ...prev,
          isSignupLoad: false,
          signupError: t("invitation.invalid"),
        }));
        return;
      }

      setInvitation(invitation);
      setPrincipalUser(principalUser);
      setState((prev) => ({
        ...prev,
        email: invitation.email,
        address: principalUser.address,
        addressCity: principalUser.addressCity,
        addressZipCode: principalUser.addressZipCode,
        company: principalUser.company,
        principalUser: principalUser.uid,
      }));
    };

    fetchInvitation().catch(() => {
      setState((prev) => ({
        ...prev,
        isSignupLoad: false,
        signupError: t("invitation.error"),
      }));
    });
  }, [id, t]);

  return (
    <>
      {state.signupError ? (
        renderError(state.signupError)
      ) : (
        <>
          {invitation ? (
            <>
              {state.step === 1 && renderStepOne()}
              {state.step === 2 && renderSuccessSignUp()}
              {state.step > 2 && <div>Etape inconnue...</div>}
            </>
          ) : (
            <div className="container has-text-centered">
              <div className="title is-4">{t("loading")}</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Invitation;
