import React, { useContext } from "react";
import Collapse from "react-collapse";
import { presets } from "react-motion";
import LocaleContext from "../../context/LocaleContext";
import { CustomHoverPopUp } from "../CustomHoverPopUp";
import styled from "styled-components";

const StyledCollapseConfig = styled.div`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const CollapseHeader = (props) => {
  const { i18n } = useContext(LocaleContext);
  return (
    <div
      onClick={props.onClick}
      style={{ cursor: "pointer", textAlign: "center", marginBottom: "10px" }}
    >
      {props.text && (
        <CustomHoverPopUp
          content={props.abbr}
          title={props.text}
          position="bottom center"
        >
          {props.text}
        </CustomHoverPopUp>
      )}
      {!props.text && (
        <span style={{ color: "grey", fontWeight: "bold" }}>
          {i18n.t("advancedConfiguration")}
        </span>
      )}
      {!props.isOpened && (
        <i
          style={{ color: "grey", marginLeft: "4px" }}
          className="fa fa-angle-right"
        />
      )}
      {props.isOpened && (
        <i
          style={{ color: "grey", marginLeft: "4px" }}
          className="fa fa-angle-down"
        />
      )}
    </div>
  );
};
class CollapseControlled extends React.Component {
  constructor(props) {
    super(props);
    this.state = { localCollapse: false };
  }

  render() {
    // const { isCollapsed } = this.state;
    const {
      className = "",
      conversionError,
      isCollapsed,
      dropdownName = "",
      handleOpenDropdown = () => {},
      abbr = "",
      visible = true,
    } = this.props;
    return (
      <StyledCollapseConfig className={`${className}`} visible={visible}>
        <CollapseHeader
          text={this.props.headerText}
          onClick={() => {
            if (dropdownName) handleOpenDropdown(dropdownName);
            else {
              this.setState({ localCollapse: !this.state.localCollapse });
            }
          }}
          isOpened={this.state.localCollapse || isCollapsed || conversionError}
          abbr={abbr}
        />
        <Collapse
          isOpened={this.state.localCollapse || isCollapsed || conversionError}
          springConfig={presets.stiff}
        >
          {this.props.children}
        </Collapse>
      </StyledCollapseConfig>
    );
  }
}
export default CollapseControlled;
