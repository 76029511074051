import React, { useContext, useState } from "react";
import { Button } from "react-bulma-components/full";
import FACTURE1 from "../../assets/img/facture1.png";
import FACTURE2 from "../../assets/img/facture2.png";

import landingPV from "../../assets/img/PV.jpg";
import styled from "styled-components";
import { Square } from "../../assets/style/commonStyledComponent";

export const Step2 = (props) => {
  const StepContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align &.step-5 {
      background: url(${landingPV});
    }
  `;

  const Main = styled.div`
    color: ${(props) => props.theme.mainText || "white"};
    font-weight: 500;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    max-width: 760px;
    margin: 20px;
  `;

  const Secondary = styled.div`
    color: ${(props) => props.theme.mainText || "white"};
    padding: 1em;
    padding-top: 0;
    height: 15%;
    font-size: 1.3rem !important;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    text-align: left;
    .emphasis {
      font-weight: bold;
      &.underlined {
        text-decoration: underline #001b51 5px;
        text-underline-offset: 0.5px;
      }
    }
  `;

  const Img = styled.img`
    position: relative;
    margin: 0 auto;
    &.facture-1 {
      width: 35% !important;
    }
    &.facture-2 {
      width: 95% !important;
      padding-bottom: 5%;
    }
  `;

  return (
    <StepContainer>
      <Square>
        <Main>
          Avant de commencer, munissez-vous de votre facture d'électricité pour
          repérer :
        </Main>
        <Secondary>
          1. Le Point de Livraison (PDL) ou le Point de Référence Mesure (PRM) :
        </Secondary>
        <Img className="facture-1" src={FACTURE1}></Img>
        <Secondary>2. Vos tarifs d'achat d'électricité :</Secondary>
        <Img className="facture-2" src={FACTURE2}></Img>
      </Square>
    </StepContainer>
  );
};
