import { db } from "./../fire";
import DatabaseConstants from "./../constants/Database";
import to from "await-to-js";
class DeveloppersService {
  constructor() {
    this.developper = window.location.hostname;
  }
  getByDevelopper = (developper = this.developper) =>
    db
      .collection(DatabaseConstants.COLLECTION_DEVELOPPERS)
      .doc(developper)
      .get()
      .then((doc) => (doc.exists ? { ...doc.data() } : null));

  add = async (developper = this.developper, data) => {
    const refDeveloppers = db
      .collection(DatabaseConstants.COLLECTION_DEVELOPPERS)
      .doc(developper);
    refDeveloppers.set(data, { merge: true });
    return refDeveloppers.id;
  };

  update = async (developper = this.developper, data) => {
    const refDeveloppers = db
      .collection(DatabaseConstants.COLLECTION_DEVELOPPERS)
      .doc(developper);
    return refDeveloppers.set(data, { merge: true });
  };
  removeByDevelopper = async (developper = this.developper) => {
    return db
      .collection(DatabaseConstants.COLLECTION_DEVELOPPERS)
      .doc(developper)
      .delete();
  };

  getAll = async () => {
    const docRef = db.collection(DatabaseConstants.COLLECTION_DEVELOPPERS);
    const [err, developpers] = await to(docRef.get());
    if (err) {
      console.error(`Could not get user. Error : ${err.message}`);
      return null;
    }
    if (developpers) return developpers.docs.map((doc) => doc.data());
    return null;
  };
}

export default new DeveloppersService();
