module.exports = {
  AD: "Andorre",
  AE: "Emirats Arabes Unis",
  AF: "Afghanistan",
  AG: "Antigua-Et-Barbuda",
  AI: "Anguilla",
  AL: "Albanie",
  AM: "Arménie",
  AO: "Angola",
  AQ: "Antarctique",
  AR: "Argentine",
  AS: "Samoa Américaines",
  AT: "Autriche",
  AU: "Australie",
  AW: "Aruba",
  AX: "Iles Åland",
  AZ: "Azerbaïdjan",
  BA: "Bosnie-Herzégovine",
  BB: "Barbade",
  BD: "Bangladesh",
  BE: "Belgique",
  BF: "Burkina Faso",
  BG: "Bulgarie",
  BH: "Bahreïn",
  BI: "Burundi",
  BJ: "Bénin",
  BL: "Saint-Barthélemy",
  BM: "Bermudes",
  BN: "Brunei Darussalam",
  BO: "Bolivie",
  BQ: "Bonaire, Saint-Eustache Et Saba",
  BR: "Brésil",
  BS: "Bahamas",
  BT: "Bhoutan",
  BV: "Ile Bouvet",
  BW: "Botswana",
  BY: "Biélorussie",
  BZ: "Belize",
  CA: "Canada",
  CC: "Iles Cocos",
  CD: "République Démocratique Du Congo",
  CF: "République Centrafricaine",
  CG: "Congo",
  CH: "Suisse",
  CI: "Côte D'Ivoire",
  CK: "Iles Cook",
  CL: "Chili",
  CM: "Cameroun",
  CN: "Chine",
  CO: "Colombie",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cap-Vert",
  CW: "Curaçao",
  CX: "Ile Christmas",
  CY: "Chypre",
  CZ: "République Tchèque",
  DE: "Allemagne",
  DJ: "Djibouti",
  DK: "Danemark",
  DM: "Dominique",
  DO: "République Dominicaine",
  DZ: "Algérie",
  EC: "Equateur",
  EE: "Estonie",
  EG: "Egypte",
  EH: "Sahara Occidental",
  ER: "Erythrée",
  ES: "Espagne",
  ET: "Ethiopie",
  EU: "Europe",
  FI: "Finlande",
  FJ: "Fidji",
  FK: "Iles Malouines",
  FM: "Etats Fédérés De Micronésie",
  FO: "Iles Féroé",
  FR: "France",
  GA: "Gabon",
  GB: "Royaume-Uni",
  GD: "Grenade",
  GE: "Géorgie",
  GF: "Guyane",
  GG: "Guernesey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Groenland",
  GM: "Gambie",
  GN: "Guinée",
  GP: "Guadeloupe",
  GQ: "Guinée Equatoriale",
  GR: "Grèce",
  GS: "Géorgie Du Sud-Et-Les Iles Sandwich Du Sud",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HM: "Iles Heard-Et-MacDonald",
  HN: "Honduras",
  HR: "Croatie",
  HT: "Haïti",
  HU: "Hongrie",
  ID: "Indonésie",
  IE: "Irlande",
  IL: "Israël",
  IM: "Ile De Man",
  IN: "Inde",
  IO: "Territoire Britannique De L'océan Indien",
  IQ: "Irak",
  IR: "République Islamique D'Iran",
  IS: "Islande",
  IT: "Italie",
  JE: "Jersey",
  JM: "Jamaïque",
  JO: "Jordanie",
  JP: "Japon",
  KE: "Kenya",
  KG: "Kirghizistan",
  KH: "Cambodge",
  KI: "Kiribati",
  KM: "Comores",
  KN: "Saint-Christophe-et-Niévès",
  KP: "République Populaire Démocratique De Corée",
  KR: "République De Corée",
  KW: "Koweït",
  KY: "Iles Caïmans",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Liban",
  LC: "Sainte-Lucie",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lituanie",
  LU: "Luxembourg",
  LV: "Lettonie",
  LY: "Libye",
  MA: "Maroc",
  MC: "Monaco",
  MD: "Moldavie",
  ME: "Monténégro",
  MF: "Saint-Martin (Partie Française)",
  MG: "Madagascar",
  MH: "Iles Marshall",
  MK: "Macédoine",
  ML: "Mali",
  MM: "Birmanie",
  MN: "Mongolie",
  MO: "Macao",
  MP: "Iles Mariannes Du Nord",
  MQ: "Martinique",
  MR: "Mauritanie",
  MS: "Montserrat",
  MT: "Malte",
  MU: "Maurice",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexique",
  MY: "Malaisie",
  MZ: "Mozambique",
  NA: "Namibie",
  NC: "Nouvelle-Calédonie",
  NE: "Niger",
  NF: "Ile Norfolk",
  NG: "Nigéria",
  NI: "Nicaragua",
  NL: "Pays-Bas",
  NO: "Norvège",
  NP: "Népal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  PA: "Panama",
  PE: "Pérou",
  PF: "Polynésie Française",
  PG: "Papouasie-Nouvelle-Guinée",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Pologne",
  PM: "Saint-Pierre-Et-Miquelon",
  PN: "Pitcairn",
  PR: "Porto Rico",
  PS: "Territoires Palestiniens Occupés",
  PT: "Portugal",
  PW: "Palaos",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  RS: "Serbie",
  RU: "Fédération De Russie",
  RW: "Rwanda",
  SA: "Arabie Saoudite",
  SB: "Iles Salomon",
  SC: "Seychelles",
  SD: "Soudan",
  SE: "Suède",
  SG: "Singapour",
  SH: "Sainte-Hélène",
  SI: "Slovénie",
  SJ: "Svalbard Et Jan Mayen",
  SK: "Slovaquie",
  SL: "Sierra Leone",
  SM: "Saint-Marin",
  SN: "Sénégal",
  SO: "Somalie",
  SR: "Suriname",
  SS: "Soudan Du Sud",
  ST: "Sao Tomé-Et-Principe",
  SV: "République Du Salvador",
  SX: "Saint-Martin (Partie Néerlandaise)",
  SY: "République Arabe Syrienne",
  SZ: "Swaziland",
  TC: "Iles Turks-Et-Caïcos",
  TD: "Tchad",
  TF: "Terres Australes Françaises",
  TG: "Togo",
  TH: "Thaïlande",
  TJ: "Tadjikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkménistan",
  TN: "Tunisie",
  TO: "Tonga",
  TR: "Turquie",
  TT: "Trinité-Et-Tobago",
  TV: "Tuvalu",
  TW: "Taïwan",
  TZ: "Tanzanie",
  UA: "Ukraine",
  UG: "Ouganda",
  UM: "Iles Mineures Eloignées Des Etats-Unis",
  US: "Etats-Unis",
  UY: "Uruguay",
  UZ: "Ouzbékistan",
  VA: "Saint-Siège (Etat De La Cité Du Vatican)",
  VC: "Saint-Vincent-Et-Les Grenadines",
  VE: "Venezuela",
  VG: "Iles Vierges Britanniques",
  VI: "Iles Vierges Des Etats-Unis",
  VN: "Viet Nam",
  VU: "Vanuatu",
  WF: "Wallis Et Futuna",
  WS: "Samoa",
  YE: "Yémen",
  YT: "Mayotte",
  ZA: "Afrique Du Sud",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};
