module.exports = {
  fr: {
    consumer: {
      "Coûts Stockage virtuel": "Coûts Stockage virtuel",
      "Achats combustible": "Achats combustible",
      "Achats hydrogene": "Achats hydrogene",
      "Revenus vente PV": "Revenus vente PV",
      "Revenus vente PV2": "Revenus vente PV2",
      "Revenus vente Eolien": "Revenus vente Eolien",
      "Revenus Effacement sur Spot": "Revenus Effacement sur Spot",
      "Revenus Effacement Complement de Remuneration":
        "Revenus Effacement Complement de Remuneration",
      "Revenus Effacement Mecanisme de Capacite":
        "Revenus Effacement Mecanisme de Capacite",
      "Revenus vente hydrogene": "Revenus vente hydrogene",
      "Achats PV en PPA": "Achats PV en PPA",
      "Achats PV2 en PPA": "Achats PV2 en PPA",
      "Achats Eolien en PPA": "Achats Eolien en PPA",
      "Prime AO autoconsommation": "Prime AO autoconsommation",
      "Achats reseau pre-optim": "Achats reseau pre-optim",
      "Achats reseau post-optim": "Achats reseau post-optim",
      "Economie sur la facture": "Economie sur la facture",
      Economie: "Economie",
      "Economies totales sur la facture": "Economies totales sur la facture",
      "Total produit d'exploitation": "Total produit d'exploitation",
      "Excédent brut d'exploitation": "Excédent brut d'exploitation",
      "Résultat net": "Résultat net",
      Interets: "Interets",
      "Remboursement emprunt": "Remboursement emprunt",
      Amortissements: "Amortissements",
      Impôts: "Impôts",
      FreeCashFlow: "Flux de trésorerie",
      VAN: "Valeur actuelle net",
      TRI: "TRI fonds propres",
      TRIProject: "TRI projet",
      "Temps de Retour": "Temps de Retour",
      IFERTax: "Taxe IFER (€)",
      "Facture avant": "Facture réseau avant",
      "Facture après": "Facture réseau après",
      Subventions: "Prime",
      totalSum: "Somme totale",
      yearlyAverage: "Moyenne/an",
      monthlyAverage: "Moyenne/mois",
    },
    supplier: {
      "Achats reseau": "Achats reseau",
      "Achats combustible": "Achats combustible",
      "Achats hydrogene": "Achats hydrogene",
      "Revente H2": "Vente H2",
      "Revenus Batterie Mecanisme de Capacite":
        "Revenus Batterie Mecanisme de Capacite",
      "Revenus FCR": "Revenus FCR",
      "Revenus PPA Batterie": "Revenus PPA Batterie",
      "Revenus Batterie sur Spot": "Revenus Batterie sur Spot",
      "Revenus PPA Pile a combustible": "Revenus PPA Pile a combustible",
      "Revenus Pile a combustible sur Spot":
        "Revenus Pile a combustible sur Spot",
      "Revenus PPA Groupe Elec.": "Revenus PPA Groupe Elec.",
      "Revenus PV Mecanisme de Capacite": "Revenus PV Mecanisme de Capacite",
      "Revenus PV Complement de remuneration":
        "Revenus PV Complement de remuneration",
      "Revenus PPA PV": "Revenus PPA PV",
      "Complement PPA PV": "Complement PPA PV",
      "Complement PPA Eolien": "Complement PPA Eolien",
      "Revenus PV sur Spot": "Revenus PV sur Spot",
      "Revenus Eolien Mecanisme de Capacite":
        "Revenus Eolien Mecanisme de Capacite",
      "Revenus Eolien Complement de remuneration":
        "Revenus Eolien Complement de remuneration",
      "Revenus PPA Eolien": "Revenus PPA Eolien",
      "Revenus Eolien sur Spot": "Revenus Eolien sur Spot",
      "Revenus PV2 Mecanisme de Capacite": "Revenus PV2 Mecanisme de Capacite",
      "Revenus PV2 Complement de remuneration":
        "Revenus PV2 Complement de remuneration",
      "Revenus PPA PV2": "Revenus PPA PV2",
      "Complement PPA PV2": "Complement PPA PV2",
    },
  },
  en: {
    consumer: {
      "Coûts Stockage virtuel": "Virtual storage costs",
      "Achats combustible": "Fuel purchase",
      "Achats hydrogene": "Hydrogen purchase",
      "Revenus vente PV": "Photovoltaic sales",
      "Revenus vente PV2": "Photovoltaic 2 sales",
      "Revenus vente Eolien": "Wind turbine sales",
      "Revenus Effacement sur Spot": "Withdrawal income on SPOT market",
      "Revenus Effacement Complement de Remuneration":
        "Withdrawal income Complement",
      "Revenus Effacement Mecanisme de Capacite": "Withdrawal income FCR",
      "Revenus vente hydrogene": "Hydrogen sales income",
      "Achats PV en PPA": "PPA Solar panel purchase",
      "Achats PV2 en PPA": "PPA Solar panel 2 purchase",
      "Achats Eolien en PPA": "PPA Wind turbine purchase",
      "Prime AO autoconsommation": "Self-consumption tender bonus",
      "Achats reseau pre-optim": "Pre-optim grid purchase",
      "Achats reseau post-optim": "Post-optim grid purchase",
      "Economie sur la facture": "Savings on bill",
      Economie: "Economy",
      "Economies totales sur la facture": "Total savings on bill",
      "Total produit d'exploitation": "Total operating income",
      "Excédent brut d'exploitation": "Gross operating surplus",
      "Résultat net": "Net result",
      Interets: "Interests",
      "Remboursement emprunt": "Debt repayment",
      Amortissements: "Depreciation",
      Impôts: "Taxes and fees",
      FreeCashFlow: "FreeCashFlow",
      VAN: "NPV",
      TRI: "Financial IRR",
      TRIProject: "Project IRR",
      "Temps de Retour": "Runback time",
      IFERTax: "IFER tax",
      "Facture avant": "Grid bill before optimization",
      "Facture après": "Grid bill after optimization",
      Subventions: "Subventions",
      totalSum: "Sum",
      yearlyAverage: "Yearly average",
      monthlyAverage: "Monthly average",
    },
    supplier: {
      "Achats reseau": "Grid purchase",
      "Achats combustible": "Fuel cost",
      "Achats hydrogene": "Hydrogen purchase",
      "Revente H2": "H2 sales",
      "Revenus Batterie Mecanisme de Capacite": "Battery Capacity income",
      "Revenus FCR": "FCR income",
      "Revenus PPA Batterie": "Battery PPA income",
      "Revenus Batterie sur Spot": "Battery SPOT income",
      "Revenus PPA Pile a combustible": "Fuel cell PPA income",
      "Revenus Pile a combustible sur Spot": "Fuel cell SPOT income",
      "Revenus PPA Groupe Elec.": "Engine-generator PPA income",
      "Revenus PV Mecanisme de Capacite": "Photovoltaic Capacity income",
      "Revenus PV Complement de remuneration": "Photovoltaic Complement income",
      "Revenus PPA PV": "Photovoltaic PPA income",
      "Complement PPA PV": "Photovoltaic PPA Complement",
      "Complement PPA Eolien": "Wind turbine PPA Complement",
      "Revenus PV sur Spot": "Solar panel SPOT income",
      "Revenus Eolien Mecanisme de Capacite": "Wind turbine Capacity income",
      "Revenus Eolien Complement de remuneration":
        "Wind turbine complement income",
      "Revenus PPA Eolien": "Wind turbine PPA income",
      "Revenus Eolien sur Spot": "Wind turbine SPOT income",
      "Revenus PV2 Mecanisme de Capacite": "Photovoltaic Capacity income",
      "Revenus PV2 Complement de remuneration":
        "Photovoltaic 2 Complement income",
      "Revenus PPA PV2": "Photovoltaic 2 PPA income",
      "Complement PPA PV2": "Photovoltaic 2 PPA Complement",
    },
  },
};
