import React, { useState, useEffect } from "react";
import { Navbar } from "react-bulma-components/full";
import { NavLink, withRouter } from "react-router-dom";
import Classnames from "classnames";
import Firebase from "firebase";

import "./Header.scss";

import logo from "./../assets/img/logo.png";
import Auth from "../services/Auth";
import SiteConstant from "../constants/Site";
import { Translation } from "react-i18next";
import i18n from "../config/i18n";
import { TutorialContainer } from "../components/Header/TutorialContainer";
import { CustomNotification } from "./CustomNotification";
import { getDiffBetweenTwoDatesInUnit, getCookie } from "../utils/Utils";
// import EVENT_LOGO from "./../assets/img/event.png";
import { checkWidth } from "../utils/Responsive";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import notifications$, {
  getNotificationsToShow,
  toogleNotification,
} from "../store/notifications/NotificationsStore";
import { CustomModal } from "./CustomModal";
import { POP_UPS } from "../constants/Notifications";
import { AiFillHome } from "react-icons/ai";
import {
  CustomGetACCMessage,
  CustomEndOfDemoMessage,
  CustomUpgradeToBTSUPMessage,
  CustomUpgradePlanMessage,
  CustomUpgradePlanFeatureMessage,
} from "./CustomCTAMessage";
import { CustomMailEditor } from "./CustomMailEditor";

import { isUserAllowedToChangeParams } from "../helpers/Validators";

import { CustomHoverPopUp } from "./CustomHoverPopUp";
import styled from "styled-components";
dayjs.extend(duration);
dayjs.extend(customParseFormat);
const LOCAL_VERSION = "0.0.1";
import { FaList, FaPollH } from "react-icons/fa";
import { SiGoogleanalytics } from "react-icons/si";
import _ from "lodash";

const StyledNavLink = styled(NavLink)`
  &:hover {
    ${(props) =>
      props.disabled &&
      `
		cursor: not-allowed
	`}
  }
`;

const Account = ({ isMobile, allowedToChangeParams = true }) => {
  const [active, setActive] = useState(false);

  const clickEvent = ({ target }) => {
    const pupUp = document.querySelector(".pop-up");
    const isClosest = target.closest(".account");

    if (!isClosest && pupUp?.classList.contains("active")) {
      pupUp.classList.remove("active");
    }
  };
  document.addEventListener("click", clickEvent);

  useEffect(() => {
    return () => {
      window.removeEventListener("click", clickEvent);
    };
  }, []);

  if (isMobile) {
    return (
      <>
        <NavLink className="navbar-item" to="/me">
          <span className="icon has-margin-right__Small">
            <i className="fa fa-user" />
          </span>
          {/* {Auth.getUser().email} */}
          <Translation>{(t) => t("myAccount")}</Translation>
        </NavLink>
        <NavLink className="navbar-item" to="/signout">
          &emsp;<Translation>{(t) => t("logOut")}</Translation>
        </NavLink>
      </>
    );
  }

  return (
    <Navbar.Item renderAs="div" className="account">
      <div
        className="toggler"
        id="my-account"
        onClick={() => setActive(!active)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            setActive(!active);
          }
        }}
        tabIndex={0}
        role="button"
        aria-haspopup="true"
        aria-expanded={active}
      >
        <span className="icon">
          <i className="fa fa-user" aria-hidden="true" />
        </span>
        <Translation>{(t) => t("myAccount")}</Translation>
        <span className="icon">
          <i
            className={`fa fa-caret-${!active ? "right" : "down"}`}
            aria-hidden="true"
          />
        </span>
      </div>
      <div className={`pop-up${active ? " active" : ""}`} role="menu">
        <ul>
          <li>
            <CustomHoverPopUp
              disabled={allowedToChangeParams}
              title="Ce compte est géré par un administrateur"
            >
              <StyledNavLink
                className="navbar-item"
                to={!allowedToChangeParams ? "" : "/me"}
                id="my-settings"
                disabled={!allowedToChangeParams}
                tabIndex={active ? 0 : -1}
              >
                <Translation>{(t) => t("mySettings")}</Translation>
              </StyledNavLink>
            </CustomHoverPopUp>
          </li>
          <li>
            <NavLink
              className="navbar-item"
              to="/signout"
              tabIndex={active ? 0 : -1}
            >
              <Translation>{(t) => t("logOut")}</Translation>
            </NavLink>
          </li>
        </ul>
      </div>
    </Navbar.Item>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      language: null,
      showTutorial: false,
      tutorialStep: 0,
      showUpdateButton: false,
      showNotification: true,
      showModal: false,
      notificationsToShow: [],
    };
    this.notificationsObservable = null;
  }

  handleClickEvent = ({ target }) => {
    const navbarBurger = document.querySelector(".navbar .navbar-burger");
    const navbarMenu = document.querySelector(".navbar .navbar-menu");
    const isClosest = target.closest(".navbar");

    if (!isClosest && navbarBurger?.classList.contains("is-active")) {
      this.setState({ isActive: false });
    }
  };

  handleResizeEvent = () => {
    const isMobile = checkWidth();
    if (!isMobile && this.state.isActive) {
      return this.setState({ isActive: false, isMobile });
    }
    return this.setState({ isMobile });
  };

  componentDidMount = () => {
    const notificationCookie = getCookie("showLicenceNotification");

    this.notificationsObservable = notifications$.subscribe(() => {
      const notificationsToShow = getNotificationsToShow();
      this.setState({
        notificationsToShow,
        showModal: notificationsToShow.length > 0,
      });
    });
    if (notificationCookie) {
      this.setState({ showNotification: !!+notificationCookie });
    }

    this.setState({
      language: i18n.language,
    });

    window.addEventListener("resize", this.handleResizeEvent);

    document.addEventListener("click", this.handleClickEvent);

    return this.setState({ isMobile: checkWidth() });
  };

  componentWillUnmount = () => {
    this.notificationsObservable.unsubscribe();
    window.removeEventListener("resize", this.handleResizeEvent);
    window.removeEventListener("click", this.handleClickEvent);
  };

  handleActive = () => this.setState({ isActive: !this.state.isActive });

  renderWhenNotLogged = () => {
    const { isActive } = this.state;
    const { location } = this.props;
    const { pathname } = location;
    const isConsentIncluded = pathname.includes("consent");

    return (
      <Navbar active={isActive}>
        <Navbar.Brand>
          <Navbar.Item renderAs="a" href="#">
            <div className="logo-container">
              <img src={logo} alt="Likewatt Logo" className="brand-logo" />
            </div>
          </Navbar.Item>
          {!isConsentIncluded && (
            <Navbar.Burger
              active={isActive.toString()}
              onClick={this.handleActive}
            />
          )}
        </Navbar.Brand>

        <Navbar.Menu
          active={isActive.toString()}
          onClick={() => this.state.isActive && this.handleActive()}
        >
          <Navbar.Container />
          {!isConsentIncluded && (
            <Navbar.Container position="end">
              <>
                <NavLink className="navbar-item" to="/signin">
                  Se connecter
                </NavLink>
                <NavLink className="navbar-item" to="/signup">
                  S'inscrire
                </NavLink>{" "}
              </>
            </Navbar.Container>
          )}
        </Navbar.Menu>
      </Navbar>
    );
  };

  toggleNotification = () => {
    //Fixed to 20 days since the notification will only appear if the licence expires in less than 30 days
    //and the user shouldn't be able to close it 10 days prior to expiration
    //NB : Brave browser limits cookies max-age to 7 days.
    const duration = dayjs.duration(20, "days").as("seconds");
    document.cookie = `showLicenceNotification=0; max-age=${duration}; path=/`;
    this.setState({ showNotification: false });
  };

  renderLicenceWarningNotification = () => {
    const { showNotification } = this.state;
    const user = Auth.getUser();

    const difference = getDiffBetweenTwoDatesInUnit(
      dayjs(user.endDate, "DD/MM/YYYY"),
      dayjs(),
    );
    const renewed = !_.isEmpty(user.renewLicense);
    if (showNotification && difference && difference <= 30 && !renewed && !user.automaticRenew) {
      return (
        <CustomNotification
          color="danger"
          noMargin
          padding="10px"
          isBold
          logo="danger"
          isCloseable={difference > 20}
          toggleNotification={this.toggleNotification}
          fontSize="0.8em"
        >
          {difference > 0
            ? i18n.t("info.licenceWillExpireIn", {
                number: difference,
                unit: difference > 1 ? i18n.t("days") : i18n.t("day"),
              })
            : i18n.t("info.licenceHasExpired")}
        </CustomNotification>
      );
    }
    return null;
  };

  renderNotificationPopUp = () => {
    const { notificationsToShow } = this.state;

    let content = null;
    switch (notificationsToShow[0]) {
      case POP_UPS.GET_ACC:
        content = <CustomGetACCMessage />;
        break;
      case POP_UPS.END_OF_DEMO:
        content = <CustomEndOfDemoMessage />;
        break;
      case POP_UPS.BTINF:
        content = <CustomUpgradeToBTSUPMessage />;
        break;
      case POP_UPS.UPGRADE_PLAN:
        content = <CustomUpgradePlanMessage />;
        break;
      case POP_UPS.SEND_MAIL:
        content = <CustomMailEditor />;
        break;
      case POP_UPS.FEATURE_UPGRADE:
        content = <CustomUpgradePlanFeatureMessage />;
        break;
      default:
        break;
    }
    return content;
  };

  renderWhenLogged = () => {
    const {
      isActive,
      isMobile,
      showTutorial,
      showNotification = false,
      notificationsToShow,
      showModal,
    } = this.state;
    const { location } = this.props;
    const { pathname } = location;
    const isConsentIncluded = pathname.includes("consent");
    const allowedToChangeParams = isUserAllowedToChangeParams();

    return (
      <Navbar active={isActive}>
        {/* <img src={EVENT_LOGO} id="event-logo" /> * MAJ EVENT */}
        <TutorialContainer
          showTutorial={showTutorial}
          handleShowTutorial={this.handleShowTutorial}
          currentPage={pathname}
          closeTutorial={this.closeTutorial}
        />
        <CustomModal
          color="danger"
          show={showModal}
          bodyOnly={notificationsToShow[0] !== POP_UPS.UPGRADE_PLAN}
          title={
            notificationsToShow[0] === POP_UPS.UPGRADE_PLAN
              ? "Le nombre de sites maximal permis par votre licence a été atteint"
              : ""
          }
          onClose={() => toogleNotification(notificationsToShow[0])}
          onConfirm={() => toogleNotification(notificationsToShow[0])}
          isFullHeight={notificationsToShow[0] === POP_UPS.SEND_MAIL}
        >
          {this.renderNotificationPopUp()}
        </CustomModal>
        <Navbar.Brand>
          <Navbar.Item renderAs="a" href="/">
            <div className="logo-container">
              <img src={logo} alt="Likewatt Logo" className="brand-logo" />
            </div>
          </Navbar.Item>

          {!isConsentIncluded && (
            <Navbar.Burger
              active={isActive.toString()}
              onClick={this.handleActive}
            />
          )}
        </Navbar.Brand>

        <Navbar.Menu
          active={isActive.toString()}
          onClick={() => this.state.isActive && this.handleActive()}
        >
          {!isConsentIncluded && (
            <>
              <Navbar.Container position="start">
                <NavLink
                  className={Classnames({
                    "navbar-item": true,
                    "is-active": pathname === "/",
                  })}
                  to="/"
                >
                  <span className="icon has-margin-right__Small">
                    <AiFillHome />
                  </span>
                  <Translation>{(t) => t("pages.home")}</Translation>
                  {/* <Trans i18nKey="title">Accueil</Trans> */}
                </NavLink>
                <NavLink
                  id="analyze-header"
                  className={Classnames({
                    "navbar-item": true,
                    "is-active": pathname.match(/^\/analyze/),
                  })}
                  to="/analyze"
                >
                  <span className="icon has-margin-right__Small">
                    <SiGoogleanalytics />
                  </span>
                  1. <Translation>{(t) => t("pages.analyze")}</Translation>
                </NavLink>
                <NavLink
                  id="scenarios"
                  className={Classnames({
                    "navbar-item": true,
                    "is-active": pathname.match(/^\/optimization/),
                  })}
                  to="/optimization"
                >
                  <span className="icon has-margin-right__Small">
                    <FaList />
                  </span>
                  2. <Translation>{(t) => t("pages.scenarios")}</Translation>
                </NavLink>
                <NavLink
                  id="results"
                  className={Classnames({
                    "navbar-item": true,
                    "is-active": pathname.match(/^\/results/),
                  })}
                  to="/results"
                >
                  <span className="icon has-margin-right__Small">
                    <FaPollH />
                  </span>
                  3. <Translation>{(t) => t("pages.results")}</Translation>
                </NavLink>
                {Auth.isAuth() && Auth.getUser().principal && (
                  <NavLink
                    className={Classnames({
                      "navbar-item": true,
                      "is-active": pathname.match(/^\/gestion/),
                    })}
                    to="/gestion"
                  >
                    <span className="icon has-margin-right__Small">
                      <i className="fa fa-users" />
                    </span>
                    <Translation>{(t) => t("pages.workspace")}</Translation>
                  </NavLink>
                )}
                {Auth.isAuth() && Auth.getUser().isAdmin && (
                  <NavLink
                    className={Classnames({
                      "navbar-item": true,
                      "is-active": pathname.match(/^\/admin/),
                    })}
                    to="/admin"
                  >
                    <span className="icon has-margin-right__Small">
                      <i className="fa fa-desktop" />
                    </span>
                    <Translation>{(t) => t("pages.admin")}</Translation>
                  </NavLink>
                )}
                {Auth.isAuth() && Auth.getUser().isManager && (
                  <NavLink
                    className={Classnames({
                      "navbar-item": true,
                      "is-active": pathname.match(/^\/manage/),
                    })}
                    to="/manage"
                  >
                    <span className="icon has-margin-right__Small">
                      <i className="fa fa-desktop" />
                    </span>
                    <Translation>{(t) => t("pages.manage")}</Translation>
                  </NavLink>
                )}
              </Navbar.Container>
              <Navbar.Container position="end">
                {/* {this.state.showUpdateButton && (
              <NavLink className="navbar-item" to="#" id="help">
                <Button color="warning" onClick={this.updateApp}>
                  Une mise à jour est disponible !
                </Button>
              </NavLink>
            )} */}

                {this.renderLicenceWarningNotification()}

                <NavLink className="navbar-item" to="#" id="help">
                  <div onClick={this.handleShowTutorial}>
                    <span className="icon has-margin-right__Small">
                      <i className="fa fa-question" />
                    </span>
                    <Translation>{(t) => t("pages.help")}</Translation>
                  </div>
                </NavLink>
                {/* {this.state.language && Auth.getUser().isAdmin && (
              <select
                onChange={this.handleLanguageChange}
                value={i18n.language}
              >
                {Object.keys(LOCALES).map((locale) => (
                  <option key={locale} value={LOCALES[locale]}>
                    {locale}
                  </option>
                ))}
              </select>
            )} */}
                <Account
                  isMobile={isMobile}
                  allowedToChangeParams={allowedToChangeParams}
                  id="my-account"
                />
              </Navbar.Container>{" "}
            </>
          )}
        </Navbar.Menu>
      </Navbar>
    );
  };

  updateApp = () => window.location.reload();

  checkAppVersion = async () => {
    const dbRef = Firebase.database().ref(`/VERSION/`);
    dbRef.on("value", (snap) => {
      if (snap) {
        const REMOTE_VERSION = snap.val();
        if (
          (REMOTE_VERSION !== LOCAL_VERSION && !this.state.showUpdateButton) ||
          (REMOTE_VERSION === LOCAL_VERSION && this.state.showUpdateButton)
        )
          return this.setState({
            showUpdateButton: REMOTE_VERSION !== LOCAL_VERSION,
          });
      }
    });
  };

  closeTutorial = () => this.setState({ showTutorial: false });

  handleShowTutorial = () => {
    const { location } = this.props;
    const { pathname } = location;

    this.setState({ showTutorial: !this.state.showTutorial });
  };
  relocate = () => window.open(SiteConstant.userManual);

  render() {
    const { location } = this.props;
    const { pathname } = location;
    if (Auth.isAuthLocally() && Auth.getUser() !== null)
      return this.renderWhenLogged();
    return this.renderWhenNotLogged();
  }
}

export default withRouter(Header);
