import React, { useContext, useState } from "react";
import SiteConstants from "../../../constants/Site";

import "../EditorSiteForm.scss";
import LocaleContext from "../../../context/LocaleContext";
import EditorSiteForm from "../EditorSiteForm";
import ConsumerPlusEditor from "../ConsumerPlusEditor";
import { INDIV, FULL_SELLOUT, ACC } from "./SiteTypes";
import { Step0 } from "./Step0";
/**
 *
 * If user is Producer , they can create :
 * 	Individual consumer site
 * 	ACC site
 * 	Full Sell Out site (as consumer)
 *
 */

export function CONSUMER_PLUSStep0(props) {
  const { cancelEditor } = props;
  const context = useContext(LocaleContext);
  const { i18n } = context;
  const POSSIBLE_CHOICES = [
    {
      name: i18n.t("selfConsumptionProject"),
      description: i18n.t("captions.selfConsumptionProject"),

      img: INDIV,
      action() {
        setRenderEditor(SiteConstants.type.CONSUMER);
      },
    },
    {
      name: i18n.t("consumer_plusProject"),
      description: i18n.t("captions.consumer_plusProject"),

      img: ACC,
      action() {
        setRenderEditor(SiteConstants.type.CONSUMER_PLUS);
      },
    },
    {
      name: i18n.t("fullSellOutProject"),
      description: i18n.t("captions.fullSellOutProject"),

      img: FULL_SELLOUT,
      action() {
        setIsFullSellOut(true);
        setRenderEditor(SiteConstants.type.CONSUMER);
      },
    },
  ];
  const [renderEditor, setRenderEditor] = useState(null);
  const [isFullSellOut, setIsFullSellOut] = useState(false);
  const handleRemoveEditor = () => {
    setRenderEditor(null);
  };
  if (!renderEditor) {
    return <Step0 choices={POSSIBLE_CHOICES} cancelEditor={cancelEditor} />;
  }
  switch (renderEditor) {
    case SiteConstants.type.CONSUMER:
      return (
        <EditorSiteForm
          type={renderEditor}
          isFullSellOut={isFullSellOut}
          handleRemoveEditor={handleRemoveEditor}
          {...props}
        />
      );
    case SiteConstants.type.CONSUMER_PLUS:
      return (
        <ConsumerPlusEditor
          {...props}
          handleRemoveEditor={handleRemoveEditor}
        />
      );
    default:
      break;
  }
}
