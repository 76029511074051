import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GoogleMapReact from "google-map-react";
import randomString from "randomstring";
import KEYS from "../../keys";
import { addPdfElement } from "../../store/pdf/store";
import _ from "lodash";
import html2canvas from "html2canvas";
const bootstrapURLKeys = {
  key: KEYS.MAPS,
  libraries: ["drawing", "geometry", "places"].join(","),
};
import "./map.scss";

const Map = memo((props) => {
  const OPTIONS = useMemo(
    () => ({
      zoomControl: true,
      fullscreenControl: true,
      mapTypeId: "hybrid",
    }),
    [],
  );

  const isDraw = useRef(props?.isDraw);
  const [refresh, setRefresh] = useState(false);
  let path;
  if (props.isInResults === true)
    path = useRef(_.cloneDeep([...props?.path, ...props?.pathpv2]) || []);
  else path = useRef([...props?.path] || []);
  const zoomLevel = useRef(19);
  const coordinates = useRef(
    props.isInResults === true || props.isInDefaultScenario === true
      ? [...props?.coordinates]
      : [...props?.coordinates()],
  );
  const mapSetting = useRef({});
  const isInResults = useRef(props?.isInResults);
  const polygonList = useRef([]);
  const settings = useRef(props?.settings);
  const exclusionZone = useRef([...(props?.exclusionZone || [])]);
  const currentDrawingMode = useRef(2);
  const componentRef = useRef(null);
  const [key, setKey] = useState(randomString.generate(10));
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const markers = useRef([]);

  const clearMarkers = () => {
    markers.current.forEach((marker) => {
      marker.setMap(null);
    });
    markers.current = [];
  };
  useEffect(() => {
    setKey(randomString.generate(10));
  }, [refresh]);
  useEffect(() => {
    if (mapInstance === null) {
      return;
    }
    if (mapsInstance === null) {
      return;
    }

    // Nettoyer les polygones existants
    const cleanExistingPolygons = () => {
      // Supprimer tous les polygones de la liste polygonList
      polygonList.current.forEach((polygonData) => {
        if (polygonData?.mainPolygon) {
          polygonData.mainPolygon.setMap(null);
          polygonData.listOfPanels.forEach((panel) => {
            panel.setMap(null);
          });
        }
      });
      polygonList.current = [];
    };

    // Nettoyer avant de redessiner
    clearMarkers();
    cleanExistingPolygons();

    path.current.forEach((p, i) => {
      // detect older version
      if (_.size(p.content) > 0) {
        p.mainPanel = p.content;
        if (_.size(props.settings[i]) === 0)
          props.settings[i] = props.settings[_.size(props.settings) - 1];
        p.setting = props.settings[i];
        p.setting.width = 1;
        p.setting.lng = 2;
        p.setting.interdistance = 0;
        p.setting.share = 0;
        delete p.area;
        delete p.content;
      }
      const mainPolygon = new mapsInstance.Polygon();
      mainPolygon.setPath(p.mainPanel);
      mainPolygon.setOptions({
        strokeColor: "black",
        fillOpacity: 0,
        clickable: true,
      });
      mainPolygon.setMap(mapInstance);
      // Calculer le centre du polygone
      const bounds = new mapsInstance.LatLngBounds();
      p.mainPanel.forEach((point) =>
        bounds.extend(new mapsInstance.LatLng(point.lat, point.lng)),
      );
      const center = bounds.getCenter();
      let maxDistance = 15;
      p.mainPanel.forEach((point) => {
        const distance = mapsInstance.geometry.spherical.computeDistanceBetween(
          center,
          new mapsInstance.LatLng(point.lat, point.lng),
        );
        if (distance > maxDistance) {
          maxDistance = distance;
        }
      });
      const newPoint = mapsInstance.geometry.spherical.computeOffset(
        center,
        maxDistance,
        90, // You can adjust this angle if needed
      );
      //1
      if (props.hasLegend) {
        const label = new mapsInstance.Marker({
          position: newPoint,
          map: mapInstance,
          icon: {
            path: mapsInstance.SymbolPath.CIRCLE,
            scale: 0,
          },
          label: {
            text: `${p.panelCount || _.size(p.panels)} PV : ${(
              (props.yieldSTC / 100) *
              parseFloat(p.zoneArea)
            ).toFixed(2)} kWc inc: ${p.setting.tilt}° ori: ${
              p.setting.orientation
            }°`,
            className: "maplabel",
            fontWeight: "500",
            color: "white",
            fontSize: "13px",
          },
          clickable: false,
        });
        markers.current.push(label);
      }

      if (props.isInResults !== true) {
        mainPolygon.addListener("rightclick", (e) => {
          coordinates.current = [
            mapInstance.center.lat(),
            mapInstance.center.lng(),
          ];
          zoomLevel.current = mapInstance.zoom;

          mainPolygon.setMap(null);
          _.map(polygonList.current, (p, key) => {
            if (p?.mainPolygon === mainPolygon) {
              p.listOfPanels.forEach((panel) => {
                panel.setMap(null);
              });
              delete polygonList.current[key];
            }
          });
          path.current = path.current.filter((path) => path !== p);
          props.removeSurfacePvRow(p.setting.id);
          if (props.isInResults !== true && _.size(props.settings) === 1) {
            props.newSurfacePvRow(randomString.generate(10), {
              width: 1,
              lng: 2,
              tilt: 15,
              orientation: 0,
              interdistance: 0,
              share: 0,
            });
          }
          handleUpdate();
        });
        mainPolygon.addListener("mouseover", (e) => {
          mainPolygon.setOptions({
            strokeColor: "#757373",
            fillOpacity: 0.2,
          });
        });
        mainPolygon.addListener("mouseout", (e) => {
          mainPolygon.setOptions({
            strokeColor: "black",
            fillOpacity: 0,
          });
        });
      }
      mainPolygon.setMap(mapInstance);

      if (props.settings[i].width <= 0 || props.settings[i].lng <= 0) return;

      let result = generatePolygonPv(
        props.settings[i],
        mapsInstance,
        mainPolygon,
        mapInstance,
      );
      p.panelCount = result.panelCount;
      p.zoneArea = result.zoneArea;
      p.setting = props.settings[i];
    });
    handleUpdate();
  }, [props.settings, props.exclusionZone, props.path]);
  const debouncedCaptureComponent = useCallback(
    _.debounce(() => {
      if (componentRef.current === null) {
        return;
      }
      setTimeout(() => {
        html2canvas(componentRef.current, {
          useCORS: true,
          allowTaint: true,
          scale: 1.5,
          backgroundColor: null,
          logging: true,
          onclone: (document) => {
            let watermarks = document.querySelectorAll(".gm-style-iw-c");
            let controls = document.querySelectorAll(
              ".gm-style-cc, .gm-fullscreen-control, .gmnoprint",
            );
            watermarks.forEach((el) => el.remove());
            controls.forEach((el) => el.remove());
            document.body.style.backgroundColor = "white";
          },
          windowWidth: componentRef.current.offsetWidth,
          windowHeight: componentRef.current.offsetHeight,
        })
          .then((canvas) => {
            addPdfElement("Map", { dataUrl: canvas.toDataURL("image/jpeg") });
          })
          .catch((e) => {
            console.error("Capture error:", e);
          });
      }, 2000);
    }, 500),
    [],
  );

  const captureComponent = useCallback(() => {
    if (componentRef.current === null) {
      return;
    }
    // Capture the component and add it to the pdf
    debouncedCaptureComponent();
  }, [debouncedCaptureComponent]);

  const area =
    props.isInResults === false ? useRef(props?.getMaxArea()) : useRef(0);

  useEffect(() => {
    isDraw.current = props.isDraw;
  }, [props.isDraw]);

  useEffect(() => {
    isInResults.current = props.isInResults;
  }, [props.isInResults]);

  useEffect(() => {
    settings.current = props.settings;
  }, [props.settings]);
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    const rootElement = componentRef.current;
    rootElement.addEventListener("contextmenu", handleContextMenu);
    return () => {
      rootElement.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  function generatePolygonPv(size, maps, mainPolygon, map) {
    const width = size.width;
    let listOfPanels = [];
    let zoneArea = 0;
    let areaOfPanel = width * size.lng;
    const length = size.lng * Math.cos((size.tilt * Math.PI) / 180);
    if (width <= 0 || length <= 0) return;

    const bounds = new maps.LatLngBounds();
    const mainPolygonPath = mainPolygon.getPath().getArray();
    mainPolygonPath.forEach((point) => {
      bounds.extend(point);
    });
    // Calculer le centre du bounding box
    const currentCenter = {
      lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
      lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
    };

    let maxDistance = 0;
    for (let i = 0; i < mainPolygonPath.length; i++) {
      for (let j = i + 1; j < mainPolygonPath.length; j++) {
        const distance = maps.geometry.spherical.computeDistanceBetween(
          mainPolygonPath[i],
          mainPolygonPath[j],
        );
        if (distance > maxDistance) {
          maxDistance = distance;
        }
      }
    }
    // Calculer le carré englobant
    const radius = maxDistance / Math.sqrt(2);
    const leftTop = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      315 + Number.parseFloat(size.orientation),
    );
    const rightTop = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      45 + Number.parseFloat(size.orientation),
    );
    const leftBottom = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      225 + Number.parseFloat(size.orientation),
    );
    const rightBottom = maps.geometry.spherical.computeOffset(
      currentCenter,
      radius,
      135 + Number.parseFloat(size.orientation),
    );
    const pathBig = [leftTop, rightTop, rightBottom, leftBottom];
    const polygonBig = new maps.Polygon();
    polygonBig.setOptions({
      fillColor: "green",
      strokeColor: "green",
      clickable: true,
    });

    polygonBig.setPath(pathBig);
    // polygonBig.setMap(map);
    const maxPossibleI = Math.ceil(
      maps.geometry.spherical.computeDistanceBetween(rightTop, rightBottom) /
        size.width,
    );
    let maxPossibleJ =
      maps.geometry.spherical.computeDistanceBetween(rightTop, rightBottom) /
      length;
    // maxPossibleJ = Math.ceil(maxPossibleJ + maxPossibleJ * size.interdistance);
    // return;
    const leftTopLat = leftTop.lat();
    const leftTopLng = leftTop.lng();

    let panelPathList = [];
    let panelPathObject = {};
    let xy1 = maps.geometry.spherical.computeOffset(
      { lng: leftTopLng, lat: leftTopLat },
      length,
      0,
    );
    // { lng: leftTopLng, lat: leftTopLat };
    let xy2 = null;
    let xy3 = null;
    let xy4 = null;
    for (let j = 0; j < maxPossibleJ; j++) {
      xy1 = maps.geometry.spherical.computeOffset(
        { lat: leftTopLat, lng: leftTopLng },
        length * j + size.interdistance * j,
        180 + Number.parseFloat(size.orientation),
      );

      for (let i = 0; i < maxPossibleI; i++) {
        xy2 = maps.geometry.spherical.computeOffset(
          { lng: xy1.lng(), lat: xy1.lat() },
          width,
          90 + Number.parseFloat(size.orientation),
        );

        xy3 = maps.geometry.spherical.computeOffset(
          { lng: xy2.lng(), lat: xy2.lat() },
          length,
          180 + Number.parseFloat(size.orientation),
        );

        xy4 = maps.geometry.spherical.computeOffset(
          { lng: xy3.lng(), lat: xy3.lat() },
          width,
          270 + Number.parseFloat(size.orientation),
        );

        const mainPanelPath = [
          { lng: xy1.lng(), lat: xy1.lat() },
          { lng: xy2.lng(), lat: xy2.lat() },
          { lng: xy3.lng(), lat: xy3.lat() },
          { lng: xy4.lng(), lat: xy4.lat() },
        ];
        xy1 = xy2;
        const polygon = new maps.Polygon();
        polygon.setPath(mainPanelPath);
        polygon.setOptions({
          fillColor: "#000099",
          strokeColor: "white",
          strokeWeight: 0.4,
          fillOpacity: 0.6,
          clickable: true,
          zIndex: 5,
        });
        if (
          polygonIsOnAnotherPolygon(polygon, mainPolygon, maps) === true &&
          polygonIsOnExclusionZone(polygon, exclusionZone.current, maps) ===
            false
        ) {
          polygon.setMap(map);
          if (props.isInResults !== true) {
            polygon.addListener("rightclick", (e) => {
              // Récupérer le chemin du panneau
              const originalPath = polygon.getPath().getArray();

              // Calculer le centre du panneau
              const bounds = new maps.LatLngBounds();
              originalPath.forEach((point) => bounds.extend(point));
              const center = bounds.getCenter();

              // Réduire la taille de 1% en créant un nouveau chemin
              const panelPath = originalPath.map((point) => {
                // Calculer le vecteur du centre vers le point
                const heading = maps.geometry.spherical.computeHeading(
                  center,
                  point,
                );
                const distance = maps.geometry.spherical.computeDistanceBetween(
                  center,
                  point,
                );

                // Réduire la distance de 1%
                const newDistance = distance * 0.99;

                // Calculer le nouveau point
                const newPoint = maps.geometry.spherical.computeOffset(
                  center,
                  newDistance,
                  heading,
                );

                return {
                  lat: newPoint.lat(),
                  lng: newPoint.lng(),
                };
              });

              // Ajouter le panneau comme zone d'exclusion
              exclusionZone.current.push(panelPath);

              // Créer un nouveau polygone d'exclusion
              const exclusionPolygon = new maps.Polygon({
                paths: panelPath,
                fillColor: "red",
                strokeColor: "red",
                strokeWeight: 0.4,
                fillOpacity: 0.4,
                clickable: true,
                zIndex: 999,
              });

              // Afficher le polygone d'exclusion
              exclusionPolygon.setMap(map);

              // Supprimer le panneau
              polygon.setMap(null);
              if (props.isInResults !== true) {
                // Ajouter un gestionnaire de clic droit pour supprimer la zone d'exclusion
                exclusionPolygon.addListener("rightclick", (e) => {
                  exclusionPolygon.setMap(null);
                  exclusionZone.current = exclusionZone.current.filter(
                    (zone) => !_.isEqual(zone, panelPath),
                  );
                  handleUpdate();
                  // setRefresh(!refresh);
                });
              }

              // Mettre à jour l'affichage
              // setRefresh(!refresh);
              handleUpdate();
            });
            polygon.addListener("mouseover", (e) => {
              polygon.setOptions({
                fillColor: "blue",
                strokeColor: "blue",
                strokeWeight: 0.4,
              });
            });
            polygon.addListener("mouseout", (e) => {
              polygon.setOptions({
                fillColor: "#000099",
                strokeColor: "white",
                strokeWeight: 0.4,
              });
            });
          }
          zoneArea = zoneArea + areaOfPanel;
          panelPathList.push(mainPanelPath);
          listOfPanels.push(polygon);
        }
      }
    }
    for (let i = 0; i < panelPathList.length; i++) {
      panelPathObject[i] = panelPathList[i];
    }
    polygonList.current.push({ listOfPanels, mainPolygon });
    return { zoneArea, panelCount: panelPathList.length };
  }

  function polygonIsOnAnotherPolygon(polygon, polygonBig, maps) {
    // Obtenir les points du polygone à vérifier
    const polygonPath = polygon.getPath().getArray();

    // Vérifier que tous les sommets sont à l'intérieur du grand polygone
    for (let vertex of polygonPath) {
      if (!maps.geometry.poly.containsLocation(vertex, polygonBig)) {
        return false;
      }
    }

    // Vérifier des points supplémentaires le long des bords du polygone
    for (let i = 0; i < polygonPath.length; i++) {
      const p1 = polygonPath[i];
      const p2 = polygonPath[(i + 1) % polygonPath.length];

      // Vérifier 5 points intermédiaires sur chaque bord
      for (let j = 1; j < 5; j++) {
        const fraction = j / 5;
        const intermediatePoint = new maps.LatLng(
          p1.lat() + (p2.lat() - p1.lat()) * fraction,
          p1.lng() + (p2.lng() - p1.lng()) * fraction,
        );

        if (
          !maps.geometry.poly.containsLocation(intermediatePoint, polygonBig)
        ) {
          return false;
        }
      }
    }

    // Vérifier le centre du polygone
    const bounds = new maps.LatLngBounds();
    polygonPath.forEach((point) => bounds.extend(point));
    const center = bounds.getCenter();
    if (!maps.geometry.poly.containsLocation(center, polygonBig)) {
      return false;
    }

    return true;
  }

  function polygonIsOnExclusionZone(polygon, exclusionZones, maps) {
    // Helper function to get polygon bounds
    const getPolygonBounds = (path) => {
      const bounds = new maps.LatLngBounds();
      path.forEach((point) => bounds.extend(point));
      return bounds;
    };

    // Helper function to check if line segments intersect
    const doLineSegmentsIntersect = (p1, p2, p3, p4) => {
      const ccw = (A, B, C) => {
        return (
          (C.lat() - A.lat()) * (B.lng() - A.lng()) >
          (B.lat() - A.lat()) * (C.lng() - A.lng())
        );
      };
      return (
        ccw(p1, p3, p4) !== ccw(p2, p3, p4) &&
        ccw(p1, p2, p3) !== ccw(p1, p2, p4)
      );
    };

    return exclusionZones.some((exclusionZone) => {
      const exclusionPolygon = new maps.Polygon({ paths: exclusionZone });
      const polygonPath = polygon.getPath().getArray();
      const exclusionPath = exclusionPolygon.getPath().getArray();

      // Quick check with bounding boxes first
      const polygonBounds = getPolygonBounds(polygonPath);
      const exclusionBounds = getPolygonBounds(exclusionPath);

      if (!polygonBounds.intersects(exclusionBounds)) {
        return false;
      }

      // Check if any vertex of one polygon is inside the other
      for (let point of polygonPath) {
        if (maps.geometry.poly.containsLocation(point, exclusionPolygon)) {
          return true;
        }
      }

      for (let point of exclusionPath) {
        if (maps.geometry.poly.containsLocation(point, polygon)) {
          return true;
        }
      }

      // Check if any edges intersect
      for (let i = 0; i < polygonPath.length; i++) {
        const p1 = polygonPath[i];
        const p2 = polygonPath[(i + 1) % polygonPath.length];

        for (let j = 0; j < exclusionPath.length; j++) {
          const p3 = exclusionPath[j];
          const p4 = exclusionPath[(j + 1) % exclusionPath.length];

          if (doLineSegmentsIntersect(p1, p2, p3, p4)) {
            return true;
          }
        }
      }

      return false;
    });
  }

  const handleChange = useCallback(
    (map) => {
      if (isInResults.current) {
        coordinates.current = [map.center.lat, map.center.lng];
        captureComponent();
      } else {
        coordinates.current = [map.center.lat, map.center.lng];
        zoomLevel.current = map.zoom;
        props.setCoordinates([map.center.lat, map.center.lng]);
      }
    },
    [isInResults, props.setCoordinates],
  );

  const handleUpdate = async () => {
    let newArea = 0;
    _.map(path.current, (p) => {
      newArea = newArea + p.zoneArea;
    });
    area.current = newArea;
    if (props.isCustomArea === false) {
      // Calculer les shares comme pourcentage de la surface totale
      let totalArea = area.current;
      if (totalArea > 0) {
        // Calculer les shares comme pourcentages exacts
        path.current.forEach((p) => {
          p.setting.share = (p.zoneArea / totalArea) * 100;
        });
      }

      return props.triggerCompute(
        path.current,
        area.current,
        exclusionZone.current,
      );
    } else {
      return props.triggerCompute(
        path.current,
        props.getMaxArea(),
        exclusionZone.current,
      );
    }
  };
  const handlePolygonComplete = (polygon, maps, map) => {
    coordinates.current = [map.center.lat(), map.center.lng()];
    zoomLevel.current = map.zoom;
    const pathPolygon = polygon.getPath();
    const newPath = pathPolygon.getArray().map((e) => ({
      lat: e.lat(),
      lng: e.lng(),
    }));
    const lastSetting = _.findLastKey(settings.current, (setting) => {
      return setting;
    });

    if (_.size(path.current) > 0) {
      const newSetting = {
        width: settings.current[lastSetting].width,
        lng: settings.current[lastSetting].lng,
        tilt: settings.current[lastSetting].tilt,
        orientation: settings.current[lastSetting].orientation,
        interdistance: settings.current[lastSetting].interdistance,
        share: settings.current[lastSetting].share,
      };
      props.newSurfacePvRow(settings.current[lastSetting].id, newSetting);
    }

    const roof = {
      setting: settings.current[lastSetting],
      mainPanel: newPath,
      zoneArea: 0,
      panelCount: 0,
    };
    generatePolygonPv(settings.current[lastSetting], maps, polygon, map);
    path.current = [...path.current, roof];
  };

  const showMap = async (map, maps) => {
    if (props.isInResults !== true && _.size(props.settings) === 0) {
      props.newSurfacePvRow(randomString.generate(10), {
        width: 1,
        lng: 2,
        tilt: 15,
        orientation: 0,
        interdistance: 0,
        share: 0,
      });
    }
    if (path.current.length > 0 && props.isInResults !== true) {
      path.current.forEach((p, i) => {
        // detect older version
        if (_.size(p.content) > 0) {
          p.mainPanel = p.content;
          if (_.size(props.settings[i]) === 0)
            props.settings[i] = props.settings[_.size(props.settings) - 1];
          p.setting = props.settings[i];
          p.setting.width = 1;
          p.setting.lng = 2;
          p.setting.interdistance = 0;
          p.setting.share = 0;
          delete p.area;
          delete p.content;
        }
        const mainPolygon = new maps.Polygon();
        mainPolygon.setPath(p.mainPanel);
        mainPolygon.setOptions({
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        });
        mainPolygon.setMap(map);
        // Calculer le centre du polygone
        const bounds = new maps.LatLngBounds();
        p.mainPanel.forEach((point) =>
          bounds.extend(new maps.LatLng(point.lat, point.lng)),
        );
        const center = bounds.getCenter();
        let maxDistance = 15;
        p.mainPanel.forEach((point) => {
          const distance = maps.geometry.spherical.computeDistanceBetween(
            center,
            new maps.LatLng(point.lat, point.lng),
          );
          if (distance > maxDistance) {
            maxDistance = distance;
          }
        });
        const newPoint = maps.geometry.spherical.computeOffset(
          center,
          maxDistance,
          90, // You can adjust this angle if needed
        );
        //1
        if (props.hasLegend) {
          const label = new maps.Marker({
            position: newPoint,
            map,
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 0,
            },
            label: {
              text: `${p.panelCount || _.size(p.panels)} PV : ${(
                (props.yieldSTC / 100) *
                parseFloat(p.zoneArea)
              ).toFixed(2)} kWc inc: ${p.setting.tilt}° ori: ${
                p.setting.orientation
              }°`,
              className: "maplabel",
              fontWeight: "500",
              color: "white",
              fontSize: "13px",
            },
            clickable: false,
          });
          markers.current.push(label);
        }

        mainPolygon.addListener("rightclick", (e) => {
          coordinates.current = [map.center.lat(), map.center.lng()];
          zoomLevel.current = map.zoom;

          mainPolygon.setMap(null);
          _.map(polygonList.current, (p, key) => {
            if (p?.mainPolygon === mainPolygon) {
              p.listOfPanels.forEach((panel) => {
                panel.setMap(null);
              });
              delete polygonList.current[key];
            }
          });
          path.current = path.current.filter((path) => path !== p);
          props.removeSurfacePvRow(p.setting.id);
          handleUpdate();
        });
        if (props.isInResults !== true) {
          mainPolygon.addListener("mouseover", (e) => {
            mainPolygon.setOptions({
              strokeColor: "#757373",
              fillOpacity: 0.2,
            });
          });
          mainPolygon.addListener("mouseout", (e) => {
            mainPolygon.setOptions({
              strokeColor: "black",
              fillOpacity: 0,
            });
          });
        }
        mainPolygon.setMap(map);

        if (
          _.isEqual(p.setting, props.settings[i]) === false ||
          _.isEqual(p.setting, props.settings[i]) === true
        ) {
          if (props.settings[i].width <= 0 || props.settings[i].lng <= 0)
            return;

          let result = generatePolygonPv(
            props.settings[i],
            maps,
            mainPolygon,
            map,
          );
          p.panelCount = result.panelCount;
          p.zoneArea = result.zoneArea;
          p.setting = props.settings[i];
        }
      });
      handleUpdate();
    }
    if (props.isInResults === true || props.isInDefaultScenario === true) {
      path.current.forEach((p, i) => {
        const mainPolygon = new maps.Polygon();
        mainPolygon.setPath(p.mainPanel);
        mainPolygon.setOptions({
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        });
        mainPolygon.setMap(map);
        generatePolygonPv(p.setting, maps, mainPolygon, map);
        const bounds = new maps.LatLngBounds();
        p.mainPanel.forEach((point) =>
          bounds.extend(new maps.LatLng(point.lat, point.lng)),
        );
        const center = bounds.getCenter();
        let maxDistance = 15;
        p.mainPanel.forEach((point) => {
          const distance = maps.geometry.spherical.computeDistanceBetween(
            center,
            new maps.LatLng(point.lat, point.lng),
          );
          if (distance > maxDistance) {
            maxDistance = distance;
          }
        });
        const newPoint = maps.geometry.spherical.computeOffset(
          center,
          maxDistance,
          90, // You can adjust this angle if needed
        );

        if (props.hasLegend) {
          const label = new maps.Marker({
            position: newPoint,
            map: map,
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 0,
            },
            label: {
              text: `${p.panelCount} PV : ${(
                (props.yieldSTC / 100) *
                parseFloat(p.zoneArea)
              ).toFixed(2)} kWc inc: ${p.setting.tilt}° ori: ${
                p.setting.orientation
              }°`,
              className: "maplabel",
              fontWeight: "500",
              color: "white",
              fontSize: "13px",
            },
            clickable: false,
          });
          markers.current.push(label);
        }
      });
    }

    if (exclusionZone.current.length > 0 && props.isInResults !== true) {
      exclusionZone.current.forEach((exclusion) => {
        const exclusionPolygon = new maps.Polygon();
        exclusionPolygon.setPath(exclusion);
        exclusionPolygon.setOptions({
          fillColor: "red",
          strokeColor: "red",
          strokeWeight: 0.4,
          fillOpacity: 0.4,
          clickable: true,
          zIndex: 999,
        });
        exclusionPolygon.zIndex = 999;
        exclusionPolygon.setMap(map);
        if (props.isInResults !== true) {
          exclusionPolygon.addListener("rightclick", (e) => {
            exclusionPolygon.setMap(null);
            exclusionZone.current = exclusionZone.current.filter(
              (zone) => zone !== exclusion,
            );
            handleUpdate();

            // setRefresh(!refresh);
          });

          exclusionPolygon.addListener("mouseover", (e) => {
            exclusionPolygon.setOptions({
              fillColor: "blue",
              strokeColor: "blue",
              strokeWeight: 0.4,
              fillOpacity: 0.4,
            });
          });
          exclusionPolygon.addListener("mouseout", (e) => {
            exclusionPolygon.setOptions({
              fillColor: "red",
              strokeColor: "red",
              strokeWeight: 0.4,
              fillOpacity: 0.4,
            });
          });
        }
      });
    }
    // to draw new polygons
    if (isDraw.current) {
      if (!maps.drawing) return;

      // Drawing manager for PV panels
      let drawPv = new maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: ["polygon"],
          position: maps.ControlPosition.TOP_LEFT,
          drawingControl: false, // Hide default controls
        },
        drawingControl: false,

        drawingMode: "polygon",
        polygonOptions: {
          strokeColor: "black",
          fillOpacity: 0,
          clickable: true,
        },
      });

      // Drawing manager for exclusion zones
      let drawExclusion = new maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: ["polygon"],
          position: maps.ControlPosition.TOP_LEFT,
          drawingControl: false, // Hide default controls
        },
        drawingControl: false,

        polygonOptions: {
          strokeColor: "red",
          fillColor: "red",
          fillOpacity: 0.4,
          clickable: true,
          zIndex: 500,
        },
      });

      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates.current[0], lng: coordinates.current[1] },
        visible: false,
        map,
        className: "marker",
      });

      map.setTilt(0);
      marker.setMap(map);
      map.setZoom(zoomLevel.current);

      // Handle PV panel polygon completion
      maps.event.addListener(drawPv, "polygoncomplete", (polygon) => {
        coordinates.current = [map.center.lat(), map.center.lng()];
        zoomLevel.current = map.zoom;
        drawPv.setDrawingMode(null);
        handlePolygonComplete(polygon, maps, map);
        polygon.setMap(map);
        drawPv.setDrawingMode("polygon");
        handleUpdate();
        // setRefresh(!refresh);
      });

      // Handle exclusion zone polygon completion
      maps.event.addListener(drawExclusion, "polygoncomplete", (polygon) => {
        const path = polygon.getPath();

        polygon.setMap(map);
        polygon.setOptions({
          zIndex: 500,
        });
        const exclusionPath = path.getArray().map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));
        if (props.isInResults !== true) {
          polygon.addListener("rightclick", () => {
            polygon.setMap(null);
            exclusionZone.current = exclusionZone.current.filter(
              (zone) => zone !== exclusionPath,
            );
            handleUpdate();
            // setRefresh(!refresh);
          });
        }
        exclusionZone.current.push(exclusionPath);
        handleUpdate();
        // setRefresh(!refresh);
      });

      drawPv.setMap(map);
      drawExclusion.setMap(map);

      // Create container for buttons
      const buttonContainer = document.createElement("div");
      buttonContainer.className = "custom-control";
      buttonContainer.style.margin = "10px";

      // Create buttons with icons and tooltips
      const mainButton = document.createElement("button");
      mainButton.innerHTML = `<img height="20px" width="20px" src="./icons/icon_main.svg"></img>`;
      mainButton.className = "custom-control-button";
      mainButton.title = "Se déplacer";

      const pvButton = document.createElement("button");
      pvButton.innerHTML = `<img height="20px" width="20px" src="./icons/TbPolygon.svg"></img>`;
      pvButton.className = "custom-control-button-center";
      pvButton.title = "Déssiner une zone PV";

      const exclusionButton = document.createElement("button");
      exclusionButton.innerHTML = `<img height="20px" width="20px" src="./icons/TbPolygonOff.svg"></img>`;
      exclusionButton.className = "custom-control-button-right";
      exclusionButton.title = "Déssiner une zone d'exclusion";

      // Add click handlers
      mainButton.addEventListener("click", () => {
        handleChangeDrawMode(1, drawPv, drawExclusion, map);
        mainButton.classList.add("active");
        pvButton.classList.remove("active");
        exclusionButton.classList.remove("active");
      });

      pvButton.addEventListener("click", () => {
        handleChangeDrawMode(2, drawPv, drawExclusion, map);
        mainButton.classList.remove("active");
        pvButton.classList.add("active");
        exclusionButton.classList.remove("active");
      });

      exclusionButton.addEventListener("click", () => {
        handleChangeDrawMode(3, drawPv, drawExclusion, map);
        mainButton.classList.remove("active");
        pvButton.classList.remove("active");
        exclusionButton.classList.add("active");
      });

      if (currentDrawingMode.current === 1) {
        mainButton.classList.add("active");
      } else if (currentDrawingMode.current === 2) {
        pvButton.classList.add("active");
      } else if (currentDrawingMode.current === 3) {
        exclusionButton.classList.add("active");
      }
      // Add buttons to container
      buttonContainer.appendChild(mainButton);
      buttonContainer.appendChild(pvButton);
      buttonContainer.appendChild(exclusionButton);

      // Add container to map
      handleChangeDrawMode(
        currentDrawingMode.current,
        drawPv,
        drawExclusion,
        map,
      );
      map.controls[maps.ControlPosition.TOP_LEFT].push(buttonContainer);
    } else {
      let marker = new maps.Marker({
        animation: maps.Animation.DROP,
        clickable: false,
        position: { lat: coordinates.current[0], lng: coordinates.current[1] },
        visible: true,
        map,
        className: "marker",
        draggable: true,
      });

      map.setCenter({
        lat: coordinates.current[0],
        lng: coordinates.current[1],
      });
      map.setZoom(zoomLevel.current);
      map.setTilt(0);

      marker.addListener("mouseup", (e) => {
        coordinates.current = [map.center.lat(), map.center.lng()];
        zoomLevel.current = map.zoom;
        let latLng = marker.getPosition();
        mapSetting.current = {
          lat: latLng.lat(),
          lng: latLng.lng(),
        };
        map.setCenter({ lat: latLng.lat(), lng: latLng.lng() });
        props.getCodeFromMarker({ lat: latLng.lat(), lng: latLng.lng() });
      });
      marker.setMap(map);
    }
  };
  const handleChangeDrawMode = (value, drawPv, drawExclusion, map) => {
    switch (value) {
      case 1:
        drawPv.setMap(null);
        drawExclusion.setMap(null);
        break;
      case 2:
        drawExclusion.setMap(null);
        drawPv.setMap(map);
        drawPv.setDrawingMode("polygon");
        break;
      case 3:
        drawPv.setMap(null);
        drawExclusion.setMap(map);
        drawExclusion.setDrawingMode("polygon");
        break;
    }
    currentDrawingMode.current = value;
  };
  return (
    <div
      className={`${isInResults.current ? "results-map" : ""}`}
      style={{
        height: isInResults.current ? "450px" : "360px",
        width: isInResults.current ? "80%" : "100%",
        margin: "auto",
      }}
      ref={componentRef}
    >
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={{ lat: 46.616545, lng: 2.728726 }}
        defaultZoom={zoomLevel.current}
        center={{ lat: coordinates.current[0], lng: coordinates.current[1] }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          if (componentRef.current) {
            componentRef.current.gmapInstance = map;
          }
          if (props.isInResults === true)
            map.addListener("tilesloaded", () => {
              console.log("Tiles loaded, capturing component");
              captureComponent();
            });
          setMapInstance(map);
          setMapsInstance(maps);
          showMap(map, maps);
        }}
        options={OPTIONS}
        key={key}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
});
Map.displayName = "Map";
export default Map;
