import ConsumerConstants from "../constants/Scenario";
import SupplierConstants from "../constants/SupplyScenario";
import ScenarioService from "../services/Scenario";
import DatabaseConstants from "../constants/Database";
import { db } from "../fire";
import SiteConstants from "../constants/Site";
import Api from "../services/Api";

export const isScenarioOutdated = (scenario, site) => {
  const { type } = site;

  if (!scenario.version) return true;
  if (type === "CONSUMER") {
    if (scenario.version < ConsumerConstants.version) {
      return true;
    }
  } else {
    if (scenario.version < SupplierConstants.version) {
      return true;
    }
  }
  return false;
};

export const areResultsOutdated = (scenario, site) => {
  const { type } = site;

  if (!scenario.optimizationResults.version) return true;
  if (type === "CONSUMER") {
    if (scenario.optimizationResults.version < ConsumerConstants.version) {
      return true;
    }
  } else {
    if (scenario.optimizationResults.version < SupplierConstants.version) {
      return true;
    }
  }
  return false;
};

export const updateBatchOfScenarios = async (scenarios, siteToOptimize) => {
  const batch = db.batch();
  await Promise.all(
    scenarios.map(async (scenario) => {
      const scenarioRef = db
        .collection(DatabaseConstants.COLLECTION_SCENARIOS)
        .doc(scenario.id);
      let optim_params = null;
      let version = null;

      optim_params = await Api.buildOptimParams(siteToOptimize, scenario);
      console.log("OPTIM", { optim_params: optim_params.data });
      if (siteToOptimize.type === SiteConstants.type.CONSUMER) {
        version = ConsumerConstants.version;
      } else {
        version = SupplierConstants.version;
      }
      batch.update(scenarioRef, { optim_params: optim_params.data, version });
    }),
  );

  return batch.commit();
};

export const updateScenario = (scenario, payload, i) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      console.log("Updating... ");
      await ScenarioService.update(scenario.id, payload, false);
      resolve();
    }, i * 300);
  });
};

export const getTechnologies = (type, targetedScenario, solutions) => {
  let technologies = [];
  const isEitherConsumerOrConsumerPlus = [
    SiteConstants.type.CONSUMER,
    SiteConstants.type.CONSUMER_PLUS,
  ].includes(type);
  const SCENARIO = isEitherConsumerOrConsumerPlus
    ? ConsumerConstants
    : SupplierConstants;
  /** Pushing Grid before other techs */
  // if (
  //   targetedScenario.technologies.find(
  //     (t) => t.type === SCENARIO.techTypes.NETWORK.id
  //   )
  // )
  if (isEitherConsumerOrConsumerPlus) {
    technologies.push({
      data: solutions["Grid"],
      name: "Grid",
    });
    if (
      targetedScenario.technologies.find(
        (t) => t.type === SCENARIO.techTypes.STATION.id,
      )
    ) {
      const stations = targetedScenario.technologies.find(
        (t) => t.type === SCENARIO.techTypes.STATION.id,
      );
      stations.settings.stationTypes.forEach((stationType, index) => {
        technologies.push({
          data: stationType,
          name: `Bornes ${index + 1}`,
        });
      });
    }
    if (
      targetedScenario.technologies.find(
        (t) => t.type === SCENARIO.techTypes.VIRTUAL.id,
      )
    )
      technologies.push({
        data: solutions["VS"],
        name: "VS",
      });
  }
  if (
    targetedScenario.technologies.find(
      (t) => t.type === SCENARIO.techTypes.FUEL.id,
    )
  )
    technologies.push({
      data: solutions["FuelCell"],
      name: "FuelCell",
    });
  if (
    targetedScenario.technologies.find(
      (t) => t.type === SCENARIO.techTypes.ELEC_GROUP.id,
    )
  )
    technologies.push({
      data: solutions["Gen"],
      name: "Gen",
    });

  /** Merging conversion and Battery as one tech */

  if (
    targetedScenario.technologies.find(
      (t) => t.type === SCENARIO.techTypes.BATTERY.id,
    )
  )
    technologies.push({
      data: {
        Battery: { ...solutions["Battery"] },
        Conversion: { ...solutions["Conversion"] },
      },
      name: "Battery",
    });
  /** Merging electrolyse and H2 storage as one tech */

  if (
    targetedScenario.technologies.find(
      (t) => t.type === SCENARIO.techTypes.ELECTROLYSIS.id,
    )
  )
    technologies.push({
      data: {
        Electrolyse: { ...solutions["Electrolyse"] },
        H2Storage: { ...solutions["H2Storage"] },
      },
      name: "Electrolyse",
    });

  /** Merging electrolyse and H2 storage as one tech */

  if (
    targetedScenario.technologies.find(
      (t) => t.type === SCENARIO.techTypes.ECS.id,
    )
  )
    technologies.push({
      data: {
        ECS: { ...solutions["ECS"] },
        ThermalStorage: { ...solutions["ThermalStorage"] },
      },
      name: "ECS",
    });

  for (let tech in SCENARIO.techTypes) {
    /** For PV, PV2 and wind tubrine */
    if (
      ![
        "STATION",
        "VIRTUAL",
        "ELEC_GROUP",
        "FUEL",
        "ELECTROLYSIS",
        "ECS",
        "NETWORK",
        "BATTERY",
      ].includes(tech) &&
      targetedScenario.technologies.find(
        (t) => t.type === SCENARIO.techTypes[tech].id,
      )
    ) {
      let name = SCENARIO.techTypes[tech].techPrefix.toUpperCase();
      technologies.push({
        data: solutions[name],
        name,
      });
    }
  }
  return technologies;
};

export const getSupplierGaugeHeaders = (tech) => {
  let result = [];
  switch (tech.name) {
    case "PV":
      result = ["Vente PV - PPA", "Vente PV - SPOT", "clipped"];
      break;
    case "WT":
      result = ["Vente Eolien - PPA", "Vente Eolien - SPOT", "clipped"];
      break;
    default:
      break;
  }
  return result;
};

export const getColorsByTech = (tech, type) => {
  if (type === SiteConstants.type.CONSUMER) {
    switch (tech.name) {
      //SelfConsumed - Sold - curtailment - stored - energyLoss
      case "PV":
      case "PV2":
        return ["#FFE119", "#2ECC71", "#e5e5e5", "#82aaff", "#a2f3fc"];

      case "WT":
        return ["#277da7", "#13dbbf", "#e5e5e5", "#82aaff", "#a2f3fc"];

      default:
        break;
    }
  } else if (type === SiteConstants.type.CONSUMER_PLUS) {
    switch (tech.name) {
      // Sold - curtailment
      case "PV":
      case "PV2":
        return ["#2ECC71", "#e5e5e5"];

      case "WT":
        return ["#13dbbf", "#e5e5e5"];

      default:
        break;
    }
  }
  switch (tech.name) {
    //PPA - SPOT - curtailment
    case "PV":
    case "PV2":
      return ["#1f8048", "#2ECC71", "#e5e5e5"];

    case "WT":
      return ["#277da7", "#13dbbf", "#e5e5e5"];

    default:
      break;
  }
};
