import { TUTORIALS } from "./Tutorials";

const newLine = "<br><br>";
const PAGE_LIST = {
  HOME: "/",
  ANALYZE: "/analyze",
  OPTIMIZATION: "/optimization",
  RESULTS: "/results",
  ACCOUNT: "/me",
};

const TUTORIAL_VALUES = (page, url) => {
  const PDF_TAG = `<span style="${{
    fontWeight: "bold",
  }}"><a target="_blank" rel="noopener noreferrer" href="${url}">notre manuel d'utilisation au format pdf</a></span>`;
  switch (page) {
    case PAGE_LIST.HOME:
      return [
        {
          element: "#analyze-header",
          intro:
            "Cliquez ici pour accéder à l’analyse de la consommation actuelle de vos projets" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },

        {
          element: "#scenarios",
          intro:
            "Cliquez ici pour créer les scénarios d’autoconsommation à optimiser sur chacun de vos projets" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#results",
          intro:
            "Cliquez ici pour accéder aux résultats des scénarios déjà calculés pour chacun de vos projets" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#my-account",
          intro:
            "Cliquez ici pour accéder à vos options de personnalisation (logo, paramétrage par défaut, ...) et à vos projets masqués." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case PAGE_LIST.ANALYZE:
      return [
        {
          element: ".site-to-analyze",
          intro:
            "Sélectionnez votre projet dans cette liste pour lancer l'analyse de son historique de consommation." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#scenarios",
          intro:
            "Une fois l’analyse terminée, cliquez ici pour créer les scénarios d’autoconsommation à optimiser sur votre projet." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case PAGE_LIST.OPTIMIZATION:
      return [
        {
          element: ".site-to-analyze",
          intro:
            "Sélectionnez votre projet dans cette liste pour créer ses scénarios d’autoconsommation à optimiser" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".dropdown-trigger",
          intro:
            "Cliquez sur ce bouton afin de créer un nouveau scénario pour votre projet si ce n'est pas déjà fait." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".elsAPa",
          intro:
            "Si votre projet possède déjà un scénario, vous pouvez à tout moment le modifier en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".gJgWIV",
          intro:
            "Vous pouvez dupliquer un scénario en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".cTduRZ",
          intro:
            "Vous pouvez supprimer un scénario en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".ePIZPS",
          intro:
            "Vous pouvez télécharger toutes les hypothèses d'un scénario au format JSON en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".control",
          intro:
            "Une fois que vous avez créez ou modifiez un scénario, sélectionnez le en cliquant ici." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".calculate",
          intro:
            "Une fois que votre projet a un scénario attribué, vous pouvez calculer les résultats d'un scénario en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".results",
          intro:
            "Si vous avez déjà effectué les calculs du scénario sélectionné, vous n'avez pas besoin de relancer les calculs. Cliquez directement sur ce bouton pour afficher les résultats." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".is-info",
          intro:
            "Retournez directement sur la page d'accueil en cliquant sur ce bouton." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".tech",
          intro:
            "Cliquez ici pour ajouter une technologie de production d’ENR ou de stockage. Cliquez ensuite sur son symbole pour la configurer." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".tech-4",
          intro:
            "Cliquez ici pour configurer l’inflation des prix de l’électricité et les puissances souscrites" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".general-settings",
          intro:
            "Cliquer ici pour modéliser un emprunt, le taux d’actualisation ou l’imposition des gains." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".objective-editor",
          intro:
            "Rajouter des contraintes techniques ou environnementales à respecter ou laissez à 0 pour optimiser d’un point de vue économique uniquement." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".gMEhTS",
          intro:
            "Cliquez ici pour sauvegarder vos modifications." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case PAGE_LIST.RESULTS:
      return [
        {
          element: ".site-to-analyze",
          intro:
            "Sélectionnez votre projet dans cette liste pour afficher les résultats de ses scénarios déjà calculés." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".site-in-scenario-table",
          intro:
            "Cliquez sur le nom du scénario dans ce tableau pour afficher les détails du dimensionnement, des couts, de fonctionnement horaire... et télécharger ses fichiers." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case PAGE_LIST.ACCOUNT:
      return [
        {
          element: ".termsOfService",
          intro:
            "Cliquez ici pour accéder à notre politique concernant la vie privée." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".myContactDetails",
          intro:
            "Cliquez ici pour modifier vos informations personnelles." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".fqQORE",
          intro:
            "Cliquez ici pour modifier vos informations de connexion." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".plan",
          intro:
            "Cliquez ici pour modifier votre forfait." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".dHVxpI",
          intro:
            "Cliquez ici pour modifier votre logo et vos préférences linguistiques." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".sites",
          intro:
            "Cliquez ici pour afficher la liste de vos projets, actifs sou inactifs." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".defaultScenarios",
          intro:
            "Cliquez ici pour paramétrer vos scénarios types." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: ".defaultRates",
          intro:
            "Cliquez ici pour paramétrer votre structure tarifaire par défaut." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case TUTORIALS.NEW_RESULTS_BUTTONS:
      return [
        {
          element: "#export-button",
          intro:
            "Retrouvez ici tous les fichiers relatifs à votre projet : PDF, Bilan financier, graphiques..." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#reset-layout-button",
          intro:
            "Cliquez ici pour réinitialiser la mise en page des résultats" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case TUTORIALS.NEW_ACC_ACI:
      return [
        {
          element: "#tuto-aci",
          intro:
            "Vous pouvez désormais ajouter un compteur d'autoconsommation individuel dans le cadre d'un scénario d'autoconsommation collectif..." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#tuto-acc",
          intro:
            "Ajouter autant de compteurs ACC que vous le souhaitez" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    case TUTORIALS.NEW_TEMPO:
      return [
        {
          element: "#tuto-tempo",
          intro:
            "Vous pouvez maintenant séléctionner l'option Tempo dans la grille tarifaire..." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#tuto-tempo-prices",
          intro:
            "Ajoutez vos tarifs Bleu, Blanc, Rouge en fonction des heures creuses et des heures pleines" +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
    default:
      return [
        {
          element: "#new-site",
          intro:
            "Vous avez besoin d'aide ? N'hésitez-pas à nous contacter via le chat en bas à gauche de votre écran." +
            newLine +
            `Consultez à tout moment ${PDF_TAG}`,
        },
        {
          element: "#help",
          intro: `Consultez à tout moment ${PDF_TAG}`,
        },
      ];
  }
};
export default TUTORIAL_VALUES;
