import DatabaseConstants from "../constants/Database";
import { db } from "../fire";
import UserService from "./User";
import Auth from "./Auth";
import { getUserData } from "../store/authentication/AuthenticationStore";

const COLLECTION = DatabaseConstants.COLLECTION_DEFAULT_RATES;

/**
 *
 * @param {*} newRate a new rate Object
 * @returns
 */
export const addDefaultRates = async (newRate) => {
  const { isManager, subordinates, defaultRates } = getUserData();
  let newRateRef = await db.collection(COLLECTION).add(newRate);
  newRate.rateId = newRateRef.id;
  newRateRef.set(newRate);
  if (!newRateRef) return Promise.reject({ message: "Could not save rate" });
  try {
    await UserService.update(Auth.getUserId(), {
      defaultRates: [...defaultRates, newRate.rateId],
    });
  } catch (e) {
    return Promise.reject({ message: "Could not update user rate", e });
  }
  if (isManager) {
    subordinates.map(async (subordinateId) => {
      try {
        UserService.update(subordinateId, {
          defaultRates: [...defaultRates, newRate.rateId],
        });
      } catch {
        console.log("Could not find subordinate ", subordinateId);
      }
    });
  }
  return Promise.resolve(newRate);
};

export const getDefaultRate = async (rateId) =>
  db
    .collection(COLLECTION)
    .doc(rateId)
    .get()
    .then((doc) => (doc.exists ? { id: doc.id, ...doc.data() } : null));

export const deleteDefaultRate = async (rateId) => {
  await db.collection(COLLECTION).doc(rateId).delete();
  //Removing defaultRate from users
  const users = await UserService.findUsersWithDefaultRates(rateId);
  console.log({ users });
  console.log(`Found ${users.length} users with defaultRate`);
  return await Promise.all(
    users.map(async (user) =>
      UserService.update(user.uid, {
        defaultRates: user.defaultRates.filter((rate) => rate !== rateId),
      }),
    ),
  );
};

export const updateDefaultRate = async (rateId, payload) => {
  const defaultRateRef = db.collection(COLLECTION).doc(rateId);
  return defaultRateRef.update(payload);
};
