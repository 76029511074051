import React from "react";

import LIKEWATT from "../../assets/img/likewatt-blanc-short.png";

import styled, { useTheme } from "styled-components";
import { Square } from "../../assets/style/commonStyledComponent";

const Step = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Title = styled.div`
  color: ${(props) => props.theme.mainText || "white"};
  font-weight: 500;
  padding: 10px 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  max-width: 760px;
  margin: 0 auto;
`;

const Secondary = styled.div`
  color: ${(props) => props.theme.mainText || "white"};
  padding: 1em;
  padding-top: 0;
  padding-bottom: 10px;
  line-height: normal;
`;
const Emphasis = styled.span`
  font-weight: 500;
  &.underlined {
    text-decoration: underline ${(props) => props.theme.second} 3px;
    text-underline-offset: 5px;
  }
`;

export const ImgStyled = styled.img`
  max-width: ${(props) => props.theme.logoSize || "25%"};
  height: auto;
  margin: ${(props) => props.theme.logoMargin || "5%"} auto;
`;

export const TextInfo = styled.span`
  color: ${(props) => props.theme.mainText || "white"};
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5% 1%;
  font-size: 14px;
`;

export const Step1 = () => {
  const theme = useTheme();

  return (
    <Step id="step-1">
      <Square style={{ justifyContent: "center", gap: "5%" }}>
        <Title>
          Bienvenue sur le simulateur de votre
          <br />
          projet d'autoconsommation photovoltaïque
        </Title>
        <ImgStyled src={theme.logo || LIKEWATT} alt="Logo LIKEWATT" />

        <Secondary>
          Estimez votre potentiel <br /> d'économie d'énergie{" "}
          <Emphasis className="underlined">en 2 minutes</Emphasis>
        </Secondary>
        <TextInfo>
          Powered by <b>LIKEWATT</b>
        </TextInfo>
      </Square>
    </Step>
  );
};
